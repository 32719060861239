import React, {useEffect, useState} from 'react';
import { useBaseUrlContext } from '../../context/Base_url';
import { useNavigate} from 'react-router-dom';
import { useAuthContext } from '../../context/Auth';
import axios from "axios";
import Cookies from 'js-cookie';
import useAxiosInstance from '../../api/interceptor';

import {Box, Typography, Modal} from '@mui/material';

import ButtonSecondary from '../button/Btn_secondary';

import ModalStyle from './Modal_styles.module.scss';

import TimeIcon from '../../assets/sand-clock.gif';

const SaveChangesModal = ({openSaveChanges, setOpenSaveChanges, savePopup, discardPopup}) =>{

    const navigate = useNavigate();
    const {BaseUrl} = useBaseUrlContext()
    const axiosInstance = useAxiosInstance();

    const saveChanges = () => {
        savePopup()
    }

    const discardChanges = () => {
        discardPopup()
    }

    return(
        <div>
            <Modal
                open={openSaveChanges}
                aria-labelledby="modal-modal-title"
            >
                <Box className={`${ModalStyle['modal-base']} ${ModalStyle['modal-save-changes']}`}> 
                    <div className={ModalStyle['modal-container']}>
                        <div className={ModalStyle['content-wrapper']}>
                            <div className={ModalStyle['icon-wrapper']}>
                                <img src={TimeIcon} alt='logout icon' className={`${ModalStyle["icon-img"]} ${ModalStyle["icon-img-flip"]}`}/>
                            </div>
                                <Typography id="modal-modal-title" className={`${ModalStyle['modal-Header1']} modal-mx-3`} align={'center'} variant="text24" component="h2">
                                    You've made some changes. Don't forget to save them.
                                </Typography>
                        </div>
                        <div className={ModalStyle['footer-wrapper-center']}>
                            <ButtonSecondary btnName="Discard" type="button" variant={'primaryBtn'} classes="mr-5" onClick={discardChanges} disabled={false}/>
                            <ButtonSecondary btnName="Save Changes" variant={'secondaryBtn'} type="submit" onClick={saveChanges} disabled={false}/>
                        </div>
                    </div>    
                </Box>
            </Modal>
        </div> 
    );
}

export default SaveChangesModal;