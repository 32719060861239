import React from "react";

import {ThemeProvider, Typography} from '@mui/material';
import { ThemeCustom} from "../../styles/Theme";

import NotFoundGif  from "../../assets/not-found.gif";
import loaderIcon from '../../assets/Loading1.svg'



const Loading = (props) => {
    return(
        <ThemeProvider theme={ThemeCustom}>
            <div className="w-full min-height-uploadView flex flex-col justify-center items-center">
                <img className="max-w-[3.75rem] min-h-[3.75rem] mb-8" src={loaderIcon} alt="loading logo"/>
                    <Typography variant="text30" className="font-semibold mb-7" component='div'>
                        Loading...
                    </Typography>                
            </div>
        </ThemeProvider>    
    );
}

export default Loading;