import React, {useEffect, useState} from 'react';
import { useForm } from "react-hook-form";
import axios from "axios";
import Cookies from 'js-cookie';
import { useBaseUrlContext } from '../../context/Base_url';
import { useAuthContext } from '../../context/Auth';
import useAxiosInstance from '../../api/interceptor';
import { useUserContext } from '../../context/User_Access';

import {Box, Typography, Modal, FormControl, OutlinedInput, InputLabel, InputAdornment, IconButton, Select, MenuItem} from '@mui/material';

import ButtonSecondary from '../button/Btn_secondary';
import SklInputPopup from '../skeletons/Skl_input_popup';
import ButtonSecondaryLoader from '../button/Btn_secondary_loader';

import ModalStyle from './Modal_styles.module.scss';
import './Modal_styles.scss';

import eye from '../../assets/eye.svg'; 
import eyeSlash from '../../assets/eye-slash.svg'; 

const UserAddModal = ({openAddUser, setOpenAddUser, editId, getTableData, setOpenFail, setTextFail, setOpenSuccess, setTextSuccess}) =>{

    const { register, handleSubmit, formState: { errors }, reset, setValue } = useForm();
    const [uploading, setUploading] = useState(false)
    const [selectedCountry, setSelectedCountry] = useState([])
    const [selectedCountryCode, setSelectedCountryCode] = useState([])
    const [countryList, setCountryList] = useState([])
    const [countryListFilter, setCountryListFilter] = useState([])
    const [regionName, setRegionName] = useState('')
    const [showPassword, setShowPassword] = useState(false);
    const [roles, setRoles] = useState([])
    const [selRole, setSelRole] = useState('')
    const [popupLoader, setPopupLoader] = useState(false)
    const {BaseUrl} = useBaseUrlContext()
    const {tokenReview, popupAction} = useAuthContext()
    const axiosInstance = useAxiosInstance();
    const [btnLoading, setBtnLoading] = useState(false)
    const {setUserDetails} = useUserContext()
    const [cookieOptions, setCookieOptions] = useState({})

    const handleClickShowPassword = () => setShowPassword((show) => !show);

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    useEffect(()=>{console.log('tokenReview region add popup',tokenReview)},[tokenReview])

    useEffect(()=>{
        if(openAddUser){
            setUploading(false)
            if(editId !== 0){
                editUser()
            }
            else{
                
            }
        }
    },[openAddUser])

    //get user roles and set Cookie Options 

    useEffect(()=>{
        
        getUserRoles()

        const domain = window.location.hostname
        const isSecure = domain === 'localhost' ? false : domain.includes('invoiceai.local') ? false : true;

        setCookieOptions(
            {
                path: '/',
                secure: isSecure, 
                sameSite: 'lax', // Use 'lax' to balance security and accessibility
                //domain: cookieDomain,
            }
        )

    },[])

    //get user rolse API 

    const getUserRoles = async() => {

        try {

            setPopupLoader(true)
            
            const res = await axiosInstance.get(`/user_roles.php`)
            
                //console.log(res)
                let data = res.data.result;
                let error = res.data.error;

                console.log('get edit user roles', res.data.result)
                setRoles(data.userRoles)

        } catch (err) {
            console.error('fetchData:', err);
        } 

    }


    const cancel = () =>{
        setOpenAddUser(false)
        reset({email:'', name:'', password:'', role:''})
        setSelRole('')
    }

    //set role
    useEffect(()=>{
        setValue('role', selRole)
    },[selRole])

    //get edit data

    const editUser = async() => {

        try {

            setPopupLoader(true)

            const res = await axiosInstance.get(`/users.php?userId=${editId}`)

            let data = res.data.result;
            let error = res.data.error;
    
            console.log('get edit',res.data.result)
            setValue('email', data.user.email) 
            setValue('name', data.user.name)
            setSelRole(data.user.role.id)
            setPopupLoader(false)
            console.log('edit user role', data.user.role.id)

        } catch (err) {
            console.error('fetchData:', err);
        } 

    }

    const onSubmit = (data) => {

        console.log('submit data', data)
        
        if(editId === 0){
            dataPost(data);
        }
        else{
            dataUpdate(data);
        }

    }

    //new data post

    const dataPost = async(data) => {

        console.log('data post')
        setBtnLoading(true)

        try {
                
            const res = await axiosInstance.post(`/users.php`, data)
                
            let result = res.data.result;
            let error = res.data.error;

            console.log(result.message)
            setUploading(false)
            setOpenAddUser(false)
            getTableData()
            setOpenSuccess(true)
            setTextSuccess(result.message)
            reset({email:'', name:'', password:'', role:''})
            setSelRole('')
            setBtnLoading(false)

        } catch (err) {
            console.error('fetchData:', err);
            setBtnLoading(false)
        } 

    }

    //data update

    const dataUpdate = async(data) => {

        console.log('data update')
        setBtnLoading(true)

        try {

        
            const dataMerge = {...data, ...{'id': editId}}
                
            const res = await axiosInstance.put(`/users.php`, dataMerge)

            //console.log(res)
            let result = res.data.result;
            let error = res.data.error;

            console.log(result.message)
            setUploading(false)
            setOpenAddUser(false)
            getTableData()
            setOpenSuccess(true)
            setTextSuccess(result.message)
            reset({email:'', name:'', password:'', role:''})
            setSelRole('')
            setBtnLoading(false)
            setUserDetails(result.updatedName, undefined, undefined, undefined, undefined, undefined)
            Cookies.set('userName', result.updatedName, cookieOptions);

        } catch (err) {
            console.error('fetchData:', err);
            setBtnLoading(false)

        }     

    }



    return(
        <div>
            <Modal
                open={openAddUser}
                aria-labelledby="modal-modal-title"
            >
                <Box className={`${ModalStyle['modal-base']} ${ModalStyle['modal-secret-key']}`}> 
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <div className={ModalStyle['modal-container']}>
                            <div className={ModalStyle['content-wrapper']}>
                                <Typography id="modal-modal-title" align='center' className={ModalStyle['modal-Header1']} variant="text24" component="h2">
                                   {editId === 0 ? 'Add new user' : 'Edit user'} 
                                </Typography>
                                    <FormControl variant="standard" fullWidth  margin="normal" className='modal-mt-12 form-control' >
                                        <InputLabel shrink htmlFor='name' title='text' className='modal-label'>
                                            Name
                                        </InputLabel>
                                        { popupLoader && editId !== 0 ? (
                                            <SklInputPopup/>
                                        ):(
                                        <>
                                        <OutlinedInput 
                                            id='name'
                                            placeholder='Enter name'
                                            //value={regionName}
                                            autoComplete="off" 
                                            type='text'
                                            className='modal-input'
                                            //{...register('name',{ required: true , onChange: (e) => setRegionName(e.target.value) })} 
                                            {...register('name',{ required: true })} 
                                        />
                                        {errors.name && <p className='err-msg'>Name is required</p>}
                                        </>
                                        )}
                                    </FormControl>
                                    <FormControl variant="standard" fullWidth  margin="normal" className='form-control ' >
                                        <InputLabel shrink htmlFor='email' title='text' className='modal-label'>
                                            Email
                                        </InputLabel>
                                        { popupLoader && editId !== 0 ? (
                                            <SklInputPopup/>
                                        ):(
                                        <>
                                        <OutlinedInput 
                                            id='email'
                                            placeholder='Enter email address'
                                            //value={regionName}
                                            autoComplete="off" 
                                            type='text'
                                            className='modal-input'
                                            {...register('email',{ 
                                                required: true,
                                                pattern: {
                                                    value: /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/,
                                                },  
                                            })} 
                                            disabled={editId === 0 ? false : true}
                                        />
                                            {errors.email && errors.email.type === "required" && <p className='err-msg'>Email is required</p>}
                                            {errors.email && errors.email.type === "pattern" && <p className='err-msg'>Invalid email address</p>}
                                        </>
                                        )}
                                    </FormControl>
                                    <FormControl variant="standard" fullWidth  margin="normal" className='form-control modal-mb-10' >
                                        <InputLabel shrink htmlFor='role' title='text' className='modal-label'>
                                            Role
                                        </InputLabel>
                                        { popupLoader && editId !== 0 ? (
                                            <SklInputPopup/>
                                        ):(
                                        <>
                                        <Select
                                            id='role'
                                            className='w-modal-full select-modal type-filter-select'
                                            input={<OutlinedInput />}
                                            displayEmpty
                                            value={selRole}
                                            renderValue={(selRole) => {

                                                if (!selRole) {
                                                return <span className='text-sonicSilver'>-Select user role-</span>;
                                                }

                                                const result = roles.find(role => role.id == selRole);
                                                if (result) {
                                                    return result.name;
                                                }

                                            }}
                                            inputProps={{ 'aria-label': 'Role' }}
                                            {...register('role',{ required: editId === 0  ? true : !selRole.length > 0 ? true : false , onChange: (e) => setSelRole(e.target.value), x:console.log('selRole xxx',selRole) })} 
                                        >
                                            { roles.length > 0 && roles.map((role, index) => (
                                                <MenuItem 
                                                    className='country-down-item'
                                                    key={index} 
                                                    value={role.id} 
                                                >
                                                    {role.name}
                                                </MenuItem>
                                            ))}
                                            
                                        </Select>
                                        {errors.role && <p className='err-msg'>Role is required</p>}
                                        </>
                                        )}
                                    </FormControl>
                                    {/* <FormControl variant="standard" fullWidth  margin="normal" className='form-control modal-mb-5' >
                                        <div className='flex justify-between'>
                                            <InputLabel shrink htmlFor="password-input">Password</InputLabel>
                                        </div>
                                        { popupLoader && editId !== 0 ? (
                                            <SklInputPopup/>
                                        ):(
                                        <>
                                        <OutlinedInput 
                                            placeholder="Enter password" 
                                            id="password-input" 
                                            type={showPassword ? 'text' : 'password'} 
                                            {...register("password",{ required: editId === 0 ? true : false })} 
                                            endAdornment={
                                                <InputAdornment position="end" className='mr-2'>
                                                    <IconButton
                                                        aria-label="toggle password visibility"
                                                        onClick={handleClickShowPassword}
                                                        onMouseDown={handleMouseDownPassword}
                                                        edge="end"
                                                    >
                                                    {showPassword ? <img src={eyeSlash} alt='VisibilityOff' className='w-6'/> : <img src={eye} alt='Visibility' className='w-6'/>}
                                                    </IconButton>
                                                </InputAdornment>
                                            }
                                        />
                                        {errors.password && editId === 0 && <span className='err-msg'>Password is required.</span>}
                                        </>
                                        )}
                                    </FormControl> */}
                            </div>
                            <div className={ModalStyle['footer-wrapper-center']}>
                                <ButtonSecondary btnName="Cancel" variant={'primaryBtn'} classes='modal-button-12' type="button" onClick={cancel} disabled={uploading}/>
                                {/* <ButtonSecondary btnName={editId === 0 ? "Add" : 'Save'} variant={'secondaryBtn'} classes='modal-button-12' type="submit" onClick={()=>{}} disabled={uploading}/> */}
                                <ButtonSecondaryLoader variant={'secondaryBtn'} type='submit' classes='modal-button-12' btnName={editId === 0 ? "Add" : 'Save'} btnLoading={btnLoading} disabled={uploading} onClick={()=>{}}/>
                            </div>
                        </div>    
                    </form>
                </Box>
            </Modal>
        </div> 
    );
}

export default UserAddModal;