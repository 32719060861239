import React, {useState, useEffect} from 'react';
import { Link } from 'react-router-dom';

import Container from '@mui/material/Container';

import SignUpForm from '../components/auth-form/Signup_form';
import SuccessAlert from '../components/alerts/Success_alert';
import ErrorAlert from '../components/alerts/Fail_alert';

const SignUp = () =>{
    const [openSuccess, setOpenSuccess] = useState(false);
    const [openFail, setOpenFail] = useState(false);
    const [textSuccess, setTextSuccess] = useState('');
    const [textFail, setTextFail] = useState('');

    useEffect(()=>{
        setTimeout(()=>{
            setOpenFail(false);
        },10000)
    },[openFail]);

    useEffect(()=>{
        console.log(openSuccess)
        setTimeout(()=>{
            setOpenSuccess(false);
        },10000)
      },[openSuccess]);

    return(
        <>
            <div className='absolute top-8 right-8 w-full z-[1999] h-0'>
                <SuccessAlert setOpenSuccess={setOpenSuccess} openSuccess={openSuccess} alretText={textSuccess}/>
                <ErrorAlert setOpenFail={setOpenFail} openFail={openFail} alretText={textFail}/>
            </div>
            <Container maxWidth="xl">
                <div className='flex justify-center items-center h-[calc(100vh-6rem)] my-12 relative'>
                    <SignUpForm setOpenSuccess={setOpenSuccess} setTextSuccess={setTextSuccess} setOpenFail={setOpenFail} setTextFail={setTextFail}/>
                </div>
                <div className='text-center h-12 flex justify-center mb-12'>
                    <div className='my-3 px-3 flex items-center text-base text-sonicSilver font-medium'>
                        By signing up, you agree to the <a href='#' target='_blank' className=' text-sonicSilver underline mx-1'>Terms of Service</a> and <a href='#' target='_blank' className=' text-sonicSilver underline ml-1'>Privacy Policy</a>
                    </div>
                </div>
            </Container> 
        </> 
    );
}

export default SignUp;