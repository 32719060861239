import React, {useState, useEffect} from 'react';
import axios from "axios";
import Cookies from 'js-cookie';
import { useOutletContext } from 'react-router-dom';
import { useBaseUrlContext } from '../../context/Base_url';
import { useAuthContext } from '../../context/Auth';
import useAxiosInstance from '../../api/interceptor';

import {ThemeProvider, Typography} from '@mui/material';

import { ThemeCustom} from "../../styles/Theme";
import './settings_styles.scss';

import RegionTable from './Region_table';
import ButtonImg from '../button/Btn_img';
import RegionAddModal from '../modal/Modal_region_add';
import DeleteV2Modal from '../modal/Modal_delete_V2';

import plusIcon from '../../assets/add-light.svg'

const Region = ({openFail, textFail, openSuccess, textSuccess}) =>{

    const {setOpenFail} = useOutletContext(); 
    const {setTextFail} = useOutletContext(); 
    const {setOpenSuccess} = useOutletContext(); 
    const {setTextSuccess} = useOutletContext(); 
    const {BaseUrl} = useBaseUrlContext()
    const {tokenReview, popupAction} = useAuthContext()

    const [openAddRegion, setOpenAddRegion] = useState(false);
    const [editId, setEditId] = useState(1)
    const [country, setcountry] = useState([]);
    const [openDelete, setOpenDelete] = useState(false);
    const [delText, setDelText] = useState('list')
    const [loading, setLoading] = useState(true)
    const axiosInstance = useAxiosInstance();
    const [btnLoading, setBtnLoading] = useState(false);

    useEffect(() => {

        getTableData();

    },[])   


    //get Region & countries table data

    const getTableData = async() =>{

        try {

            setLoading(true)

            const res = await axiosInstance.get(`/region.php?other=0`)

            let data = res.data.result;
            let error = res.data.error;

            console.log(res.data.result)
            setcountry(data.region)
            setLoading(false)
                  
        } catch (err) {
            console.error('fetchData:', err);
        }     

    }

    const deleteFun = async() => {
        console.log('delete function region-------------------')
        setBtnLoading(true)

        try {

            const deleteId = JSON.stringify({
                "regionId": editId
            });

            const res = await axiosInstance.delete(`/region.php`,{data : deleteId})

            let data = res.data.result;
            let error = res.data.error;

            setOpenDelete(false);
            getTableData();
            setOpenSuccess(true)
            setTextSuccess(data.message)      
            setBtnLoading(false)

        } catch (err) {
            console.error('fetchData:', err);
            setBtnLoading(false)
        } 

    }

    useEffect(()=>{console.log('tokenReview region',tokenReview)},[tokenReview])
    
    return(
        <ThemeProvider theme={ThemeCustom}>
            <DeleteV2Modal
                openDelete={openDelete}
                setOpenDelete={setOpenDelete}
                deleteFun={deleteFun}
                delText={delText}
                editId={editId}
                tokenReview={tokenReview}
                btnLoading={btnLoading}
            />
            <RegionAddModal
                openAddRegion={openAddRegion} 
                setOpenAddRegion={setOpenAddRegion} 
                editId={editId}
                getTableData={getTableData}
                setOpenFail={setOpenFail}
                setTextFail={setTextFail}
                setOpenSuccess={setOpenSuccess}
                setTextSuccess={setTextSuccess}
                tokenReview={tokenReview}
            />
            <div className='mb-12'>
                <div className='flex justify-between items-start'> 
                    <div>
                        <Typography variant="text32" className="mb-3" component='div'>
                            Regions & Countries
                        </Typography>
                        <Typography variant="text20" className="mb-3" component='div'>
                            Customize your settings by adding or removing regions and countries.
                        </Typography>
                    </div>
                    <div>
                        <ButtonImg btnName="Add New" img={plusIcon} type="button" classes=" min-w-[11.5rem] px-3" 
                        onClick={() => {setOpenAddRegion(true); setEditId(0)}}/>
                    </div>
                </div>
            </div>
            <div>
                <RegionTable
                    setOpenAddRegion={setOpenAddRegion} 
                    setEditId={setEditId}
                    country={country}
                    setOpenFail={setOpenFail}
                    setTextFail={setTextFail}
                    setOpenSuccess={setOpenSuccess}
                    setTextSuccess={setTextSuccess}
                    setOpenDelete={setOpenDelete}
                    loading={loading}
                />
            </div>
         
        </ThemeProvider>
    )
}

export default Region;