import React, {useState, useEffect, useRef} from 'react';
import {ThemeProvider, Skeleton } from '@mui/material';

import { ThemeCustom } from "../../styles/Theme";


const SklXmlViewer = (props) => {


    return(
        <ThemeProvider theme={ThemeCustom}>
            <>
                {[1,2,3,4].map((item,i)=>(
                    <div className='m-5 mb-3' key={i}>
                        <Skeleton animation="wave" className={`w-[80%] max-h-[1.125rem] h-[1.125rem] rounded mb-2`}></Skeleton>
                        <Skeleton animation="wave" className={`w-[60%] max-h-[1.125rem] h-[1.125rem] rounded mb-2 `}></Skeleton>
                        <Skeleton animation="wave" className={`w-[40%] max-h-[1.125rem] h-[1.125rem] rounded mb-2 `}></Skeleton>
                        <Skeleton animation="wave" className={`w-[25%] max-h-[1.125rem] h-[1.125rem] rounded mb-2 `}></Skeleton>
                        <Skeleton animation="wave" className={`w-[40%] max-h-[1.125rem] h-[1.125rem] rounded mb-2`}></Skeleton>
                        <Skeleton animation="wave" className={`w-[60%] max-h-[1.125rem] h-[1.125rem] rounded mb-2 `} ></Skeleton>
                        <Skeleton animation="wave" className={`w-[80%] max-h-[1.125rem] h-[1.125rem] rounded mb-2`}></Skeleton>
                    </div>
                ))}
            </>
        </ThemeProvider>
    )
}

export default SklXmlViewer;