import { createTheme } from "@mui/material/styles";  
import { fontSize } from "@mui/system";
import { transform } from "lodash";

export const ThemeMain = createTheme({

    palette: {
        primary: {
            light: '#333333',
            main: "#000000",
            disable:"#666666"
        },
        secondary:{
            lighter:'#F2FAF5',
            light: '#3ab36b',
            main: "#09A047",
            disable:"#73c796"
        },
        white:{
            main: "#fff"
        },
        antiFlashWhite:{
            main: "#F2F2F2"
        },
        platinum:{
            main: "#E4E4E4",
            dark:"#9f9f9f",
            light: "#e9e9e9",
            lighter:'#E3E3E3'
        },
        cultured:{
            main: "#F3F5F5",
            dark:"#aaabab",
            light: "#f5f7f7"
        },
        silver:{
            main: "#CBCBCB"
        },
        fulvous:{
            main:'#E28A05'
        },
        red:{
            main:'#F72032',
            dark:"#ac1623",
            light: "#f84c5b",
            disable:"#ff7c87",
            hover:'#ffeced'
        },

    },
    typography: {
        fontFamily: ['Poppins','sans-serif',].join(','),
        fontSize: 16,
    }

});

export const ThemeCustom = createTheme({
    palette: {
        primary: {
            light: '#333333',
            main: "#000000",
            disable:"#333333"
        },
        secondary:{
            light: '#3ab36b',
            main: "#09A047",
            disable:"#73c796"
        },
        white:{
            main: "#fff"
        },
        antiFlashWhite:{
            main: "#F2F2F2"
        },
        platinum:{
            main: "#E4E4E4"
        },
        cultured:{
            main: "#F3F5F5",
            dark:"#aaabab",
            light: "#f5f7f7"
        },
        silver:{
            main: "#CBCBCB"
        },
        fulvous:{
            main:'#E28A05'
        },
        red:{
            main:'#F72032',
            dark:"#ac1623",
            light: "#f84c5b",
            disable:"#ff7c87",
            hover:'#ffeced'
        }     
    },
    typography: {
        userDropdown: {
            color:  ThemeMain.palette.primary.main,
        },
        text14: {
            color:  ThemeMain.palette.primary.main,
            fontSize: '0.875rem',
        },
        textBase:{
            color:  ThemeMain.palette.primary.main,
            fontSize: '1rem',
        },
        text18: {
            color:  ThemeMain.palette.primary.main,
            fontSize: '1.125rem',
        },
        text20: {
            color:  ThemeMain.palette.primary.main,
            fontSize: '1.25rem',
        },
        text24: {
            color:  ThemeMain.palette.primary.main,
            fontSize: '1.5rem',
            fontWeight:'600 !important'
        },
        textMedium24: {
            color:  ThemeMain.palette.primary.main,
            fontSize: '1.5rem',
            fontWeight:'500 !important'
        },
        textMedium28: {
            color:  ThemeMain.palette.primary.main,
            fontSize: '1.75rem',
            fontWeight:'500 !important'
        },
        text30: {
            color:  ThemeMain.palette.primary.main,
            fontSize: '1.875rem',
            fontWeight:'600 !important'
        },
        text32: {
            color:  ThemeMain.palette.primary.main,
            fontSize: '2rem',
            fontWeight:'600 !important'
        },
        text40: {
            color:  ThemeMain.palette.primary.main,
            fontSize: '2.5rem',
            fontWeight:'700 !important'
        },
        text60: {
            color:  ThemeMain.palette.primary.main,
            fontSize: '3.75rem',
            fontWeight:'600 !important'
        },
        subHeader2: {
            color:  ThemeMain.palette.primary.main,
            fontWeight: '600 !important',
            fontSize:'1rem'
        },
        subHeader1: {
            color:  ThemeMain.palette.primary.main,
            fontWeight: '600 !important',
            fontSize:'1.25rem'
        }
    },
    components:{
        MuiTypography:{
            styleOverrides: {
                root: {
                    fontFamily: ['Poppins','sans-serif',].join(','),
                    fontWeight: 500,
                    lineHeight: 1.2,
                }
            },
        },
        MuiPaper:{
            styleOverrides: {
                root: {
                    boxShadow:'0px 5px 5px -3px #0000000d, 0px 8px 10px 1px #0000000d, 0px -3px 14px 2px rgb(0 0 0 / 12%)',
                },
                rounded: {
                    borderRadius: '0.75rem',
                },
            },
        },
        MuiCheckbox:{
            styleOverrides: {
                root: {
                    color: ThemeMain.palette.silver.main,
                    '&:hover':{
                        backgroundColor:'transparent'
                    }
                }
            }
        },
        MuiTableCell:{
            styleOverrides: {
                root: {
                    borderBottom:`1px solid ${ThemeMain.palette.platinum.lighter}`,
                    padding:'0.5rem 1rem',
                    color:ThemeMain.palette.primary.main,
                    fontSize: '1rem',
                    fontWeight:'500',
                    fontFamily: ['Poppins','sans-serif',].join(','),
                    '&.MuiTableCell-head':{
                        borderBottom:`1px solid ${ThemeMain.palette.platinum.lighter}`,
                        padding:'1rem',
                        backgroundColor:ThemeMain.palette.cultured.main,
                        fontWeight:'600',
                        fontSize: '1.125rem'
                    },
                }
            },
        },
        MuiTableRow:{
            styleOverrides: {
                root: {
                    '&:last-child':{
                        '.MuiTableCell-body':{
                            borderBottom:`0px solid ${ThemeMain.palette.silver.main}`,
                        }
                    }
                }
            }
        },
        MuiButton:{
            styleOverrides: {
                root: {
                    minHeight: '3rem',
                    minWidth: '7.5rem'
                  },
            },
            variants: [
                {
                    props: { variant: 'primaryBtn' },
                    style: {
                        color:  '#ffffff',
                        fontFamily: ['Poppins','sans-serif',].join(','),
                        fontSize: '1rem',
                        lineHeight: 1.2,
                        fontWeight: 500,
                        backgroundColor: ThemeMain.palette.primary.main,
                        borderRadius: '0.75rem',
                        padding: '0.975rem 1.5rem',
                        textTransform: 'initial',
                        '&:hover': {
                            backgroundColor: ThemeMain.palette.primary.light
                        },
                        '&.Mui-disabled': {
                           backgroundColor: `${ThemeMain.palette.primary.disable} !important`,
                           color:  '#ffffff !important',
                        }

                    },
                },
                {
                    props: { variant: 'secondaryBtn' },
                    style: {
                        color:  '#ffffff',
                        fontFamily: ['Poppins','sans-serif',].join(','),
                        fontSize: '1rem',
                        lineHeight: 1.2,
                        fontWeight: 500,
                        backgroundColor: ThemeMain.palette.secondary.main,
                        borderRadius: '0.75rem',
                        padding: '1.125rem 1.5rem',
                        textTransform: 'initial',
                        '&:hover': {
                            backgroundColor: ThemeMain.palette.secondary.light
                        },
                        '&.Mui-disabled': {
                            backgroundColor: `${ThemeMain.palette.secondary.disable} !important`,
                            color:  '#ffffff !important',
                         }
                    },
                },  
                { 
                props: { variant: 'culturedyBtn' },
                    style: {
                        color:  ThemeMain.palette.primary.main,
                        fontFamily: ['Poppins','sans-serif',].join(','),
                        fontSize: '1rem',
                        lineHeight: 1.2,
                        fontWeight: 500,
                        backgroundColor: ThemeMain.palette.cultured.main,
                        borderRadius: '0.75rem',
                        padding: '1.125rem 1.5rem',
                        textTransform: 'initial',
                        '&:hover': {
                            backgroundColor: ThemeMain.palette.cultured.dark
                        }
                    },
                },
                {
                props: { variant: 'platinumBtn' },
                    style: {
                        color:  ThemeMain.palette.primary.main,
                        fontFamily: ['Poppins','sans-serif',].join(','),
                        fontSize: '1rem',
                        lineHeight: 1.2,
                        fontWeight: 500,
                        backgroundColor: ThemeMain.palette.platinum.main,
                        borderRadius: '0.75rem',
                        padding: '1.125rem 1.5rem',
                        textTransform: 'initial',
                        '&:hover': {
                            backgroundColor: ThemeMain.palette.platinum.light
                        }
                    },
                },
                {
                    props: { variant: 'redBtn' },
                    style: {
                        color:  '#ffffff',
                        fontFamily: ['Poppins','sans-serif',].join(','),
                        fontSize: '1rem',
                        lineHeight: 1.2,
                        fontWeight: 500,
                        backgroundColor: ThemeMain.palette.red.main,
                        borderRadius: '0.75rem',
                        padding: '1.125rem 1.5rem',
                        textTransform: 'initial',
                        '&:hover': {
                            backgroundColor: ThemeMain.palette.red.light
                        },
                        '&.Mui-disabled': {
                           backgroundColor: `${ThemeMain.palette.red.disable} !important`,
                           color:  '#ffffff !important',
                        }
                    },
                }, 
                {
                    props: { variant: 'whiteBtn' },
                    style: {
                        color:  ThemeMain.palette.primary.main,
                        fontFamily: ['Poppins','sans-serif',].join(','),
                        fontSize: '1rem',
                        lineHeight: 1.2,
                        fontWeight: 500,
                        backgroundColor: ThemeMain.palette.white.main,
                        borderRadius: '0.75rem',
                        padding: '1.125rem 1.5rem',
                        textTransform: 'initial',
                        '&:hover': {
                            backgroundColor: ThemeMain.palette.white.main
                        },
                        '&.Mui-disabled': {
                           backgroundColor: `${ThemeMain.palette.white.main} !important`,
                           color:  '#ffffff !important',
                        }
                    },
                },
                {
                    props: { variant: 'imgBtn' },
                    style: {
                        color: ThemeMain.palette.primary.main,
                        fontFamily: ['Poppins','sans-serif',].join(','),
                        fontSize: '0.75rem',
                        lineHeight: 1.2,
                        fontWeight: 500,
                        backgroundColor: 'transparent',
                        borderRadius: '0.5rem',
                        padding: '0.975rem 0.75rem',
                        //border:`1px solid ${ThemeMain.palette.primary.main}`,
                        textTransform: 'initial',
                        '&:hover': {
                            backgroundColor: ThemeMain.palette.silver.light
                        }
                    },
                },
                {
                    props: { variant: 'imgBtnSecondary' },
                    style: {
                        color: ThemeMain.palette.white.main,
                        fontFamily: ['Poppins','sans-serif',].join(','),
                        fontSize: '1rem',
                        lineHeight: 1.2,
                        fontWeight: 500,
                        backgroundColor: ThemeMain.palette.secondary.main,
                        borderRadius: '0.75rem',
                        padding: '1.125rem 0.75rem',
                        //border:`1px solid ${ThemeMain.palette.primary.main}`,
                        textTransform: 'initial',
                        '&:hover': {
                            backgroundColor: ThemeMain.palette.secondary.light
                        },
                        '&.Mui-disabled': {
                            backgroundColor: `${ThemeMain.palette.secondary.disable} !important`,
                            color:  '#ffffff !important',
                         }
                    },
                },
                {
                    props: { variant: 'imgBtnPrimaryOutline' },
                    style: {
                        color: ThemeMain.palette.primary.main,
                        fontFamily: ['Poppins','sans-serif',].join(','),
                        fontSize: '1rem',
                        lineHeight: 1.2,
                        fontWeight: 500,
                        backgroundColor: 'transparent',
                        border:`1px solid ${ThemeMain.palette.primary.main}`,                        
                        borderRadius: '0.75rem',
                        padding: '1.063rem 1.5rem',
                        textTransform: 'initial',
                        '&:hover': {
                            backgroundColor: ThemeMain.palette.silver.light
                        }
                    },
                },
                {
                    props: { variant: 'primaryOutline' },
                    style: {
                        color:  ThemeMain.palette.primary.main,
                        fontFamily: ['Poppins','sans-serif',].join(','),
                        fontSize: '1rem',
                        lineHeight: 1.2,
                        fontWeight: 500,
                        backgroundColor: 'transparent',
                        border:`1px solid ${ThemeMain.palette.primary.main}`,
                        borderRadius: '0.75rem',
                        padding: '0.913rem 1.5rem',
                        textTransform: 'initial',
                        '&:hover': {
                            backgroundColor: ThemeMain.palette.silver.light
                        }
                    },
                },
                {
                    props: { variant: 'secondaryOutline' },
                    style: {
                        color:  ThemeMain.palette.secondary.main,
                        fontFamily: ['Poppins','sans-serif',].join(','),
                        fontSize: '1rem',
                        lineHeight: 1.2,
                        fontWeight: 500,
                        backgroundColor: 'transparent',
                        border:`1px solid ${ThemeMain.palette.secondary.main}`,
                        borderRadius: '0.75rem',
                        padding: '0.913rem 1.5rem',
                        textTransform: 'initial',
                        '&:hover': {
                            backgroundColor: ThemeMain.palette.secondary.lighter
                        }
                    },
                },
                {
                    props: { variant: 'redOutline' },
                    style: {
                        color:  ThemeMain.palette.red.main,
                        fontFamily: ['Poppins','sans-serif',].join(','),
                        fontSize: '1rem',
                        lineHeight: 1.2,
                        fontWeight: 500,
                        backgroundColor: 'transparent',
                        border:`1px solid ${ThemeMain.palette.red.main}`,
                        borderRadius: '0.75rem',
                        padding: '0.913rem 1.5rem',
                        textTransform: 'initial',
                        '&:hover': {
                            backgroundColor: ThemeMain.palette.red.hover
                        }
                    },
                },
                {
                    props: { variant: 'curdComp' },
                    style: {
                        color:  ThemeMain.palette.primary.main,
                        fontFamily: ['Poppins','sans-serif',].join(','),
                        fontSize: '1rem',
                        lineHeight: 1.2,
                        fontWeight: 600,
                        backgroundColor: 'transparent',
                        border:0,
                        textTransform: 'initial',
                        '&:hover': {
                            backgroundColor: 'transparent'
                        }
                    },
                },
            ]  
        },
        MuiInputLabel:{
            styleOverrides: {
                root: {
                    position: 'relative',
                    fontSize: '1rem',
                    color: ThemeMain.palette.primary.main,
                    fontFamily: ['Poppins','sans-serif',].join(','),
                    fontWeight: 500,
                    transform: 'inherit',
                    marginBottom: '0.5rem',
                    '&.Mui-focused':{
                        color: ThemeMain.palette.primary.main
                    }
                  },
            }
        },
        MuiOutlinedInput:{
            styleOverrides: {
                root: {
                    borderRadius:'0.75rem',
                    width:'100%',
                    fontWeight: 500,
                    backgroundColor: ThemeMain.palette.cultured.main,
                    color:ThemeMain.palette.primary.main,
                    fontFamily: ['Poppins','sans-serif',].join(','),
                    '&.Mui-focused .MuiOutlinedInput-notchedOutline':{
                        borderColor: ThemeMain.palette.silver.main,
                        borderWidth: '1px'
                    },
                    '& .MuiOutlinedInput-notchedOutline':{
                        borderWidth:'0'
                    },
                    '&.select':{
                        //backgroundColor: ThemeMain.palette.white.main,
                        //border:`1px solid ${ThemeMain.palette.primary.main}`,
                        fontFamily: ['Poppins','sans-serif',].join(','),
                        borderRadius:'0.75rem',

                        '&.Mui-focused':{
                            //borderWidth: 0,

                            '.MuiOutlinedInput-notchedOutline':{
                                //border:`1px solid ${ThemeMain.palette.primary.main}`,
                            }
                        },

                        '.MuiSelect-outlined':{
                            //padding: '0.944rem 14px',
                            padding: '1rem 1.5rem',
                            fontWeight: 600,
                            color: ThemeMain.palette.primary.main,
                        }
                    }
                }
                },
                input:{
                    fontSize: '1rem',
                    color: ThemeMain.palette.primary.main,
                    fontFamily: ['Poppins','sans-serif',].join(','),
                    fontWeight: 500,
                    padding: '0.75rem 1rem',
                }  
            
        },
        MuiMenuItem:{
            styleOverrides: {
                root: {
                    fontFamily: ['Poppins','sans-serif',].join(','),
                    fontWeight: 500,
                    color: ThemeMain.palette.primary.main,
                    padding:'1rem',
                    margin:'0 0.5rem',
                    borderBottom:`1px solid ${ThemeMain.palette.platinum.main}`,
                    '&:last-child':{
                        borderBottom:'0',
                        margin:'0 0.5rem 0.5rem',
                        borderRadius:'0 0 0.75rem 0.75rem'
                    },
                    '&:first-of-type':{
                        margin:'0.5rem 0.5rem 0',
                        borderRadius:'0.75rem 0.75rem 0 0'
                    },
                    '&.main-nav-item:first-of-type':{
                        marginTop:'0',
                        borderRadius:'0'
                    },
                    '&.Mui-selected':{
                        backgroundColor: `${ThemeMain.palette.white.main} !important`,
                        fontWeight:'600'
                    }
                }
            }
        },
        MuiBackdrop:{
            styleOverrides: {
                root: {
                    backgroundColor: `rgba(16, 16, 16, 0.4)`,
                }
            }
        },
        MuiTab:{
            styleOverrides: {
                root: {
                    maxWidth:'22.5rem',
                    minWidth:'5.625rem',
                    minHeight:'3.45rem',
                    padding:'0.75rem 1.25rem',
                    fontWeight:'500',
                    fontSize:'1rem',
                    textTransform:'capitalize',
                    fontFamily: ['Poppins','sans-serif',].join(',')
                }
            }
        },
        MuiDataGrid:{
            styleOverrides: {
                cellContent:{
                    fontSize: '0.75rem',
                    color: ThemeMain.palette.primary.main,
                    fontFamily: ['Poppins','sans-serif',].join(','),
                    fontWeight: 500,
                },
                columnHeaderTitle:{
                    fontSize: '0.75rem',
                    color: ThemeMain.palette.primary.main,
                    fontFamily: ['Poppins','sans-serif',].join(','),
                    fontWeight: 500,
                },
                row:{
                    '&.Mui-selected':{
                        backgroundColor: ThemeMain.palette.cultured.main

                    }
                }
            }
        },
        MuiTooltip:{
            styleOverrides: {
                tooltip: {
                    backgroundColor:ThemeMain.palette.primary.main,
                    fontFamily: ['Poppins','sans-serif',].join(','),
                    fontWeight: 500,
                    borderRadius:'0.5rem',
                    padding:'0.5rem 1rem',
                    fontSize:'0.875rem'
                },
                arrow: {
                    color:ThemeMain.palette.primary.main,
                }
            }
        },
        MuiList:{
            styleOverrides: {
                root: {
                    padding:'0'

                }
            }    
        },
        MuiToggleButton:{
            styleOverrides: {
                root: {
                    border:0,
                    borderRadius:0,
                    width:'100%',
                    justifyContent:'start',
                    fontWeight:600,
                    color:ThemeMain.palette.primary.main,
                    fontFamily: ['Poppins','sans-serif',].join(','),
                    borderBottom:`1px solid ${ThemeMain.palette.cultured.main}`,
                    textTransform:'capitalize',
                    '&.Mui-selected, &:hover':{
                        backgroundColor:'transparent !important'
                    },
                    '&.Mui-disabled':{
                        color:ThemeMain.palette.primary.main,
                        borderBottom:`1px solid ${ThemeMain.palette.cultured.main} !important`,
                        borderTop:`0 solid ${ThemeMain.palette.cultured.main} !important`,
                        borderLeft:`0 solid ${ThemeMain.palette.cultured.main} !important`,
                        borderRight:`0 solid ${ThemeMain.palette.cultured.main} !important`
                    }
                }
            }
        },
        MuiChip:{
            styleOverrides: {
                root: {
                    borderRadius:'0.5rem',
                    color:ThemeMain.palette.primary.main,
                    fontFamily: ['Poppins','sans-serif',].join(','),
                    backgroundColor:ThemeMain.palette.white.main
                }
            } 
        },
        MuiMenu:{
            styleOverrides: {
                root: {
                    '& .MuiPaper-root':{
                        border:`1px solid ${ThemeMain.palette.primary.main}`,
                        boxShadow:'none'
                    }
                }
            }
        },
        MuiSkeleton:{
            styleOverrides: {
                root: {
                    backgroundColor:ThemeMain.palette.cultured.main,
                    transform:'inherit'
                }
            }
        },
        MuiAccordionSummary:{
            styleOverrides: {
                content: {
                    margin:'0 !important',
                }
            }
        }
    }
});