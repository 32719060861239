import React, {useRef, useEffect, useState} from 'react';

import {ThemeProvider} from '@mui/material';

import SyntaxHighlighter from 'react-syntax-highlighter';
import {a11yDark} from 'react-syntax-highlighter/dist/esm/styles/prism';



const XmlViewer = (props) => {

    const refToHighlight = useRef(null);

    useEffect(() => {
        
        console.log('props.handleScroll',props.handleScroll)
        if(props.handleScroll !== undefined){
            if (refToHighlight.current) {
                props.handleScroll(refToHighlight.current.offsetTop);
                //console.log('refToHighlight.current.offsetTop', refToHighlight.current.offsetTop, refToHighlight.current)
            }
            else{
                props.handleScroll(0);
            }
        }
        
    }, [props.handleScroll]);


    return(
        <div className='p-6 w-fit min-w-full'>
            {props.nestedMap.length !== 0 && props.nestedMap.map((line, index)=> (
                <div key={index} className='flex justify-start mb-1' ref={line.hasOwnProperty('class') ? refToHighlight : null}>
                    <div className='min-w-[1.5rem] mr-3 text-left text-sonicSilver font-normal'>{index+1}</div>
                    <div className={`tagWrap font-normal z-40 indent-${line.indent} ${line.hasOwnProperty('class') ? 'highligted':''}`}>
                        <SyntaxHighlighter language="xml" style={a11yDark}>{line.line}</SyntaxHighlighter>
                    </div>
                </div>
            ))}
        </div>
    )
}

export default XmlViewer;