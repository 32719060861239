import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter as Router } from 'react-router-dom';
import { UserProvider } from './context/User_Access';
import { AuthProvider } from './context/Auth';
import { BaseUrlProvider } from './context/Base_url';
import { Auth0Provider } from '@auth0/auth0-react';
import { MediaQProvider } from './context/MediaQ';
import './wdyr';

const hasSubdomain = (url) => {
  const hostname = new URL(url).hostname;
  const parts = hostname.split('.');

  // A subdomain exists if there are more than two parts (e.g., subdomain.example.com)
  return parts.length > 2;
};

const getRedirectUri = () => {

  const baseUri = window.location.origin;

  if (hasSubdomain(baseUri)) {
    console.log('has sudomain ', baseUri)
    const hostname = window.location.hostname;
    sessionStorage.setItem('subHas', baseUri)
    if(hostname.includes('localhost')){
      console.log('baseUri', baseUri)
      return `https://company.docupathdev.app/redirect_to_login.php?redirectDomain=${baseUri}`;
    }
    else{
      return `${baseUri}/redirect_to_login.php?redirectDomain=${baseUri}`;
    }
    // return `https://dev-4j178egesnf4ubfb.us.auth0.com/authorize?scope=email openid profile&response_type=code&client_id=yWf9Iira9kfMj4Okl8eXJsdb8rNBHZYE&redirect_uri=${baseUri}/redirect&audience=https://dev-4j178egesnf4ubfb.us.auth0.com/api/v2/`;
  }  else {
    console.log('No sudomain ', baseUri)
    sessionStorage.setItem('subHasnt', baseUri)
    return `${baseUri}/create_workspace`; // Default path

  }

};

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
      <Router>
        <BaseUrlProvider>
          <Auth0Provider
            domain={process.env.REACT_APP_AUTH0_DOMAIN}
            clientId={window.location.hostname.includes('docupathdev') ?process.env.REACT_APP_AUTH0_CLIENT_ID_DEV : process.env.REACT_APP_AUTH0_CLIENT_ID_LIVE}
            redirectUri={getRedirectUri()}
            audience="https://dev-4j178egesnf4ubfb.us.auth0.com/api/v2/"
            scope="openid profile email"
            authorizationParams={{
              response_type: 'code', // You can explicitly set response_type here
              screen_hint: 'signup' 
            }}
          >
            <AuthProvider>
              <UserProvider>
                <MediaQProvider>
                  <App/>
                </MediaQProvider>
              </UserProvider>  
            </AuthProvider>
          </Auth0Provider>
        </BaseUrlProvider>
      </Router>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
