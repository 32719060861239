import React, {useState, useEffect, useRef} from 'react';
import {ThemeProvider, Skeleton } from '@mui/material';

import { ThemeCustom } from "../../styles/Theme";


const SklTab = () => {


    return(
        <ThemeProvider theme={ThemeCustom}>
            <div className='flex'>
                <Skeleton animation="wave" className='w-[10.938rem] max-h-[3.813rem] h-[3.813rem] rounded-t-xl rounded-none'></Skeleton>
                <Skeleton animation="wave" className='w-[10.938rem] max-h-[3.813rem] h-[3.813rem] rounded-t-xl rounded-none'></Skeleton>
                <Skeleton animation="wave" className='w-[10.938rem] max-h-[3.813rem] h-[3.813rem] rounded-t-xl rounded-none'></Skeleton>
            </div>
        </ThemeProvider>
    )
}

export default SklTab;