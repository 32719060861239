import React, {useEffect, useState} from 'react';

import {Box, Alert, IconButton, Collapse} from '@mui/material';

import CloseIcon from '@mui/icons-material/Close';
import CompliantIcon from '../../assets/compli-alert-light.svg'


const WarningAlert = (props) =>{

    return (
        <Box className='max-w-[480px] w-full mb-3 ml-auto'>
          <Collapse in={props.openWarning}>
            <Alert className='bg-warningYellow text-white text-base font-normal	px-4 py-[0.13rem] rounded-xl font-sans'
              variant="filled"
              action={
                <IconButton className='text-white pt-1'
                  aria-label="close"
                  onClick={() => {
                    props.setOpenWarning(false);
                  }}
                >
                  <CloseIcon className='w-6 h-6'/>
                </IconButton>
              }
              icon={<img src={CompliantIcon} alt='icon' className='w-6 h-6'/>}
            >
              {props.alretText}
            </Alert>
          </Collapse>
          
        </Box>
      );
}

export default WarningAlert;