import React, {useEffect, useState} from 'react';
import { useNavigate } from 'react-router-dom';

import {ThemeProvider, Typography, Container} from '@mui/material';
import { ThemeCustom} from "../styles/Theme";
import NavBarSimple from '../components/nav/Nav_simple';

import ButtonSecondary from '../components/button/Btn_secondary';

import docIcon from '../assets/info-green.svg'

const FinalizingFlow = () => {

    const navigate = useNavigate();

    return(
        <ThemeProvider theme={ThemeCustom}>
            <NavBarSimple isUserLogged={true} hasTenant={true}/>
            <Container maxWidth="false">
                <div className="h-screen flex justify-center items-center flex-col py-14 relative">
                    <Typography variant="text60" className="mb-4 text-center" component='div'>
                        You're good to go!
                    </Typography>
                    <Typography variant="text20" className="text-center" component='div'>
                        Next, upload and transform your first document.
                    </Typography>
                    <div className='my-16 min-h-[20rem] feature-cards-wrapper xl:w-4/5 w-full'>
                        <div className='justify-end flex'>
                            <div className='text-left normal-case rounded-[1.75rem] p-8 border border-solid border-borderGrayDark feature-card' >
                                <img src={docIcon} alt='checked' className='w-16'/>
                                <Typography variant="text30" className="font-semibold my-5" component='div'>
                                    Upload
                                </Typography>
                                <Typography variant="textBase" component='div'>
                                    Drag, drop, or email multiple formats(PDF, DOCX, XLS, JPGE), including XML and handwritten invoices.
                                </Typography>
                            </div>
                            <div className='text-left normal-case rounded-[1.75rem] p-8 border border-solid border-borderGrayDark feature-card' >
                                <img src={docIcon} alt='checked' className='w-16'/>
                                <Typography variant="text30" className="font-semibold my-5" component='div'>
                                    Recognize
                                </Typography>
                                <Typography variant="textBase" component='div'>
                                    Use the power of general Al for contextual understanding, minimizing manual input.
                                </Typography>
                            </div>
                            <div className='text-left normal-case rounded-[1.75rem] p-8 border border-solid border-borderGrayDark feature-card' >
                                <img src={docIcon} alt='checked' className='w-16'/>
                                <Typography variant="text30" className="font-semibold my-5" component='div'>
                                    Integrate
                                </Typography>
                                <Typography variant="textBase" component='div'>
                                    Select an output format and integrate directly with your ERP or third-party system.
                                </Typography>
                            </div>
                        </div>
                    </div>
                    <div>
                        <ButtonSecondary variant={'secondaryBtn'} type='button' btnName="Let's go" classes="w-full sm:w-[30rem] w-[20rem]" onClick={()=>navigate('/file_upload')}/>
                    </div>
                    {/* <div className='absolute bottom-16 flex justify-between'>
                        <div className='w-2 h-2 bg-borderGrayDark rounded-full'></div>
                        <div className='w-2 h-2 bg-borderGrayDark rounded-full mx-4'></div>
                        <div className='w-2 h-2 bg-secondary rounded-full'></div>
                    </div> */}
                </div>
            </Container>
        </ThemeProvider>
    )

}

export default FinalizingFlow;