import React, { createContext, useContext, useEffect, useState} from "react";
import { useMediaQuery } from 'react-responsive'

//create context
const MediaQContext = createContext();

//custom hook to use the context 
export const useMediaQContext = () => {
    return useContext(MediaQContext)
};


export const MediaQProvider = ({children}) => {

    const isResponsiveLg = useMediaQuery({
        query: '(max-width: 1200px)'
    })

    const isResponsiveMd = useMediaQuery({
        query: '(max-width: 900px)'
    })

    return(
        <MediaQContext.Provider value={{isResponsiveLg, isResponsiveMd}}>
            {children}
        </MediaQContext.Provider>
    )
}