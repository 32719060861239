import React, {useEffect, useState} from 'react';
import { useForm } from "react-hook-form";
import axios from "axios";
import Cookies from 'js-cookie';
import Grid from '@mui/material/Unstable_Grid2';
import { useBaseUrlContext } from '../../context/Base_url';

import {Box, Typography, Modal, FormControl, FormControlLabel, Checkbox } from '@mui/material';

import ButtonSecondary from '../button/Btn_secondary';

import ModalStyle from './Modal_styles.module.scss';
import './Modal_styles.scss';

import CheckIcon from '../../assets/tick-select.svg'; 
import UncheckIcon from '../../assets/tick-notselect.svg'; 
import { json } from 'react-router';


const columnsArray = [
[{name:'Type', value:'type'}, {name:'Payment methods', value:'payment_methods'}, {name:'Discounts', value:'discounts'}],
[{name:'Invoice date', value:'invoice_date'}, {name:'Tax information', value:'tax_information'}, {name:'Handling fees', value:'handling_fees'}],
[{name:'Due date', value:'due_date'}, {name:'Bank information', value:'bank_information'}, {name:'Subtotals', value:'subtotals'}]
]


const AddColumnModal = ({openAddColumn, setOpenAddColumn }) =>{

    const { register, handleSubmit, formState: { errors }, reset } = useForm();
    const [columns, setColumns] = useState(columnsArray)
    const [selColumns, setSelColumns] = useState(['type','payment_methods'])
    const {BaseUrl} = useBaseUrlContext()

    const cancel = () =>{
        setOpenAddColumn(false)
        reset({name:''})
    }

    const submit = () =>{

    }


    const onSubmit = (data) => {
        
 
    }

    return(
        <div>
            <Modal
                open={openAddColumn}
                aria-labelledby="modal-modal-title"
            >
                <Box className={`${ModalStyle['modal-base']} ${ModalStyle['modal-secret-key']}`}> 
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <div className={ModalStyle['modal-container']}>
                            <div className={ModalStyle['content-wrapper']}>
                                <Typography id="modal-modal-title" align='center' className={ModalStyle['modal-Header1']} variant="text24" component="h2">
                                    Add columns
                                </Typography>
                                <Grid container spacing={2} className='modal-mt-12'>
                                    {columns.length > 0 && columns.map((colGrid, index) => (
                                        <Grid xs={12} md={4} key={index}>
                                            {colGrid.map((col, i) => (
                                                <FormControl key={i} variant="standard" fullWidth  margin="normal" className='modal-mt-0 form-control modal-mb-5' >
                                                    <FormControlLabel 
                                                        control={
                                                            <Checkbox 
                                                                checked={selColumns.indexOf(col.value) > -1} 
                                                                disableTouchRipple={true}
                                                                disableRipple={true}
                                                                checkedIcon={<img src={CheckIcon} alt='checked' className='w-6'/> }
                                                                icon={<img src={UncheckIcon} alt='uncheck' className='w-6'/> }
                                                                onChange={() => setSelColumns([...selColumns, col.value])}
                                                            />
                                                        } 
                                                        label={col.name} 
                                                    />
                                                </FormControl>
                                            ))}
                                        </Grid>
                                    ))}
                                </Grid>    
                            </div>
                            <div className={ModalStyle['footer-wrapper-center']}>
                                <ButtonSecondary btnName="Cancel" variant={'primaryBtn'} classes='modal-button-12' type="button" onClick={cancel} disabled={false}/>
                                <ButtonSecondary btnName="Create" variant={'secondaryBtn'} classes='modal-button-12' type="submit" onClick={submit} disabled={false}/>
                            </div>
                        </div>    
                    </form>
                </Box>
            </Modal>
        </div> 
    );
}

export default AddColumnModal;