import React, {useEffect, useState} from 'react';
import {ThemeProvider, Box} from '@mui/material';

import MaterialReactTable from 'material-react-table';

import { ThemeCustom} from "../../styles/Theme";
import invoiceStyle from './Invoice-input_style.module.scss';

const InvoiceTableMap = (props) => {
    
      const [tableCell, setTableCell ] =  useState(1);
    

      useEffect(()=>{
        //console.log('tableData',props.tableRow)
        //console.log(props.columns)
        if(props.readonly){
          setTableCell(0.5);
        }
        else{
          setTableCell(1);
        }
      },[props.readonly]);   

    
      return (
        <ThemeProvider theme={ThemeCustom}>
            <div className='w-full' id='muiTableWrapper'>
              <Box className='w-full p-5 pb-2'>
                
                <MaterialReactTable
                  className={invoiceStyle['muiTable']}
                  columns={props.columns}
                  data={[...props.tableRow]}
                  editingMode="cell"
                  enableEditing={!props.readonly}
                  muiTableBodyCellEditTextFieldProps={({ cell }) => ({
                    //onBlur is more efficient, but could use onChange instead
                    onBlur: (event) => {
                      props.setSearchText('')
                    },
                    onFocus: (event) => {
                      props.setSearchText(cell.getValue())
                    },
                    onKeyDown: (event) => {
                      event.preventDefault()
                    },
                    onContextMenu: (event) => {
                      event.preventDefault()
                    }
                  })}
                  enableTopToolbar={false}
                  enableColumnActions={false} 
                  initialState={{density:'compact'}}
                  enablePagination={false}
                  enableBottomToolbar={false}
                  setPageSize='auto'
                  muiTablePaperProps={{
                    sx:{
                      boxShadow:'none',
                      border: '1px solid #F3F5F5',
                      borderRadius:'0.75rem',
                      marginBottom:'1rem'
                    }
                  }}
                  muiTableHeadCellProps={{
                    sx:{
                      backgroundColor:'#F8F8F8 !important',
                      padding:'0.5rem !important',
                      '& .Mui-TableHeadCell-Content-Wrapper':{
                        fontSize:'0.75rem',
                        fontWeight:'600',
                        color:'#000',
                      },
                      '&:first-of-type':{
                        borderRadius: '0.5rem 0 0 0',
                        paddingLeft:'1.25rem !important'
                      },
                      '&:last-of-type':{
                        borderRadius: '0 0.5rem 0 0'
                      }
                    }
                  }}
                  muiTableHeadProps={{
                    sx:{
                      '& .MuiTableRow-root':{
                        backgroundColor:'transparent'
                      }
                    }
                  }}
                  muiTableBodyCellProps={{
                    sx:{
                      fontSize:'0.75rem !important',
                      fontWeight:'500',
                      color:'#000',
                      borderBottom: '1px solid #e0e0e0 !important',
                      fontFamily: `${['Poppins','sans-serif',].join(',')} !important`,
                      opacity:tableCell,
                      '&:first-of-type':{
                        paddingLeft:'1.25rem'
                      }
                    }
                  }}
                  muiTableBodyRowProps={{
                    sx:{
                      '&:hover .MuiTableCell-root':{
                        backgroundColor:'#F3F5F5'
                      }
                    }
                  }}
                />
              </Box>
            </div>
        </ThemeProvider>
      );
}

export default InvoiceTableMap;