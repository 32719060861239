import React, {useEffect, useState} from 'react';

import {Box, Typography, Modal} from '@mui/material';

import ButtonSecondary from '../button/Btn_secondary';
import ButtonSecondaryLoader from '../button/Btn_secondary_loader';

import ModalStyle from './Modal_styles.module.scss';

import deleteIcon from '../../assets/delete-modal.gif';

//const BaseUrl = 'https://docupath.app';


const DeleteV2Modal = ({openDelete, setOpenDelete, delText, deleteFun, btnLoading}) =>{
    
    return(
        <div>
            <Modal
                open={openDelete}
                aria-labelledby="modal-modal-title"
            >
                <Box className={`${ModalStyle['modal-base']} ${ModalStyle['modal-delete']}`}> 
                    <div className={ModalStyle['modal-container']}>
                        <div className={ModalStyle['content-wrapper']}>
                            <div className={ModalStyle['icon-wrapper']}>
                                <img src={deleteIcon} alt='delete icon' className={ModalStyle["icon-img"]}/>
                            </div>
                                <Typography id="modal-modal-title" className={ModalStyle['modal-Header1']} align={'center'} variant="text24" component="h2">
                                    Are you sure you want to delete {delText ? `this ${delText}`: ''}?
                                </Typography>
                        </div>
                        <div className={ModalStyle['footer-wrapper-center']}>
                            <ButtonSecondary btnName="Cancel" type="button" variant={'platinumBtn'} classes="mr2" onClick={() => setOpenDelete(false)}/>
                            <ButtonSecondaryLoader variant={'redBtn'} type='button' btnName='Delete' btnLoading={btnLoading} disabled={false} onClick={deleteFun}/>
                        </div>
                    </div>    
                </Box>
            </Modal>
        </div> 
    );
}

export default DeleteV2Modal;