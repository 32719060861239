import React, {useEffect, useState} from 'react';
import { useForm } from "react-hook-form";
import axios from "axios";
import Cookies from 'js-cookie';
import { useBaseUrlContext } from '../../context/Base_url';
import { useAuthContext } from '../../context/Auth';
import useAxiosInstance from '../../api/interceptor';

import {Box, Typography, Modal, FormControl, OutlinedInput, InputLabel, Skeleton} from '@mui/material';

import ButtonSecondary from '../button/Btn_secondary';

import ModalStyle from './Modal_styles.module.scss';
import './Modal_styles.scss';

const RejectReasonModal = ({openRejectReason, setOpenRejectReason, setOpenFail, setTextFail, setOpenSuccess, setTextSuccess, postReject}) =>{

    const { register, handleSubmit, formState: { errors }, reset, setValue } = useForm();
    const {BaseUrl} = useBaseUrlContext()
    const {tokenReview, popupAction} = useAuthContext()
    const axiosInstance = useAxiosInstance();



    const cancel = () =>{
        
        setOpenRejectReason(false)
        reset({rejectReason:''})

    }

    useEffect(()=>{
        reset({rejectReason:''})
    },[openRejectReason])


    //new data post

    const onSubmit = async(data) => {

        console.log('data post', data)
        postReject(data)  

    }


    return(
        <div>
            <Modal
                open={openRejectReason}
                aria-labelledby="modal-modal-title"
            >
                <Box className={`${ModalStyle['modal-base']} ${ModalStyle['modal-secret-key']}`}> 
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <div className={ModalStyle['modal-container']}>
                            <div className={ModalStyle['content-wrapper']}>
                                    <>
                                        <Typography id="modal-modal-title" align='center' className={ModalStyle['modal-Header1']} variant="text24" component="h2">
                                            Rejection details
                                        </Typography>
                                        <FormControl variant="standard" fullWidth  margin="normal" className='modal-mt-12 form-control modal-mb-5' >
                                            <InputLabel shrink htmlFor='name' title='text' className='modal-label'>
                                                Reason for Decision
                                            </InputLabel>
                                                <OutlinedInput 
                                                    id='rejectReason'
                                                    placeholder='Please explain the reason for rejection.'
                                                    //value={cusRules}
                                                    autoComplete="off" 
                                                    type='text'
                                                    multiline
                                                    rows={12}
                                                    className='modal-input multiple-line'
                                                    {...register('rejectReason',{ required: false })} 
                                                />    
                                                {errors.rejectReason && <p className='err-msg'>Rules are required</p>}
                                        </FormControl>
                                    </>
                            </div>
                            <div className={ModalStyle['footer-wrapper-center']}>
                                <ButtonSecondary btnName="Cancel" variant={'primaryBtn'} classes='modal-button-12' type="button" onClick={cancel} />
                                <ButtonSecondary btnName={'Confirm'} variant={'secondaryBtn'} classes='modal-button-12' type="submit" />
                            </div>
                        </div>    
                    </form>
                </Box>
            </Modal>
        </div> 
    );
}

export default RejectReasonModal;