import React, {useEffect, useState} from 'react';
import { useForm } from "react-hook-form";
import axios from "axios";
import Cookies from 'js-cookie';
import { useBaseUrlContext } from '../../context/Base_url';
import { useAuthContext } from '../../context/Auth';
import useAxiosInstance from '../../api/interceptor';

import {Box, Typography, Modal, FormControl, OutlinedInput, InputLabel, Skeleton} from '@mui/material';

import ButtonSecondary from '../button/Btn_secondary';
import SklInputPopup from '../skeletons/Skl_input_popup';
import ButtonSecondaryLoader from '../button/Btn_secondary_loader';

import ModalStyle from './Modal_styles.module.scss';
import './Modal_styles.scss';

const CustomRulesAddModal = ({openAddCusRules, setOpenAddCusRules, editId, popupCus2Loader, setOpenFail, setTextFail, setOpenSuccess, setTextSuccess, getTableDataCustomTab, customInstruct}) =>{

    const { register, handleSubmit, formState: { errors }, reset, setValue } = useForm();
    const [uploading, setUploading] = useState(false)
    const {BaseUrl} = useBaseUrlContext()
    const {tokenReview, popupAction} = useAuthContext()
    const axiosInstance = useAxiosInstance();
    const [btnLoading, setBtnLoading] = useState(false)

    //update custom rules

    useEffect(()=>{
        if(openAddCusRules){
            setValue('rulesCus', customInstruct)
            setUploading(false)
            //getTableDataCustomTab()
        }
        console.log('customInstruct xx',customInstruct)

    },[openAddCusRules])

    const cancel = () =>{
        
        setOpenAddCusRules(false)
        reset({rulesCus:''})

    }

    //form submit

    const onSubmit = (data) => {

        console.log('submit data', data)
        dataPost(data);

    }

    //new data post

    const dataPost = async(data) => {

        console.log('data post', data)
        setBtnLoading(true)

            try {

                const res = await axiosInstance.post(`/settings.php`, {customInstructions: data.rulesCus})

                //console.log(res)
                let result = res.data.result;
                let error = res.data.error;
    
                setUploading(false)
                setOpenAddCusRules(false)
                getTableDataCustomTab()
                setOpenSuccess(true)
                setTextSuccess(result.message)
                reset({rules:''})
                setBtnLoading(false)

            } catch (err) {
                console.error('fetchData:', err);
                setBtnLoading(false)
            }         

    }


    return(
        <div>
            <Modal
                open={openAddCusRules}
                aria-labelledby="modal-modal-title"
            >
                <Box className={`${ModalStyle['modal-base']} ${ModalStyle['modal-secret-key']}`}> 
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <div className={ModalStyle['modal-container']}>
                            <div className={ModalStyle['content-wrapper']}>
                                    <>
                                        <Typography id="modal-modal-title" align='center' className={ModalStyle['modal-Header1']} variant="text24" component="h2">
                                            {editId === 0 ? 'Add new custom rules' : 'Edit custom rules'} 
                                        </Typography>
                                        <FormControl variant="standard" fullWidth  margin="normal" className='modal-mt-12 form-control modal-mb-5' >
                                            <InputLabel shrink htmlFor='name' title='text' className='modal-label'>
                                                Rules
                                            </InputLabel>
                                            {popupCus2Loader?(
                                                <Skeleton animation="wave"  className='modal-rules-cus2-skeleton'></Skeleton>
                                            ):(
                                                <>
                                                    <OutlinedInput 
                                                        id='rulesCus'
                                                        placeholder='Enter custom rules'
                                                        //value={cusRules}
                                                        autoComplete="off" 
                                                        type='text'
                                                        multiline
                                                        rows={12}
                                                        className='modal-input multiple-line'
                                                        {...register('rulesCus',{ required: true })} 
                                                    />    
                                                    {errors.rulesCus && <p className='err-msg'>Rules are required</p>}
                                                </>
                                                )}
                                        </FormControl>
                                    </>
                            </div>
                            <div className={ModalStyle['footer-wrapper-center']}>
                                <ButtonSecondary btnName="Cancel" variant={'primaryBtn'} classes='modal-button-12' type="button" onClick={cancel} disabled={uploading}/>
                                {/* <ButtonSecondary btnName={editId === 0? "Add":"Save"} variant={'secondaryBtn'} classes='modal-button-12' type="submit" disabled={uploading}/> */}
                                <ButtonSecondaryLoader variant={'secondaryBtn'} type='submit' classes='modal-button-12' btnName={editId === 0? "Add":"Save"} btnLoading={btnLoading} disabled={uploading}/>
                            </div>
                        </div>    
                    </form>
                </Box>
            </Modal>
        </div> 
    );
}

export default CustomRulesAddModal;