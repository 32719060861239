import React, {useState, useEffect} from 'react';
import axios from "axios";
import Cookies from 'js-cookie';
import { useBaseUrlContext } from '../../context/Base_url';
import { useOutletContext } from 'react-router-dom';
import { useAuthContext } from '../../context/Auth';
import useAxiosInstance from '../../api/interceptor';

import {ThemeProvider, Typography} from '@mui/material';

import { ThemeCustom} from "../../styles/Theme";
import 'simplebar-react/dist/simplebar.min.css';
import './settings_styles.scss';

import InstructTable from './Instruct_table';
import ButtonImg from '../button/Btn_img';
import RulesAddModal from '../modal/Modal_rules_add';
import DeleteV2Modal from '../modal/Modal_delete_V2';
import CustomRulesModal from '../modal/Modal_custom_rules_add'
import OrgRulesAddModal from '../modal/Modal_org_rules_add';

import plusIcon from '../../assets/add-light.svg'


const Instruct = ({openFail, textFail, openSuccess, textSuccess}) =>{

    const {setOpenFail} = useOutletContext(); 
    const {setTextFail} = useOutletContext(); 
    const {setOpenSuccess} = useOutletContext(); 
    const {setTextSuccess} = useOutletContext(); 
    const {BaseUrl} = useBaseUrlContext()

    const [openAddRules, setOpenAddRules] = useState(false);
    const [openAddOrgRules, setOpenAddOrgRules] = useState(false);
    const [openAddCusRules, setOpenAddCusRules] = useState(false);
    const [editId, setEditId] = useState(1)
    const [rules, setRules] = useState([]);
    const [orgs, setOrgs] = useState([]);
    const [openDelete, setOpenDelete] = useState(false);
    const [delText, setDelText] = useState('rule')
    const [addNewVisible, setAddNewVisible] = useState(true)
    const [tabView, setTabView] = useState(0)
    const [loader, setLoader] = useState(true);
    const [customInstruct, setCustomInstruct] = useState('')
    const [popupCus2Loader, setPopupCus2Loader] = useState(false)
    const {tokenReview, popupAction} = useAuthContext()
    const axiosInstance = useAxiosInstance();
    const [btnLoading, setBtnLoading] = useState(false);

    useEffect(() => {

        getTableData();

    },[])   

    useEffect(() => {

        console.log('rules xxx', rules)

    },[rules])   
    
    //get org table data

    const getTableDataOrg = async() =>{

        setLoader(true);

        try {

            const res = await axiosInstance.get(`/organization_instructions.php?instructions=1`)

            let data = res.data.result;
            let error = res.data.error;

            console.log('organization_instructions', res.data.result)
            setRules(data.instructions)
            setTimeout(()=>{
                setLoader(false);
            },500)
                    
        } catch (err) {
            console.error('fetchData:', err);
            setTimeout(()=>{
                setLoader(false);
            },500)
        } 

    }

    //get Region & countries table data

    const getTableData = async() =>{

        setLoader(true);

        try {

            const res = await axiosInstance.get(`/instructions.php?instructions=1`)
            
            let data = res.data.result;
            let error = res.data.error;

            console.log(res.data.result)
            setRules(data.countries)

            setTimeout(()=>{
                setLoader(false);
            },500)
                      

        } catch (err) {
            console.error('fetchData:', err);
            setTimeout(()=>{
                setLoader(false);
            },500)
        } 

    }

    const getTableDataCustomTab = async () =>{

        setLoader(true);

        try {

            setPopupCus2Loader(true)

            const res = await axiosInstance.get(`/settings.php?customInstructions=1`)

            let data = res.data.result;
            let error = res.data.error;

            console.log(res.data.result)
            setCustomInstruct(data.result)
            setPopupCus2Loader(false)

            setTimeout(()=>{
                setLoader(false);
            },500)     

        } catch (err) {
            setTimeout(()=>{
                setLoader(false);
            },500)
            console.error('fetchData:', err);
        }     

    }

    const deleteFun = async () => {
        console.log('delete function-------------------')
        setBtnLoading(true)

        if(tabView === 1){

            try {

                const deleteId = JSON.stringify({
                    "instructionId": editId
                });

                const res = await axiosInstance.delete(`/organization_instructions.php?`, {data:deleteId})
        
                let data = res.data.result;
                let error = res.data.error;
    
                setOpenDelete(false);
                getTableDataOrg();
                setOpenSuccess(true)
                setTextSuccess(data.message)   
                setBtnLoading(false)

            } catch (err) {
                console.error('fetchData:', err);
                setBtnLoading(false)
            }    

        }
        else if(tabView === 2){

            try {

                const res = await axiosInstance.delete(`/settings.php?customInstructions=1`)
            
                    let data = res.data.result;
                    let error = res.data.error;
            
                    setOpenDelete(false);
                    getTableDataCustomTab();
                    setOpenSuccess(true)
                    setTextSuccess(data.message)
                    setBtnLoading(false)

            } catch (err) {
                console.error('fetchData:', err);
                setBtnLoading(false)
            }    

        }
        else{

            try {

                const deleteId = JSON.stringify({
                    "countryCode": editId
                });
    
                const res = await axiosInstance.delete(`/instructions.php`,{data:deleteId})
        
                let data = res.data.result;
                let error = res.data.error;
        
                setOpenDelete(false);
                getTableData();
                setOpenSuccess(true)
                setTextSuccess(data.message)
                setBtnLoading(false)

            } catch (err) {
                console.error('fetchData:', err);
                setBtnLoading(false)
            }   

        }

    }

    useEffect(()=>{console.log('tabView',tabView)},[tabView])

    const addNewButton = () =>{
        if(tabView === 0){
            setOpenAddRules(true); 
            setEditId(0);
        } 
        else if(tabView === 1){
            setOpenAddOrgRules(true); 
            setEditId(0);
        } 
        else{
            setOpenAddCusRules(true); 
            setEditId(0)
        }
    }
    
    return(
        <ThemeProvider theme={ThemeCustom}>
            <DeleteV2Modal
                openDelete={openDelete}
                setOpenDelete={setOpenDelete}
                deleteFun={deleteFun}
                delText={delText}
                editId={editId}
                btnLoading={btnLoading}
            />
            <OrgRulesAddModal
                openAddOrgRules={openAddOrgRules} 
                setOpenAddOrgRules={setOpenAddOrgRules} 
                editId={editId}
                getTableDataOrg={getTableDataOrg}
                setOpenFail={setOpenFail}
                setTextFail={setTextFail}
                setOpenSuccess={setOpenSuccess}
                setTextSuccess={setTextSuccess}
                tabView={tabView}
                customInstruct={customInstruct}
                tokenReview={tokenReview}
                popupCus2Loader={popupCus2Loader}
            />
            <RulesAddModal
                openAddRules={openAddRules} 
                setOpenAddRules={setOpenAddRules} 
                editId={editId}
                getTableData={getTableData}
                setOpenFail={setOpenFail}
                setTextFail={setTextFail}
                setOpenSuccess={setOpenSuccess}
                setTextSuccess={setTextSuccess}
                tabView={tabView}
                customInstruct={customInstruct}
                tokenReview={tokenReview}
                popupCus2Loader={popupCus2Loader}
            />
            <CustomRulesModal
                openAddCusRules={openAddCusRules}
                setOpenAddCusRules={setOpenAddCusRules}
                editId={editId}
                setOpenFail={setOpenFail}
                setTextFail={setTextFail}
                setOpenSuccess={setOpenSuccess}
                setTextSuccess={setTextSuccess}
                customInstruct={customInstruct}
                tokenReview={tokenReview}
                popupCus2Loader={popupCus2Loader}
                getTableDataCustomTab={getTableDataCustomTab}
            />
            <div className='mb-12'>
                <div className='flex justify-between items-start'> 
                    <div>
                        <Typography variant="text32" className="mb-3" component='div'>
                            Instruction Builds
                        </Typography>
                        <Typography variant="text20" className="mb-3" component='div'>
                            Create and modify instructions and compliance rules specific to each country.
                        </Typography>
                    </div>
                    <div>
                        {addNewVisible?(
                            <ButtonImg btnName="Add New" img={plusIcon} type="button" classes=" min-w-[11.5rem] px-3" 
                            onClick={addNewButton}/>
                        ):''}
                    </div>
                </div>
            </div>
            <div>
                <InstructTable
                    setOpenAddRules={setOpenAddRules} 
                    setOpenAddCusRules={setOpenAddCusRules}
                    setEditId={setEditId}
                    rules={rules}
                    setOpenFail={setOpenFail}
                    setTextFail={setTextFail}
                    setOpenSuccess={setOpenSuccess}
                    setTextSuccess={setTextSuccess}
                    setOpenDelete={setOpenDelete}
                    setAddNewVisible={setAddNewVisible}
                    setTabView={setTabView}
                    loader={loader}
                    getTableData={getTableData}
                    getTableDataCustomTab={getTableDataCustomTab}
                    customInstruct={customInstruct}
                    getTableDataOrg={getTableDataOrg}
                    setOpenAddOrgRules={setOpenAddOrgRules} 
                />
            </div>
         
        </ThemeProvider>
    )
}

export default Instruct;