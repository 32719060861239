import React, {useState, useEffect, useRef} from 'react';
import {ThemeProvider, Skeleton} from '@mui/material';

import { ThemeCustom } from "../../styles/Theme";


const SklInstructCus = () => {

    return(
        <ThemeProvider theme={ThemeCustom}>
            <div className='flex justify-between w-full items-start'>
                <div className='w-[calc(100%-10rem)] p-10 pr-4'>
                    {[0,1,2].map((_,i)=>(
                        <Skeleton key={i} animation="wave" className={` w-full h-[1.25rem] mb-1 rounded`}></Skeleton>    
                    ))}
                    <Skeleton animation="wave" className={` w-[70%] h-[1.25rem] mb-1 rounded`}></Skeleton>      
                </div>
                <div className={`flex text-center max-w-[10rem] p-4`}>
                    <Skeleton animation="wave" className={` w-[1.56rem] h-[1.56rem] mr-4 rounded`}></Skeleton>    
                    <Skeleton animation="wave" className={` w-[1.56rem] h-[1.56rem] mr-4 rounded`}></Skeleton>  
                </div>
            </div>
        </ThemeProvider>
    )
}

export default SklInstructCus;

