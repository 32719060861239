import React, {useState, useEffect, useRef} from 'react';
import {ThemeProvider, Skeleton} from '@mui/material';

import { ThemeCustom } from "../../styles/Theme";


const SklSwiper = () => {

    return(
        <ThemeProvider theme={ThemeCustom}>
            <div className='border-borderGrayDark border border-solid rounded-2xl p-5'>
                <div className='flex items-start'>
                    <Skeleton animation="wave" className={`min-w-[3.5rem] h-[3.5rem] rounded-full`}></Skeleton>   
                    <div className='ml-5 flex flex-col max-w-[72%] w-full'>
                        <Skeleton animation="wave" className={` w-full h-[1.5rem] mb-2 rounded`}></Skeleton>    
                        <Skeleton animation="wave" className={` w-[3rem] h-[2.25rem] rounded`}></Skeleton>    
                    </div>
                </div>
            </div>
        </ThemeProvider>
    )
}

export default SklSwiper;

