import React, {useEffect, useState} from 'react';
import {Link } from 'react-router-dom';

import {ThemeProvider, Typography} from '@mui/material';
import { ThemeCustom} from "../styles/Theme";

import loaderIcon from '../assets/Loading1.svg'
import notAllowIcon from '../assets/not-allow.gif'

const NotAccess = (props) => {

    return(
        <ThemeProvider theme={ThemeCustom}>
                <div className={`${props.class? props.class:'h-screen'} flex justify-center items-center flex-col`}>
                    <img src={notAllowIcon} alt='exit icon' className='max-w-[7rem] w-full mb-7'/>
                    <div className="text-lg font-sans font-semibold mb-2">You do not have permission to access this feature.</div> 
                </div>
        </ThemeProvider>
    )
}

export default NotAccess;