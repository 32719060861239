import React, {useState, useEffect, useRef} from 'react';
import {ThemeProvider, Skeleton} from '@mui/material';

import { ThemeCustom } from "../../styles/Theme";


const SklChart = () => {

    return(
        <ThemeProvider theme={ThemeCustom}>
            <div className='flex items-end justify-between h-full'>
                <Skeleton animation="wave" className={`max-w-[4rem] w-full h-[50%] rounded mx-1`}></Skeleton>  
                <Skeleton animation="wave" className={`max-w-[4rem] w-full h-[60%] rounded mx-1`}></Skeleton>   
                <Skeleton animation="wave" className={`max-w-[4rem] w-full h-[75%] rounded mx-1`}></Skeleton>   
                <Skeleton animation="wave" className={`max-w-[4rem] w-full h-[50%] rounded mx-1`}></Skeleton>   
                <Skeleton animation="wave" className={`max-w-[4rem] w-full h-[80%] rounded mx-1`}></Skeleton>      
                <Skeleton animation="wave" className={`max-w-[4rem] w-full h-[100%] rounded mx-1`}></Skeleton>   
                <Skeleton animation="wave" className={`max-w-[4rem] w-full h-[60%] rounded mx-1`}></Skeleton>   
                <Skeleton animation="wave" className={`max-w-[4rem] w-full h-[80%] rounded mx-1`}></Skeleton>   
                <Skeleton animation="wave" className={`max-w-[4rem] w-full h-full rounded mx-1`}></Skeleton>    
            </div>
        </ThemeProvider>
    )
}

export default SklChart;

