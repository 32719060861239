import React, {useEffect, useState, useRef} from 'react';
import { useForm } from "react-hook-form";

import {ThemeProvider, OutlinedInput , FormControl, InputLabel, InputAdornment, Tooltip } from '@mui/material';
import { ThemeCustom} from "../../styles/Theme";

import invoiceStyle from './Invoice-input_style.module.scss';

import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';

const InvoiceInput = (props) =>{

    const inputRef = props.inputRef;
    const { register, handleSubmit, formState: { errors } } = useForm();

    const inputChange = (e, defValue) =>{

        const newValue = e.target.value;
        const isUpdated  = newValue != defValue
        console.log('newValue >', newValue, 'defValue >', defValue, 'isUpdated >', isUpdated);
        props.onInputChange(newValue, isUpdated);

    }

    //pdf text highlight ###

    const handleFocus = (e) => {
        //console.log("handleFocus",e.target.value);
        props.setSearchText(e.target.value);
    }

    //pdf text highlight ###

    const handleBlur = (e) => {
        //console.log("handleFocus",e.target.value);
        props.setSearchText('');
    }

    //switch value to input detecting value ###

    const valueSwitch = (detectVal, isUpdated) => {
        props.onInputChange(detectVal, isUpdated);
        console.error('detectVal',detectVal, isUpdated)
    }

    //debug

    useEffect(()=>{
       // console.log('props input', props)
    },[props])

    return(
        <ThemeProvider theme={ThemeCustom}>
            {props.compliant?.code === 1 ? (
                <FormControl variant="standard" fullWidth  margin="normal" className='flex flex-row items-center my-1'>
                    <InputLabel shrink htmlFor={`id-${props.inputName}-${props.id}`} className={`w-[8rem] mb-0 mr-3 text-right text-xs`} title={(props.inputName).replace(/([A-Z][a-z])/g, ' $1').trim()}>{(props.inputName).replace(/([A-Z][a-z])/g, ' $1').trim()}</InputLabel>
                    <OutlinedInput 
                        inputRef={inputRef}
                        id={`id-${props.inputName}-${props.id}`}
                        autoComplete="off" 
                        type={props.inputType}
                        // value={!props.value ? '':props.value}
                        defaultValue={!props.value ? '':props.value}
                        readOnly={true}
                        endAdornment={
                        <InputAdornment position="end">
                             <Tooltip title={props.compliant.message} arrow placement="right">
                                <ErrorOutlineIcon className='text-crayolaIcon text-base cursor-pointer'/>
                             </Tooltip>
                        </InputAdornment>
                        }
                        className={`max-h-[2rem] rounded-md font-semibold !bg-azureish ${invoiceStyle['invoice-input']} ${invoiceStyle['invoice-compliant']} ${props.readonly ? "opacity-50	":""}`}
                        sx={{'&.Mui-focused .MuiOutlinedInput-notchedOutline':{border:'1px solid #9FC7FF'}}}
                        {...register(`input-${props.inputName}`,{onChange: (e) => {inputChange(e, props.defValue); console.log('props.defValue >>> ', props.defValue)}} )} 
                        onFocus={(e)=>handleFocus(e)}
                        onBlur={(e)=>handleBlur(e)}
                    />
                </FormControl>
            ):props.compliant?.code === 2 ? (
                <FormControl variant="standard" fullWidth  margin="normal" className='flex flex-row items-center my-1'>
                    <InputLabel shrink htmlFor={`id-${props.inputName}-${props.id}`} className={`w-[8rem] mb-0 mr-3 text-right text-xs`} title={(props.inputName).replace(/([A-Z][a-z])/g, ' $1').trim()}>{(props.inputName).replace(/([A-Z][a-z])/g, ' $1').trim()}</InputLabel>
                    <OutlinedInput 
                        inputRef={inputRef}
                        id={`id-${props.inputName}-${props.id}`}
                        autoComplete="off" 
                        type={props.inputType}
                        // value={!props.value ? '':props.value}
                        defaultValue={!props.value ? '':props.value}
                        readOnly={true}
                        endAdornment={
                        <InputAdornment position="end">
                            <Tooltip 
                                title={
                                    <>
                                        {
                                            props.value !== props.compliant.documentValue ?  (
                                            <>    
                                            {`${props.compliant.message} Previous value: ${props.compliant.documentValue}.`}
                                            <span className='value-switch-tooltip' onClick={()=>valueSwitch(props.compliant.documentValue, true)}>Switch back to detected value.</span>
                                            </>
                                        ):(
                                            <>
                                            {`Switched back to detected value.`}
                                            <span className='value-switch-tooltip' onClick={()=>valueSwitch(props.compliant.autoValue, false)}>Undo</span> 
                                            </>
                                        )
                                        }
                                    </>
                                } 
                                arrow 
                                placement="right"
                            >
                                <ErrorOutlineIcon className='text-gambogeIcon text-base cursor-pointer'/>
                            </Tooltip>
                        </InputAdornment>
                        }
                        className={`max-h-[2rem] rounded-md font-semibold !bg-almond ${invoiceStyle['invoice-input']} ${invoiceStyle['invoice-compliant']} ${props.readonly ? "opacity-50	":""}`}
                        sx={{'&.Mui-focused .MuiOutlinedInput-notchedOutline':{border:'1px solid #FFCC82'}}}
                        {...register(`input-${props.inputName}`,{onChange: (e) => {inputChange(e, props.defValue)}} )} 
                        onFocus={(e)=>handleFocus(e)}
                        onBlur={(e)=>handleBlur(e)}
                    />
                </FormControl>
            ):(
                <FormControl variant="standard" fullWidth  margin="normal" className='flex flex-row items-center my-1'>
                    <InputLabel shrink htmlFor={`id-${props.inputName}-${props.id}`} className={`w-[8rem] mb-0 mr-3 text-right text-xs`} title={(props.inputName).replace(/([A-Z][a-z])/g, ' $1').trim()}>{(props.inputName).replace(/([A-Z][a-z])/g, ' $1').trim()}</InputLabel>
                    <OutlinedInput 
                        inputRef={inputRef}
                        id={`id-${props.inputName}-${props.id}`}
                        autoComplete="off" 
                        type={props.inputType}
                        //value={!props.value ? '':props.value}
                        defaultValue={!props.value ? '':props.value}
                        className={`max-h-[2rem] rounded-md font-semibold ${invoiceStyle['invoice-input']} ${props.readonly ? "opacity-50	":""}`}
                        {...register(`input-${props.inputName}`,{onChange: (e) => {inputChange(e, props.defValue)}})} 
                        readOnly={props.readonly}
                        onFocus={(e)=>handleFocus(e)}
                        onBlur={(e)=>handleBlur(e)}
                    />
                </FormControl>
            )}
        </ThemeProvider>
    )
}

export default InvoiceInput;