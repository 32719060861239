import React, {useEffect, useState} from 'react';
import axios from "axios";
import Cookies from 'js-cookie';
import { Navigate, useNavigate} from 'react-router-dom';
import { useBaseUrlContext } from '../../context/Base_url';

import {ThemeProvider, Box, Typography, Modal} from '@mui/material';
import { ThemeCustom } from '../../styles/Theme';

import ButtonSecondary from '../button/Btn_secondary';

import notAllowIcon from '../../assets/not-allow.gif'
import ModalStyle from './Modal_styles.module.scss';
import './Modal_styles.scss'

const UnAuthModal = ({openUnAuth, setOpenUnAuth}) =>{

    const navigate = useNavigate();
    const {BaseUrl} = useBaseUrlContext()
    const [cookieOptions, setCookieOptions] = useState({})

    useEffect(()=>{

        const domain = window.location.hostname
        const baseDomain = domain.split('.').slice(-2).join('.');
        const cookieDomain = domain === 'localhost' ? 'localhost' : `.${baseDomain}`;
        const isSecure = domain === 'localhost' ? false : domain.includes('invoiceai.local') ? false : true;

        setCookieOptions(
            {
                path: '/',
                secure: isSecure, 
                sameSite: 'lax', // Use 'lax' to balance security and accessibility
                //domain: cookieDomain,
                
            }
        )
        
    },[])

    useEffect(()=>{
        console.log('cookieOptions >',cookieOptions)
    },[cookieOptions])

    const logOut = () =>{
        console.log('logOut unAuth')
        setOpenUnAuth(false);

        Cookies.remove('token', cookieOptions);
        Cookies.remove('expire', cookieOptions);
        Cookies.remove('refreshToken', cookieOptions);
        Cookies.remove('userEmail', cookieOptions);
        Cookies.remove('userName', cookieOptions);
        Cookies.remove('userId', cookieOptions);
        Cookies.remove('auth0Id', cookieOptions);
        Cookies.remove('access', cookieOptions);
        navigate('/');

    }

    return(
        <ThemeProvider theme={ThemeCustom}>
        <div>
            <Modal
                open={openUnAuth}
                aria-labelledby="modal-modal-title"
            >
                <Box className={`${ModalStyle['modal-base']} ${ModalStyle['modal-verfy-output']}`}> 
                    <div className={ModalStyle['modal-container']}>
                        <div className={ModalStyle['content-wrapper']}>
                            <div className={ModalStyle['icon-wrapper']}>
                                <img src={notAllowIcon} alt='delete icon' className={ModalStyle["icon-img"]}/>
                            </div>
                            <Typography id="modal-modal-title" className={ModalStyle['modal-Header1']} align={'center'} variant="text24" component="h2">
                                Not authorized
                            </Typography>
                            <Typography id="modal-modal-description" className={`${ModalStyle['modal-text-base']} modal-font-medium`} align={'center'} variant="textBase" component="p">
                                Your session has expired or you do not have permission to access this page. Please log in again to continue.
                            </Typography>
                        </div>
                        <div className={ModalStyle['footer-wrapper-center']}>
                            <ButtonSecondary btnName="Sign in" variant={'secondaryBtn'} type="submit" onClick={logOut} disabled={false}/>
                        </div>
                    </div>    
                </Box>
            </Modal>
        </div> 
        </ThemeProvider>
    );
}

export default UnAuthModal;