import React, {useState, useEffect, useRef} from 'react';
import SimpleBar from 'simplebar-react';
import axios from "axios";
import Cookies from 'js-cookie';
import { useNavigate, Outlet, Link } from 'react-router-dom';
import PropTypes from 'prop-types';

import {ThemeProvider, Table, TableHead, TableBody, TableCell, TableContainer, TableRow, Tooltip, Skeleton, IconButton, Tabs, Tab, Box,InputAdornment, Typography, OutlinedInput, FormControl} from '@mui/material';

import { ThemeCustom } from "../../styles/Theme";

import NotFound from '../masseges/Not_found';
import SklTable from '../skeletons/Skl_table';
import SklInstructCus from '../skeletons/Skl_instruct_cus';

import EditIcon from '../../assets/edit-new.svg'
import DeleteIcon from '../../assets/delete.svg'
import SearchIcon from '../../assets/search.svg'

function TabPanel(props) {
    const { children, value, index, ...other } = props;
  
    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
            <Box sx={{ p: 3 }}>
                <Typography component={'div'}>{children}</Typography>
            </Box>
            )}
         </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};
  
function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

const InstructTable = (props) => {

    const [notFound, setNotFound] = useState(false);
    const [value, setValue] = useState(0);
    const [showMore, setShowMore] = useState(false);
    const [searchTerm, setSearchTerm] = useState('');
    const [searchItem, setSearchItem] = useState([]);
    //const [customInstruct, setCustomInstruct] = useState('1. Start by detecting the buyer, seller and shipping details. 2. For Additional Fields: Only include any payment notes. Exclude any irrelevant details from Additional Fields. Please ensure that you translate both field names and values into English in Additional Fields.')
    const [clickCode, setClickCode] = useState('');  

    //add new button visibility

    useEffect(()=>{
        if(value === 0 || value === 1 || (props.customInstruct === '' && value === 2)){
            props.setAddNewVisible(true)
        }
        else{
            props.setAddNewVisible(false)
        }
        console.log('visibility***')
    },[props.customInstruct, value])


    //set table data

    useEffect(() => {

        setSearchItem(props.rules);

    },[props.rules])  


    //search

    useEffect(()=>{

        if(props.rules.length > 0){
            const filteredRules = props.rules.filter((rule) =>
            rule.instructions.toLowerCase().includes(searchTerm.toLowerCase())
            );
            setSearchItem(filteredRules);
            console.log('searchItem',filteredRules);
        }

    },[searchTerm])
    


    useEffect(()=>{
        console.log('not found',props.rules);
        if(searchItem.length === 0){
            setNotFound(true)
            console.log('not found if',props.rules);
        }
        else{
            setNotFound(false)
            console.log('not found else',props.rules);
        }
    },[searchItem])

    //editRegion

    const editInstruct = (id) => {
        console.log('editRegion id - ', id)
        props.setEditId(id)
        props.setOpenAddRules(true)
    }

    const editOrgInstruct = (id) => {
        console.log('editRegion id - ', id)
        props.setEditId(id)
        props.setOpenAddOrgRules(true)
    }

    //deleteRegion

    const deleteInstruct = (id) => {
        console.log('deleteRegion id - ', id)
        props.setEditId(id)
        props.setOpenDelete(true)
    }

    //tab change 

    const handleChange = (event, newValue) => {

        console.log('tab view', newValue)
        setValue(newValue);
        props.setTabView(newValue)
        setSearchTerm('')

        if(newValue === 1){
            props.getTableDataOrg()
            props.setEditId(0)
        }
        else if(newValue === 2){
            props.getTableDataCustomTab()
            props.setEditId(0)
        }
        else{
            props.getTableData()
        }
        
    }; 
    
    //specific tab

    const specific = () => {

    }

    //custom tab

    const custom = () => {

    }

    const editCustomInstruct = () => {
        props.setOpenAddCusRules(true)
        props.setEditId(1)
        //props.getTableDataCustomTab()
    }

    const delCustomInstruct = () => {
        props.setOpenDelete(true)
    }

    useEffect(()=>{
        console.log('clickCode---',clickCode)
    },[clickCode])

    const InstructionContent = ({ instructions, code }) => {
        console.log('InstructionContent', instructions, instructions.replace(/\n/g, '<br>'))
        return (
            showMore ? (
                <>  
                    {clickCode === code ? (
                        <span dangerouslySetInnerHTML={{ __html: instructions ? instructions.replace(/\n/g, '<br>'):'' }} />
                    ):(
                        <span dangerouslySetInnerHTML={{ __html: instructions ? instructions.replace(/\n/g, '<br>').substring(0, 500):''}} />
                    )}
                    {instructions.length > 500 && clickCode === code  ? (
                        <Typography component={'span'} className='text-secondary underline ml-2 cursor-pointer' onClick={()=>{setShowMore(false); setClickCode('')}}>{'Show Less'}</Typography>
                    ):instructions.length > 500 ? (
                        <Typography component={'span'} className='text-secondary underline ml-2 cursor-pointer' onClick={()=>{setShowMore(true); setClickCode(code)}}>{'Show More'}</Typography>
                    ):''
                    }
                </>

            ):(
                <>
                    <span dangerouslySetInnerHTML={{ __html: instructions.replace(/\n/g, '<br>').substring(0, 500) }} />
                    {instructions.length > 500 ? (
                        <Typography component={'span'} className='text-secondary underline ml-2 cursor-pointer' onClick={()=>{setShowMore(true); setClickCode(code)}}>{'Show More'}</Typography>
                    ):('')
                    }
                </>
            )
        );
    };

    const orgSpecific = () => {
        console.log('orgSpecific')
    }

   

    return(
        <ThemeProvider theme={ThemeCustom}>
            <div className='flex justify-between items-center document-review relative'>
                <div className='flex items-center'>
                    <Tabs value={value} onChange={handleChange} className='border-b-0' aria-label="document review tab">
                        <Tab label={
                            <Tooltip title={'Country Specific'} arrow placement="bottom">
                                <span className='truncate max-w-[7rem] xl:max-w-full'>Country specific</span>
                            </Tooltip>} onClick={specific} disableRipple  {...a11yProps(0)} className='normal-case'/>
                        <Tab onClick={orgSpecific} label={
                            <Tooltip title={'Organization Specific'} arrow placement="bottom">
                                <span className='truncate max-w-[7rem] xl:max-w-full'>Organization Specific</span>
                            </Tooltip>    
                            }  disableRipple {...a11yProps(2)}/>
                        <Tab onClick={custom} label="Global"  disableRipple {...a11yProps(1)}/>
                    </Tabs>
                </div>
                {value === 0 || value === 1 ? (
                    <div className='flex items-center justify-between absolute bottom-5 right-0'>
                    <FormControl variant="standard"  margin="normal" className='m-0 w-full xl:min-w-[25rem] '>
                    {props.loader ? (
                        <Skeleton animation="wave" className={'m-0 w-full h-[3.438rem] min-w-[16rem] xl:min-w-[25rem] rounded-xl'}></Skeleton>
                    ):(
                        <OutlinedInput className='search-input rounded-xl'
                            autoComplete="off" 
                            placeholder="Search" 
                            type='text'
                            value={searchTerm}
                            startAdornment={
                                <InputAdornment position="start">
                                    <img src={SearchIcon} alt='icon' className='w-5'/>
                                </InputAdornment>
                            }
                            onChange={(e) => {setSearchTerm(e.target.value)}}
                        />
                    )}
                    </FormControl>
                </div>
                ):''}
            </div>
            <div className={` relative border-solid border border-borderGrayDark rounded-tl-none rounded-xl`}>
                <SimpleBar className='min-h-instruct rounded-xl'>
                <TabPanel value={value} index={0} className='p-0 remove-pad'>
                {props.loader ? (  
                    <SklTable 
                        tableRow={9}
                        tableCol={2}
                        hasActions={2}
                    />
                ): notFound && searchItem.length === 0 && props.loader === false ? (  
                    <NotFound 
                        msgText={'No instructions found!'}
                        from={'instruct'}
                    />
                ):(
                    <TableContainer >
                        <Table stickyHeader aria-label="unknown map Datasets">  
                            <TableHead>
                                <TableRow>
                                    <TableCell>Country</TableCell>
                                    <TableCell>Rules</TableCell>
                                    <TableCell>Action</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                            { props.rules.length > 0 && searchItem.map((rule, index) => (
                                <TableRow key={index} >
                                    <TableCell className='max-w-[15vw] truncate cursor-pointer align-top'>
                                        {rule.name}
                                    </TableCell>
                                    <TableCell className='max-w-[32.5vw] min-w-[32.5vw] align-top'>
                                        <InstructionContent 
                                            instructions={rule.instructions}
                                            code={rule.code}
                                        />
                                    </TableCell>
                                            
                                    <TableCell className={`max-w-[10rem] align-top`}>
                                        <IconButton  onClick={()=>editInstruct(rule.code)} className='mr-4'>
                                            <img src={EditIcon} alt='icon' className='w-5'/>
                                            </IconButton>
                                        <IconButton  onClick={()=>deleteInstruct(rule.code)} className='mr-4'>
                                            <img src={DeleteIcon} alt='icon' className='w-5'/>
                                        </IconButton>
                                    </TableCell>
                                </TableRow>
                            ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                )}
                </TabPanel>
                <TabPanel value={value} index={1} className='p-0 remove-pad'>
                {props.loader ? (  
                    <SklTable 
                        tableRow={9}
                        tableCol={2}
                        hasActions={2}
                    />
                ): notFound && searchItem.length === 0 && props.loader === false ? (  
                    <NotFound 
                        msgText={'No instructions found!'}
                        from={'instruct'}
                    />
                ):(
                    <TableContainer >
                        <Table stickyHeader aria-label="unknown map Datasets">  
                            <TableHead>
                                <TableRow>
                                    <TableCell>Organization</TableCell>
                                    <TableCell>Rules</TableCell>
                                    <TableCell>Action</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                            { props.rules.length > 0 && searchItem.map((rule, index) => (
                                <TableRow key={index} >
                                    <TableCell className='max-w-[20vw] align-top'>
                                        {rule.name ? rule.name :(
                                            rule.organizations?.length > 0 && rule.organizations.map((orgItem, index)=>(
                                                <span className='' key={index}>
                                                    {orgItem.orgName} 
                                                    {(rule.organizations?.length -1) == index ? '' : `, `}
                                                </span>
                                                
                                            ))
                                        )}
                                        {/*truncate max-w-[15vw] mb-1*/}
                                    </TableCell>
                                    <TableCell className='max-w-[32.5vw] min-w-[32.5vw] align-top'>
                                        <InstructionContent 
                                            instructions={rule.instructions}
                                            code={rule.id}
                                        />
                                    </TableCell>
                                            
                                    <TableCell className={`max-w-[10rem] align-top`}>
                                        <IconButton  onClick={()=>editOrgInstruct(rule.id)} className='mr-4'>
                                            <img src={EditIcon} alt='icon' className='w-5'/>
                                            </IconButton>
                                        <IconButton  onClick={()=>deleteInstruct(rule.id)} className='mr-4'>
                                            <img src={DeleteIcon} alt='icon' className='w-5'/>
                                        </IconButton>
                                    </TableCell>
                                </TableRow>
                            ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                )}
                </TabPanel>
                <TabPanel value={value} index={2} className='remove-pad'>
                    {props.loader ? (
                        <SklInstructCus/>
                    ):props.customInstruct === '' && !props.loader ? (
                        <NotFound 
                            msgText={'No instructions found!'}
                            from={'instruct'}
                        />
                    ):(
                        <div className='flex justify-between w-full items-start'>
                            <div className='w-[calc(100%-10rem)] p-10 pr-4'>
                                <Typography component={'div'} variant='text-base'>
                                    {props.customInstruct}
                                </Typography>
                            </div>
                            <div className={`text-center max-w-[10rem] p-4`}>
                                <IconButton  onClick={editCustomInstruct} className='mr-4'>
                                    <img src={EditIcon} alt='icon' className='w-5'/>
                                    </IconButton>
                                <IconButton  onClick={delCustomInstruct} className='mr-4'>
                                    <img src={DeleteIcon} alt='icon' className='w-5'/>
                                </IconButton>
                            </div>
                        </div>
                    )}
                </TabPanel>
                </SimpleBar>
            </div>
        </ThemeProvider>
    );
}

export default InstructTable;