import React, {useState, useEffect, useMemo} from 'react';
import { useUserContext } from '../context/User_Access';
import SimpleBar from 'simplebar-react';
import { useNavigate, Outlet, Link } from 'react-router-dom';
import { useAuthContext } from '../context/Auth';

import {ThemeProvider, Container, Typography, Tabs, Tab} from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';


import { ThemeCustom} from "./../styles/Theme";
import 'simplebar-react/dist/simplebar.min.css';
import '../components/settings_comp/settings_styles.scss'

import SuccessAlert from '../components/alerts/Success_alert';
import ErrorAlert from '../components/alerts/Fail_alert';
import LogoutModal from '../components/modal/Modal_logout';
import WarningAlert from '../components/alerts/Warning_alert';

import KnownMappings from '../assets/Known_mappings.svg';
import KnownMappingsActive from '../assets/Known_mappings-active.svg';
import API from '../assets/APIs.svg';
import APIActive from '../assets/APIs-active.svg';
import Logout from '../assets/logout.svg';
import LogoutActive from '../assets/Logout-active.svg';
import UnKnownMappings from '../assets/Unknown-map.svg';
import UnKnownMappingsActive from '../assets/Unknown-map-active.svg';
import Global from '../assets/global.svg';
import GlobalActive from '../assets/global-active.svg';
import Instruct from '../assets/task-square.svg';
import InstructActive from '../assets/task-square-active.svg';
import report from '../assets/linear-chart.svg';
import reportActive from '../assets/linear-chart-active.svg';
import OrgSettings from '../assets/org_settings.svg'
import OrgSettingsActive from '../assets/org_settings-active.svg'
import ProfileUser from '../assets/profile_user.svg'
import ProfileUserActive from '../assets/profile_user-active.svg'
import Profile from '../assets/profile-circle.svg'
import ProfileActive from '../assets/profile-circle-active.svg'

function a11yProps(index) {
    return {
      id: `settings-tab-${index}`,
      'aria-controls': `settings-tabpanel-${index}`,
    };
}

const TabsArray = [
                    {img:OrgSettings, imgActive:OrgSettingsActive, text:'Organization settings', accessKey:'/organization', path:'./organization_settings' },
                    {img:report, imgActive:reportActive, text:'Usage Insights', accessKey:'/reports', path:'./usage_insights'},
                    {img:ProfileUser, imgActive:ProfileUserActive, text:'User Management', accessKey:'/users', path:'./user_management'},
                    {img:API, imgActive:APIActive, text:'Destination Format & APIs', accessKey:'/mappings', path:'./destination_format_and_apis' },
                    {img:KnownMappings, imgActive:KnownMappingsActive, text:'Known mappings', accessKey:'/known_mappings', path:'./known_mappings'},
                    {img:UnKnownMappings, imgActive:UnKnownMappingsActive, text:'Unknown mappings', accessKey:'unknown_map', path:'./unknown_mappings'},
                    {img:Global, imgActive:GlobalActive, text:'Regions & countries', accessKey:'/region', path:'./regions_and_countries'},
                    {img:Instruct, imgActive:InstructActive, text:'Instruction builds', accessKey:'/instructions', path:'./instruction_builds'},
                    {img:Profile, imgActive:ProfileActive, text:'User settings', accessKey:true, path:'./user_settings'},
                    // {img:Logout, imgActive:LogoutActive, text:'Logout'}
                  ]

const TabsArrayDev = [
                    {img:OrgSettings, imgActive:OrgSettingsActive, text:'Organization settings', accessKey:'/organization', path:'./organization_settings?devMode=true' },
                    {img:report, imgActive:reportActive, text:'Usage Insights', accessKey:'/reports', path:'./usage_insights?devMode=true'},
                    {img:ProfileUser, imgActive:reportActive, text:'User Management', accessKey:'/users', path:'./user_management?devMode=true'},
                    {img:API, imgActive:APIActive, text:'Destination Format & APIs',  accessKey:'/mappings', path:'./destination_format_and_apis?devMode=true'},
                    {img:KnownMappings, imgActive:KnownMappingsActive, text:'Known mappings', accessKey:'/known_mappings', path:'./known_mappings?devMode=true'},
                    {img:UnKnownMappings, imgActive:UnKnownMappingsActive, text:'Unknown mappings', accessKey:'unknown_map', path:'./unknown_mappings?devMode=true'},
                    {img:Global, imgActive:GlobalActive, text:'Regions & countries', accessKey:'/region', path:'./regions_and_countries?devMode=true'},
                    {img:Instruct, imgActive:InstructActive, text:'Instruction builds', accessKey:'/instructions', path:'./instruction_builds?devMode=true'},
                    {img:Profile, imgActive:ProfileActive, text:'User settings', accessKey:true, path:'./user_settings?devMode=true'},
                    //{img:Logout, imgActive:LogoutActive, text:'Logout'}
]


const Settings = (props) => {

    const [openSuccess, setOpenSuccess] = useState(false);
    const [openFail, setOpenFail] = useState(false);
    const [textSuccess, setTextSuccess] = useState('');
    const [textFail, setTextFail] = useState('');
    const [textWarning, setTextWarning] = useState('');
    const [openWarning, setOpenWarning]= useState(false);
    const [openLogout, setOpenLogout] = useState(false);
    const [tabPadding, setTabPadding] = useState(0)
    const [tabsArray, setTabsArray] = useState([])
    const navigate = useNavigate();
    const {devMode, accessControl, orgName} = useUserContext()
    const {unAuthAccess} = useAuthContext()


    useEffect(()=>{
        if(devMode){
            console.log('accessControlTab if xx', TabsArrayDev, accessControl)
            if(accessControl ){
                const accessControlTab = TabsArrayDev.filter(tab => (Object.keys(accessControl).includes(tab.accessKey)  && accessControl[tab.accessKey].write) || tab.accessKey === true);
                console.log('accessControlTab if',accessControlTab, TabsArrayDev, accessControl)
                setTabsArray(accessControlTab)
            }
          }
          else{
            console.log('accessControlTab else xx', TabsArrayDev, accessControl)
            if(accessControl){
                const accessControlTab = TabsArray.filter(tab => (Object.keys(accessControl).includes(tab.accessKey)  && accessControl[tab.accessKey].write) || tab.accessKey === true);
                console.log('accessControlTab',accessControlTab, TabsArrayDev, accessControl)
                setTabsArray(accessControlTab)
            }
          }
    },[props.tabValue, devMode, accessControl])

    //unAuthAccess

    useEffect(() => {
        console.log('navigate xxx');
        unAuthAccess()
    }, [navigate]);

    // const cancel = () =>{
    //     navigate('/document_review');
    // }

    //fail alert

    useEffect(()=>{
        setTimeout(()=>{
            setOpenFail(false);
        },5000)
    },[openFail]);

    //success alert

    useEffect(()=>{
        //console.log(openSuccess)
        setTimeout(()=>{
            setOpenSuccess(false);
        },10000)
    },[openSuccess]);

    //warning alert

    useEffect(()=>{
        //console.log(openSuccess)
        setTimeout(()=>{
            setOpenWarning(false);
        },10000)
    },[openWarning]);

    //tab change

    const settingsHandle = (event, newValue) => {
        props.setTabValue(newValue);
        setTabPadding(newValue)
        console.log(newValue)

        const route = tabsArray.at(newValue).path;
        navigate(route);

    };

    const logout = () => {
        setOpenLogout(true);
    }

    //setting item unhighlight in denied routes

    useEffect(()=>{

        const partToRemove = '/settings';
        const modifiedUrl = props.pathName.replace(new RegExp(partToRemove), '');

        props.setTabValue(null); 

        tabsArray.map((tab, index) => 
            {if(tab.path == `.${modifiedUrl}`) {
                return props.setTabValue(index); 
            }
                console.log('*inside***', index, tab.path, props.pathName)
        })

    },[tabsArray, props.pathName])


    return(
        <ThemeProvider theme={ThemeCustom}>
            <LogoutModal setOpenLogout={setOpenLogout} openLogout={openLogout} />
            <div className='absolute top-8 right-8 w-full z-[1999] h-0'>
                <SuccessAlert setOpenSuccess={setOpenSuccess} openSuccess={openSuccess} alretText={textSuccess}/>
                <ErrorAlert setOpenFail={setOpenFail} openFail={openFail} alretText={textFail}/>
                <WarningAlert setOpenWarning={setOpenWarning} openWarning={openWarning} alretText={textWarning}/>
            </div>
            <div className='mt-[4.5rem] container-up-margin'>
                <Container maxWidth="false">
                    <Grid container spacing={2} className='min-height-grid-settings'>
                        <Grid xs={12} md={2} lg={3} className="md:border-r hidden md:block border-0 md:border-solid md:border-r-borderGrayDark px-5 pt-10 pb-5">
                            <div className='mb-10'>
                                <Typography component={'div'} variant='text20' className='mb-2 font-semibold hidden lg:block'>{orgName}</Typography>
                                {/* <Typography component={'div'} variant='textBase' className='hidden lg:block'>pagero@docupath.app</Typography> */}
                            </div>
                            <SimpleBar className={`-left`}>
                                <Tabs
                                    orientation="vertical"
                                    value={props.tabValue}
                                    onChange={settingsHandle}
                                    aria-label="settings"
                                    className='settings-tab'
                                >
                                    {tabsArray.map((item, index) => (

                                        <Tab 
                                            key={index} 
                                            icon={<img src={props.tabValue === index ? item.imgActive : item.img} alt={item.text} className='mr-5 md:mr-0 lg:mr-5 m-0 max-w-[1.25rem] w-full'/>} 
                                            label={<span className='md:hidden lg:block capitalize'>{item.text}</span>} 
                                            className='border-0 mb-2 rounded-xl text-left justify-start p-5 items-start text-base max-w-full md:min-w-[auto] md:justify-center lg:justify-start' 
                                            iconPosition="start" 
                                            disableTouchRipple {...a11yProps(index)}
                                        />

                                    ))}
                                </Tabs>  
                                {/* <Button variant='whiteBtn' disableTouchRipple className='border-0 mb-2 rounded-xl text-left justify-start p-5 items-start text-base capitalize hover:bg-white w-full active:bg-culturedGreen active:text-secondary' onClick={logout}>
                                    <img src={Logout} alt={'Logout'} className='mr-5 m-0 max-w-[1.25rem] w-full'/> Logout
                                </Button> */}
                            </SimpleBar>
                        </Grid>
                        <Grid xs={12} md={10} lg={9} className={`pl-5 pt-10 pb-5 ${tabPadding === 1 ? 'pr-5':'pr-5'}`}>
                            {/* <KnowMap
                                setOpenFail={setOpenFail} 
                                setTextFail={setTextFail} 
                                setOpenSuccess={setOpenSuccess} 
                                setTextSuccess={setTextSuccess}
                            />*/}
                            <Outlet context={{ setOpenFail, setTextFail, setOpenSuccess, setTextSuccess, setTextWarning, setOpenWarning, devMode:devMode }} />
                        </Grid>
                    </Grid>
                </Container>
            </div> 
        </ThemeProvider>
    );
}

export default Settings;