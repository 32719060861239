import React, {useEffect, useState} from 'react';
import { useForm } from "react-hook-form";
import { useNavigate } from 'react-router-dom';
import axios from "axios";
import Cookies from 'js-cookie';

import {Box, Typography, Modal} from '@mui/material';

import ModalStyle from './Modal_styles.module.scss';

import PreProcessIcon from '../../assets/pre-process.gif'

let arry = ['Extracting essential information from unstructured text', 'Identifying key data points', 'Preparing structured output', 'Reviewing content layers', 'Structuring data for clarity and precision', 'Running AI-powered analysis', 'Delivering organized insights from your document']


const PreProcessModal = ({openPreProcess, setOpenPreProcess}) =>{

    const [textDynamic, setTextDynamic] = useState('Extracting essential information from unstructured text')
        
    //loop for text change

    useEffect(()=>{

        for(let i=0; i<arry.length; i++){

            if(textDynamic == arry[i]){
                if(i==arry.length-1){
                    setTimeout(()=>{
                        setTextDynamic(arry[0])
                    },1250)
                }else{
                    setTimeout(()=>{
                        setTextDynamic(arry[i+1])
                    },1250)
                }
                break;
            }
            
        }

    },[textDynamic])

    return(
        <div>
            <Modal
                open={openPreProcess}
                aria-labelledby="modal-modal-title"
            >
                <Box className={`${ModalStyle['modal-base']} ${ModalStyle['modal-loader']}`}> 
                    <div className={`${ModalStyle['modal-container']}  ${ModalStyle['modal-loader']}`}>
                        <div className={`${ModalStyle['content-wrapper']} ${ModalStyle['modal-loader']}`}>
                            <div className={ModalStyle['loader-content']}>
                                <div className={ModalStyle['loader-bg']}>
                                    <img src={PreProcessIcon} alt='loader icon' className={ModalStyle['loader-icon-scale']}/>
                                </div>
                                <Typography id="loader-modal-title" className={`${ModalStyle['modal-Header2']} modal-pl-0 modal-py-0`} variant="subHeader1" component="h2">
                                    {textDynamic}
                                </Typography>
                            </div>
                        </div>
                    </div>    
                </Box>
            </Modal>
        </div> 
    );
}

export default PreProcessModal;