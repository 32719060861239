import React, {useEffect, useState} from 'react';

import {Box, Typography, Modal, Button} from '@mui/material';

import ButtonSecondary from '../button/Btn_secondary';

import ModalStyle from './Modal_styles.module.scss';

import sandClockIcon from '../../assets/sand-clock.gif'

const FeatureSoonModal = ({OpenCommingSoon, setOpenCommingSoon}) =>{
    

    return(
        <div>
            <Modal
                open={OpenCommingSoon}
                aria-labelledby="modal-modal-title"
            >
                <Box className={`${ModalStyle['modal-base']} ${ModalStyle['modal-feature-soon']}`}> 
                    <div className={ModalStyle['modal-container']}>
                        <div className={ModalStyle['content-wrapper']}>
                            <div className={ModalStyle['icon-wrapper']}>
                                <img src={sandClockIcon} alt='sand clock icon' className={`${ModalStyle["icon-img"]} ${ModalStyle["icon-img-max-w-7"]}`}/>
                            </div>
                                <Typography id="modal-modal-title" className={ModalStyle['modal-Header1']} align={'center'} variant="text24" component="h2">
                                    This feature is dropping soon! Stay tuned.
                                </Typography>
                        </div>
                        <div className={ModalStyle['footer-wrapper-center']}>
                            <ButtonSecondary btnName="Okay" variant={'secondaryBtn'} type="submit" onClick={()=>setOpenCommingSoon(false)} disabled={false}/>
                        </div>
                    </div>    
                </Box>
            </Modal>
        </div> 
    );
}

export default FeatureSoonModal;