import React, {useState, useEffect, useRef} from 'react';
import {ThemeProvider, Skeleton } from '@mui/material';

import { ThemeCustom } from "../../styles/Theme";


const SklInput = () => {


    return(
        <ThemeProvider theme={ThemeCustom}>
            <Skeleton animation="wave" className={` w-full max-h-[3.449rem] h-[3.449rem] rounded-xl`}></Skeleton>
        </ThemeProvider>
    )
}

export default SklInput;