import React, {useState, useEffect, useRef} from 'react';
import SimpleBar from 'simplebar-react';
import axios from "axios";
import { useBaseUrlContext } from '../../context/Base_url';

import {ThemeProvider, Table, TableHead, TableBody, TableCell, TableContainer, TableRow, IconButton} from '@mui/material';

import { ThemeCustom } from "../../styles/Theme";

import NotFound from '../masseges/Not_found';
import SklTable from '../skeletons/Skl_table';

import EditIcon from '../../assets/edit-new.svg'
import DeleteIcon from '../../assets/delete.svg'
import KeyIcon from '../../assets/key.svg'

import eye from '../../assets/eye.svg'; 
import eyeSlash from '../../assets/eye-slash.svg'; 

//const BaseUrl = 'https://docupath.app';

const ApisTable = (props) => {

    const [notFound, setNotFound] = useState(false);
    const {BaseUrl} = useBaseUrlContext()
    const [showClientId, setShowClientId] = useState([]);

    useEffect(()=>{
        console.log('not found',props.apiArray);
        if(props.apiArray.length === 0){
            setNotFound(true)
            console.log('not found if',props.apiArray);
        }
        else{
            setNotFound(false)
            console.log('not found else',props.apiArray);
        }
    },[props.apiArray])

    //editRegion

    const editRegion = (id) => {
        console.log('editRegion id - ', id)
        props.setEditId(id)
        props.setOpenAddRegion(true)
    }

    //delete Api

    const deleteApi = (id) => {
        console.log('deleteRegion id - ', id)
        props.setOpenDelete(true)
        props.setEditId(id)
    }

    //client id set

    const showClientIdFun = (id) => {
        

        if(showClientId.includes(id)){
            setShowClientId([])
        }
        else{
            setShowClientId(id)
            setShowClientId([id])
        }   

    }

    useEffect(()=>{
        console.log('showClientId id - ', showClientId)
        
    },[showClientId])

    // Function to convert a timestamp to a human-readable date *****

    const timestampToHumanDate = (timestamp) => {
        const date = new Date(timestamp * 1000); // Convert to milliseconds
        const options = { year: 'numeric', month: 'short', day: 'numeric', hour: 'numeric', minute: 'numeric' };
        return date.toLocaleDateString('en-US', options);
    };


    return(
        <ThemeProvider theme={ThemeCustom}>
            <div className={` relative border-solid border border-borderGrayDark rounded-xl`}>
                {props.loader ? (  
                    <SklTable 
                        tableRow={5}
                        tableCol={3}
                        hasActions={2}
                    />
                ): notFound  ? (  
                    <NotFound msgText={'No items found!'}/>
                ):(
                    <TableContainer >
                        <Table>  
                            <TableHead>
                                <TableRow>
                                    <TableCell>Key Name</TableCell>
                                    <TableCell>Client ID</TableCell>
                                    <TableCell>Created Date</TableCell>
                                    <TableCell>Actions</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                            { props.apiArray.length > 0 && props.apiArray.map((apiItem, index) => (
                                <TableRow key={apiItem.id} >
                                    <TableCell className='max-w-[20vw] truncate'>
                                        <div className='flex items-center'>
                                            <div className='w-[3rem] h-[3rem] mr-5 rounded-full bg-secondary p-3'>
                                                <img src={KeyIcon} alt='api key' className='w-full max-w-[2.5rem]'/>
                                            </div>
                                            {apiItem.name}
                                        </div>
                                    </TableCell>
                                    <TableCell className='w-[25rem]'>
                                        <div className='flex items-center'>
                                            {showClientId.includes(apiItem.id) ? apiItem.clientId : '***************'}
                                            <IconButton
                                                aria-label="toggle password visibility"
                                                onClick={() => showClientIdFun(apiItem.id)}
                                                edge="end"
                                                className='ml-3'
                                            >
                                                {showClientId.includes(apiItem.id) ? <img src={eyeSlash} alt='VisibilityOff' className='w-6'/> : <img src={eye} alt='Visibility' className='w-6'/>}
                                            </IconButton>
                                        </div>
                                    </TableCell>
                                    <TableCell className=''>
                                    {timestampToHumanDate(apiItem.timestamp)}
                                    </TableCell>
                                    <TableCell className={`max-w-[7rem]`}>
                                        {/* 
                                            <IconButton  onClick={()=>{}} className='mr-4'>
                                                <img src={EditIcon} alt='icon' className='w-5'/>
                                            </IconButton> 
                                        */}
                                        <IconButton  onClick={()=>deleteApi(apiItem.clientId)} className='mr-4'>
                                            <img src={DeleteIcon} alt='icon' className='w-5'/>
                                        </IconButton>
                                    </TableCell>
                                </TableRow>
                            ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                )}
            </div>
        </ThemeProvider>
    );
}

export default ApisTable;