import React, {useEffect, useState} from 'react';

import {Box, Typography, Modal} from '@mui/material';

import ButtonSecondary from '../button/Btn_secondary';

import ModalStyle from './Modal_styles.module.scss';


const DeleteWarnModal = ({openDeleteWarning, setOpenDeleteWarning}) =>{

    const gotIt = () =>{
        setOpenDeleteWarning(false)
    }

    return(
        <div>
            <Modal
                open={openDeleteWarning}
                aria-labelledby="modal-modal-title"
            >
                <Box className={`${ModalStyle['modal-base']} ${ModalStyle['modal-verfy-output']}`}> 
                    <div className={ModalStyle['modal-container']}>
                        <div className={ModalStyle['content-wrapper']}>
                            <Typography id="modal-modal-title" className={ModalStyle['modal-Header1']} align={'center'} variant="text24" component="h2">
                                Delete files
                            </Typography>
                            <Typography id="modal-modal-description" className={ModalStyle['modal-text-base']} align={'center'} variant="textBase" component="p">
                                You can delete up to 100 files at once. Please adjust your selection and try again.
                            </Typography>
                        </div>
                        <div className={ModalStyle['footer-wrapper-center']}>
                            <ButtonSecondary btnName="Okay, got it!" variant={'secondaryBtn'} type="submit" onClick={gotIt} disabled={false}/>
                        </div>
                    </div>    
                </Box>
            </Modal>
        </div> 
    );
}

export default DeleteWarnModal;