import React, {useEffect, useState} from 'react';
import { useBaseUrlContext } from '../../context/Base_url';
import { useNavigate} from 'react-router-dom';
import { useAuthContext } from '../../context/Auth';
import axios from "axios";
import Cookies from 'js-cookie';
import useAxiosInstance from '../../api/interceptor';
import { useAuth0 } from "@auth0/auth0-react";

import {Box, Typography, Modal} from '@mui/material';

import ButtonSecondary from '../button/Btn_secondary';

import ModalStyle from './Modal_styles.module.scss';

import logoutIcon from '../../assets/logout.gif';

const LogoutModal = ({openLogout, setOpenLogout, modalText, auth0Logout}) =>{

    const navigate = useNavigate();
    const {BaseUrl} = useBaseUrlContext()
    const {tokenReview, popupAction} = useAuthContext()
    const [cookieOptions, setCookieOptions] = useState({})
    const axiosInstance = useAxiosInstance();
    const { logout } = useAuth0();

    useEffect(()=>{

        const domain = window.location.hostname
        const baseDomain = domain.split('.').slice(-2).join('.');
        const cookieDomain = domain === 'localhost' ? 'localhost' : `.${baseDomain}`;
        const isSecure = domain === 'localhost' ? false : domain.includes('invoiceai.local') ? false : true;

        setCookieOptions(
            {
                path: '/',
                secure: isSecure, 
                sameSite: 'lax', // Use 'lax' to balance security and accessibility
                //domain: cookieDomain,
                
            }
        )
        
    },[])

    useEffect(()=>{
        console.log('cookieOptions >',cookieOptions)
    },[cookieOptions])


    const logoutFun = async () =>{

        console.log('logout');

        // logout({ 
        //     logoutParams: { returnTo: `${window.location.origin}/` } 
        // })

        try {

            setOpenLogout(false)

            const res = await axiosInstance.get(`/logout.php`)
                
            let data = res.data.result;
            let error = res.data.error;

            console.log('data sign out', data)
            logout({ 
                logoutParams: { returnTo: `${window.location.origin}/` } 
            })
            //navigate('/signin');
            Cookies.remove('token', cookieOptions);
            Cookies.remove('expire', cookieOptions);
            Cookies.remove('refreshToken', cookieOptions);
            Cookies.remove('userEmail', cookieOptions);
            Cookies.remove('userName', cookieOptions);
            Cookies.remove('userId', cookieOptions);
            Cookies.remove('access', cookieOptions);

        } 
        catch (err) {
            console.error('fetchData:', err);
            if(auth0Logout){
                logout({ 
                    logoutParams: { returnTo: `${window.location.origin}/` } 
                })
            }
        }  

    }

    return(
        <div>
            <Modal
                open={openLogout}
                aria-labelledby="modal-modal-title"
            >
                <Box className={`${ModalStyle['modal-base']} ${ModalStyle['modal-logout']}`}> 
                    <div className={ModalStyle['modal-container']}>
                        <div className={ModalStyle['content-wrapper']}>
                            <div className={ModalStyle['icon-wrapper']}>
                                <img src={logoutIcon} alt='logout icon' className={`${ModalStyle["icon-img"]} ${ModalStyle["icon-img-flip"]}`}/>
                            </div>
                                <Typography id="modal-modal-title" className={ModalStyle['modal-Header1']} align={'center'} variant="text24" component="h2">
                                    Are you sure you want to Sign out?
                                </Typography>
                        </div>
                        <div className={ModalStyle['footer-wrapper-center']}>
                            <ButtonSecondary btnName="Not Now" type="button" variant={'platinumBtn'} classes="mr-5" onClick={() => setOpenLogout(false)} disabled={false}/>
                            <ButtonSecondary btnName="Sign out" variant={'redBtn'} type="submit" onClick={logoutFun} disabled={false}/>
                        </div>
                    </div>    
                </Box>
            </Modal>
        </div> 
    );
}

export default LogoutModal;