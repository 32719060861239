import React, {useState, useEffect} from 'react';
import axios from "axios";
import Cookies from 'js-cookie';
import SimpleBar from 'simplebar-react';
import { useOutletContext } from 'react-router-dom';

import {ThemeProvider, Typography, Avatar, IconButton, Accordion, AccordionSummary, AccordionDetails} from '@mui/material';

import { ThemeCustom} from "../../styles/Theme";
import 'simplebar-react/dist/simplebar.min.css';
import './settings_styles.scss';

import SklOrgMain from '../skeletons/Skl_org_main';
import SklOrgList from '../skeletons/Skl_org_list';

import EditIcon from '../../assets/edit-new.svg'
import DeleteIcon from '../../assets/delete.svg'
import AddIcon from '../../assets/add-square.svg'
import ArrowDownIcon from '../../assets/arrow-head-down.svg'
import CopyIcon from '../../assets/copy-green.svg'

const orgList = [{}]

const OrganizationList = (props) => {

    const [mainOrg, setMainOrg] = useState({})
    const [subOrg, setSubOrg] = useState([])
    const [expanded, setExpanded] = useState(0);
    const [hoverOnId, setHoverOnId] = useState(0)

    //accordion expand

    const handleChange = (panel) => (event, newExpanded) => {
      setExpanded(newExpanded ? panel : false);
    };

    //separate the main and sub orgs

    useEffect(()=>{

        // if(props.organization.length > 0){
        //     const mainOrg = props.organization.filter(item => item.isSubOrganization === 0)
        //     setMainOrg(mainOrg.at(0))
        //     props.setInboxDomain(mainOrg.at(0).attachmentEmail)

        //     const subOrg = props.organization.filter(item => item.isSubOrganization === 1)
        //     setSubOrg(subOrg)
        // }

    },[props.organization])                                    

    const editOrg = (id, parentOrg) => {
        console.log('editOrg', id)
        props.setOpenOrgSetting(true)
        props.setEditId(id)
        props.setIsSubOrg(1)
        props.editOrgData(id)
        props.setParentOrgId(parentOrg)
    }    
    
    const deleteOrg = (id, isMain) => {
        console.log('deleteOrg', id)
        props.setEditId(id)
        props.setOpenDelete(true)
        isMain ? props.setDelText('organization') : props.setDelText('sub organization')

    }  
    
    const editMainOrg = (id) => {
        console.log('editMainOrg', id)
        props.setOpenOrgMainSetting(true)
        props.setEditId(id)
        props.editOrgData(id)
    } 

    const createSubOrg = (id) => {
        props.setOpenOrgSetting(true); 
        props.setEditId(0); 
        props.setIsSubOrg(1)
        props.setParentOrgId(id)
    }

    const copyId = (id) => {
        navigator.clipboard.writeText(id).then(() => {
            props.setOpenSuccess(true)
            props.setTextSuccess('Text copied to clipboard!');
          }).catch((err) => {
            props.setOpenFail(true)
            props.setTextFail('Failed to copy text')
            console.error('Failed to copy text: ', err);
          });
    }

    return(
        <ThemeProvider theme={ThemeCustom}>
            <div>
                {props.isLoader && props.organization.length === 0 ? (
                    [1,2,3,4,5,6].map((orgSkel, index) => (
                        <SklOrgMain key={index}/>
                    ))
                    
                ):(
                    props.organization.length > 0 && props.organization.map((org, index) => (
                        <Accordion key={org.id} expanded={expanded === org.id} className='org-accordion mb-4 shadow-none' onChange={org.subOrganisations.length > 0 ? handleChange(org.id) : null}>
                            <AccordionSummary 
                                aria-controls="panel1d-content" id="panel1d-header" 
                                className='m-0 border border-borderGrayDark border-solid px-6 rounded-t-[1.25rem]'
                                expandIcon={<img src={ArrowDownIcon} alt='arrow down' className={`${org.subOrganisations.length > 0 ? '' : 'opacity-20'} w-5`} />}
                            >
                                
                                    <div className='flex items-center w-full'>
                                        <Avatar className='w-[4rem] h-[4rem] bg-primary capitalize' alt={org.orgName} src="/static/images/avatar/1.jpg" />
                                        <div className='flex justify-between items-center py-5 w-full ml-5'>
                                            <div >
                                                <div className='flex items-center'>
                                                    <Typography className='mb-1 font-semibold' variant='text20' component={'div'}>{org.orgName}</Typography>
                                                    { org.isMainOrganization === 1 ? (
                                                        <div className='uppercase text-xs font-semibold text-secondary bg-[#DAF1E3] rounded-md py-1 px-2 ml-2'>Main</div>
                                                    ):''
                                                    }
                                                </div>
                                                <Typography variant='text18' className={`${!org.attachmentEmail ? 'min-h-[1.25rem]' : ''} text-sonicSilver`} component={'div'}>{org.attachmentEmail ? org.attachmentEmail : ''}</Typography>
                                            </div>
                                            <div className='border-r border-0 border-solid border-borderGrayDark mr-6'>
                                                <IconButton  onClick={(e)=> {createSubOrg(org.id); e.stopPropagation()}} className='mr-4'>
                                                    <img src={AddIcon} alt='icon' className='w-5'/>
                                                </IconButton>
                                                <IconButton  onClick={(e)=> {editMainOrg(org.id); e.stopPropagation()}} className='mr-4'>
                                                    <img src={EditIcon} alt='icon' className='w-5'/>
                                                </IconButton>
                                                <IconButton  onClick={org.isMainOrganization === 1 ? null : (e)=>{deleteOrg(org.id, 1); e.stopPropagation()}}  className={`${org.isMainOrganization === 0 ? '' : 'opacity-20'} mr-6`}>
                                                    <img src={DeleteIcon} alt='icon' className='w-5'/>
                                                </IconButton>
                                            </div>
                                        </div>
                                    </div>
                            </AccordionSummary>
                            <AccordionDetails 
                                className='p-0 border-b border-0 border-borderGrayDark border-solid rounded-b-[1.25rem]'
                            >
                                {org.subOrganisations.length > 0 ? (
                                    org.subOrganisations.map((item, i)=>(
                                        <div key={item.id} className='px-6 flex justify-between items-center py-4 sub-org-list w-full'>
                                            <div className='flex items-center'>
                                                <Avatar className='w-[3rem] mx-2 text-base font-semibold h-[3rem] text-primary bg-cultured' alt={item.orgName} src="/static/images/avatar/1.jpg" />
                                                <div>   
                                                    <Typography className='ml-6' variant='text18' component={'div'}>{item.orgName}</Typography>
                                                    <div 
                                                        onMouseEnter={()=> setHoverOnId(item.id)}
                                                        onMouseOver={()=> setHoverOnId(item.id)}
                                                        onMouseLeave={()=> setHoverOnId(0)}
                                                        onClick={()=>{copyId(item.id)}}
                                                        className='min-h-[1.275rem] mt-1 flex items-center'
                                                    >
                                                        <Typography className='ml-6 text-sonicSilver hover:text-secondary cursor-pointer flex items-center' variant='textBase' component={'div'}>{item.id} { hoverOnId === item.id ? <img src={CopyIcon} alt='copy icon' className='w-5 h-5 ml-3'/> : ''}</Typography>

                                                    </div>
                                                </div>
                                            </div>
                                            <div>
                                                <IconButton  onClick={()=> editOrg(item.id, org.id)} className='mr-4'>
                                                    <img src={EditIcon} alt='icon' className='w-5'/>
                                                </IconButton>
                                                <IconButton  onClick={()=> deleteOrg(item.id, 0)} className=''>
                                                    <img src={DeleteIcon} alt='icon' className='w-5'/>
                                                </IconButton>
                                            </div>
                                        </div>
                                    ))
                                ):(
                                    ''
                                )}
                            </AccordionDetails>
                        </Accordion>
                    ))
                )}
               
            </div>
        </ThemeProvider>

    )
}

export default OrganizationList;