import React, {useEffect, useState} from 'react';
import { useForm } from "react-hook-form";
import { useBaseUrlContext } from '../../context/Base_url';
import useAxiosInstance from '../../api/interceptor';

import {ThemeProvider, Box, Typography, Modal, FormControl, OutlinedInput, InputLabel} from '@mui/material';
import { ThemeCustom} from "../../styles/Theme";

import ButtonSecondary from '../button/Btn_secondary';

import ModalStyle from './Modal_styles.module.scss';
import './Modal_styles.scss';

const SecretKeyModal = ({openSecretKey, setOpenSecretKey, setOpenApiCredent, setOpenFail, setTextFail, setOpenSuccess, setTextSuccess, setKeyName, setClientSecret, setClientId  }) =>{

    const { register, handleSubmit, formState: { errors }, reset } = useForm();
    const [uploading, setUploading] = useState(false)
    const {BaseUrl} = useBaseUrlContext()
    const axiosInstance = useAxiosInstance();


    useEffect(()=>{
        setUploading(false)
    },[])
    
    const cancel = () =>{
        setOpenSecretKey(false)
        reset({client_name:''})
    }

    // const submit = () =>{
    //     setOpenApiCredent(true)
    //     setOpenSecretKey(false)
    //     reset({name:''})
    // }


    const onSubmit = async (data) => {

        console.log('data', data)

        try {

            const res = await axiosInstance.post(`/apiCredentials.php`, data )

            let result = res.data.result;
            let error = res.data.error;
                
            console.log(res.data.result)
            setKeyName(result.name)
            setClientSecret(result.clientSecret) 
            setClientId(result.clientId)
            setOpenApiCredent(true)
            setOpenSuccess(true)
            setTextSuccess(result.message)
            setOpenSecretKey(false)
            reset({client_name:''})
            
        } catch (err) {
            console.error('fetchData:', err);
            setUploading(false)
            if (!err?.response?.alertShown) {
                setOpenFail(true)
                err?.response?.data?.error.text ? setTextFail(err.response.data.error.text): setTextFail('The server encounted an error and could not complete your request.')
            }
        }   
    }

    return(
        <ThemeProvider theme={ThemeCustom}>
        <div>
            <Modal
                open={openSecretKey}
                aria-labelledby="modal-modal-title"
            >
                <Box className={`${ModalStyle['modal-base']} ${ModalStyle['modal-secret-key']}`}> 
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <div className={ModalStyle['modal-container']}>
                            <div className={ModalStyle['content-wrapper']}>
                                <Typography id="modal-modal-title" align='center' className={ModalStyle['modal-Header1']} variant="text24" component="h2">
                                    Generate API Credentials
                                </Typography>
                                    <FormControl variant="standard" fullWidth  margin="normal" className='modal-mt-12 form-control modal-mb-5' >
                                        <InputLabel shrink htmlFor='name' title='text' className='modal-label'>
                                            Key name
                                        </InputLabel>
                                        <OutlinedInput 
                                            id='name'
                                            placeholder='My test key'
                                            autoComplete="off" 
                                            type='text'
                                            className='modal-input'
                                            {...register('client_name',{ required: true })} 
                                        />
                                        {errors.client_name && <p className='err-msg'>Key name is required</p>}
                                    </FormControl>
                            </div>
                            <div className={ModalStyle['footer-wrapper-center']}>
                                <ButtonSecondary btnName="Cancel" variant={'primaryBtn'} classes='modal-button-12' type="button" onClick={cancel} disabled={uploading}/>
                                <ButtonSecondary btnName="Create" variant={'secondaryBtn'} classes='modal-button-12' type="submit" onClick={()=>{}} disabled={uploading}/>
                            </div>
                        </div>    
                    </form>
                </Box>
            </Modal>
        </div> 
        </ThemeProvider>
    );
}

export default SecretKeyModal;