import React, { useEffect, useRef, useState } from 'react';
import {Skeleton} from '@mui/material';

const PDFViewer = (props) => {
  const adobeDCViewRef = useRef(null);
  const [apis, setApis] = useState(null); // State to store the APIs object
  const [retryCount, setRetryCount] = useState(0);

  useEffect(()=>{
    console.log('props.pdfUrl',props.pdfUrl)
  },[props.pdfUrl])

  useEffect(() => {

    let apiKey;
    const domain = window.location.hostname;

    if (domain === 'localhost') {
      apiKey = '2fb71fbc24bd4be287fec52860303347';
    } 
    else if(domain.includes('docupathdev')){
      apiKey = '92f5416804124efca4702c5e6aeb25e7' //'8ae25ebc621c4a4c9f9c1bb2211b1354'
    }
    // else if(domain.includes('demo.docupath')){
    //   apiKey = '5978eb47a2f54df1a92b3f8b6427f0a9'
    // }
    else {
      //apiKey = '4d466b855b5441ebaa59a652f9bddb58';
      apiKey = 'faff2dbf94cf41d9a29069856c62dac2';
    }

    const initViewer = () => {

      props.setSklPdfView(false)

      if (window.AdobeDC) {
        try {
          adobeDCViewRef.current = new window.AdobeDC.View({
            clientId: apiKey,
            divId: 'adobe-dc-view',
          });

          adobeDCViewRef.current.previewFile(
            {
              content: { location: { url: props.pdfUrl } },
              metaData: { fileName: props.invoiceName ? props.invoiceName : '' }, //props.invoiceName, props.pdfUrl
            },
            {
              embedMode: 'FULL_WINDOW',
              defaultViewMode: 'FIT_WIDTH',
              showAnnotationTools: true,
              showDownloadPDF: false,
              enableSearchAPIs: true,
              showPrintPDF: false,
              showZoomControl: true,
              showThumbnails: false,
              showFullScreen: false,
            }
          )
          .then(adobeViewer => {

            adobeViewer.getAPIs().then(api => {
              setApis(api);
            });

            console.log('PDF is loaded!');
            props.setSklPdfView(false)


          })
          .catch(error => {

            console.error("Error in previewFile:", error);
            //props.setSklPdfView(false)


            if (retryCount < 3) {
              setRetryCount(retryCount + 1);
              initViewer();
            }

          });

        } 
        catch (error) {
          console.error("Error initializing Adobe viewer:", error);
        }
      }
    };

    if (window.AdobeDC) {
      initViewer();
    } else {
      window.addEventListener('adobe_dc_view_sdk.ready', initViewer);
    }

    return () => {
      window.removeEventListener('adobe_dc_view_sdk.ready', initViewer);
    };




  }, [props.pdfUrl, retryCount]);

  useEffect(() => {
    console.log('Search text:', props.searchText);

    if (apis) {
      if (props.searchText === '') {
        apis.search('Null').then(result => {
          result.clear();
          console.log("Search result if:", result);
        }).catch(error => {
          console.log("Search error if:", error);
        });
      } else {
        apis.search(props.searchText).then(result => {
          console.log("Search result:", result);
        }).catch(error => {
          console.log("Search error:", error);
        });
      }
    }
  }, [props.searchText]);



  return (
    <>
      <div id="adobe-dc-view" className={`${props.sklPdfView?'hidden':''}`}></div>
      <Skeleton animation="wave" className={` w-full min-height-grid-pdf-viewer rounded-xl ${props.sklPdfView?'':'hidden'}`}></Skeleton>
    </>
  );
};

export default PDFViewer;
