import React, {useState, useEffect} from 'react';
import { Link } from 'react-router-dom';

import {Container, Typography} from '@mui/material';
import {ThemeProvider} from '@mui/material';
import { ThemeCustom} from "../styles/Theme";

import InvoiceLogo from '../assets/Docupath-Logo.svg';
import loaderIcon from '../assets/Loading1.svg'

const Signin = (props) =>{


    useEffect(()=>{

        const baseUri = window.location.origin;
        const hostname = window.location.hostname;

        if(hostname.includes('localhost')){
            window.location.href =  `https://company.docupathdev.app/redirect_to_login.php?redirectDomain=${baseUri}`;
        }
        else{
            window.location.href =  `${baseUri}/redirect_to_login.php?redirectDomain=${baseUri}`;
        }

    },[])

    return(
        <ThemeProvider theme={ThemeCustom}>            
            <Container maxWidth="xl">
                <div className={`${props.class? props.class:'h-screen'} flex justify-center items-center flex-col`}>
                    <div className='flex flex-col items-center justify-center'>

                        <img className="max-w-[2.5rem] min-h-[2.5rem]" src={loaderIcon} alt="loading logo"/>
                        <Typography variant="text24" className="!font-medium mt-7" component='div'>
                            Please wait...
                        </Typography>
                        
                    </div>
                </div>
            </Container> 
        </ThemeProvider> 
    );
}

export default Signin;