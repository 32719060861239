import React, {useEffect, useState} from 'react';

import {Box, Typography, Modal} from '@mui/material';

import ButtonSecondary from '../button/Btn_secondary';

import ModalStyle from './Modal_styles.module.scss';


const ApproveSaveModal = ({openApproveSave, setOpenApproveSave, postApprove}) =>{

    const save = () =>{
        console.log('save')
        setOpenApproveSave(false)
        postApprove(1)
    }

    const notSave = () =>{
        console.log('notSave')
        setOpenApproveSave(false)
        postApprove(0)
    }

    const cancel = () =>{
        console.log('cancel')
        setOpenApproveSave(false)
    }

    return(
        <div>
            <Modal
                open={openApproveSave}
                aria-labelledby="modal-modal-title"
            >
                <Box className={`${ModalStyle['modal-base']} ${ModalStyle['modal-verfy-output']}`}> 
                    <div className={ModalStyle['modal-container']}>
                        <div className={ModalStyle['content-wrapper']}>
                            <Typography id="modal-modal-title" className={ModalStyle['modal-Header1']} align={'center'} variant="text24" component="h2">
                                Heads up!
                            </Typography>
                            <Typography id="modal-modal-description" className={ModalStyle['modal-text-base']} align={'center'} variant="textBase" component="p">
                                You've made some changes. Would you like to save them.
                            </Typography>
                        </div>
                        <div className={'modal-btn-wrap-vertical'}>
                            <ButtonSecondary btnName="Yes, save and approve" classes={'modal-mb-2 w-modal-full'} variant={'secondaryBtn'} type="submit" onClick={save} disabled={false}/>
                            <ButtonSecondary btnName="No, but approve" classes={'modal-mb-2 w-modal-full modal-btn-p'} variant={'primaryBtn'} type="submit" onClick={notSave} disabled={false}/>
                            <ButtonSecondary btnName="Cancel" variant={'primaryOutline'} classes={'w-modal-full modal-btn-outline-p'} type="button" onClick={cancel} disabled={false}/>
                        </div>
                    </div>    
                </Box>
            </Modal>
        </div> 
    );
}

export default ApproveSaveModal;