import React, {useState, useEffect, useRef} from 'react';
import {ThemeProvider, Avatar, Skeleton } from '@mui/material';

import { ThemeCustom } from "../../styles/Theme";


const SklHeaderText24 = () => {
    return(
        <ThemeProvider theme={ThemeCustom}>
            <Skeleton animation="wave" className='w-full max-w-[25.5rem] max-h-[1.8rem] h-[1.8rem] rounded my-8 mr-4' ></Skeleton>
        </ThemeProvider>
        
    )
}

export default SklHeaderText24;