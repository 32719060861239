import React, {useState, useEffect, useRef} from 'react';
import SimpleBar from 'simplebar-react';
import Cookies from 'js-cookie';

import {ThemeProvider, Table, TableHead, TableBody, TableCell, TableContainer, TableRow, Tooltip, IconButton} from '@mui/material';

import { ThemeCustom } from "../../styles/Theme";

import NotFound from '../masseges/Not_found';
import SklTable from '../skeletons/Skl_table';

import EditIcon from '../../assets/edit-new.svg'
import DeleteIcon from '../../assets/delete.svg'

const UsersTable = (props) => {

    const [notFound, setNotFound] = useState(false);
    const [userId, setUserId] = useState(Cookies.get('userId') ? Cookies.get('userId') : '')

    useEffect(()=>{
        console.log('not found',props.users);
        if(props.users.length === 0){
            setNotFound(true)
            console.log('not found if',props.users);
        }
        else{
            setNotFound(false)
            console.log('not found else',props.users);
        }
    },[props.users])

    //editRegion

    const editUser = (id) => {
        console.log('editRegion id - ', id)
        props.setEditId(id)
        props.setOpenAddUser(true)
    }

    //deleteRegion

    const deleteUser = (id) => {
        console.log('deleteRegion id - ', id)
        props.setOpenDelete(true)
        props.setEditId(id)
    }


    return(
        <ThemeProvider theme={ThemeCustom}>
            <div className={` relative border-solid border border-silver rounded-xl`}>
                <SimpleBar className='min-h-region rounded-xl'>
                {props.loader ||  props.users.length === 0 ? (  
                    <SklTable 
                        tableRow={9}
                        tableCol={3}
                        hasActions={2}
                    />
                ): notFound  ? (  
                    <NotFound msgText={'No documents found!'}/>
                ):(
                    <TableContainer >
                        <Table stickyHeader aria-label="unknown map Datasets">  
                            <TableHead>
                                <TableRow>
                                    <TableCell>Name</TableCell>
                                    <TableCell>Email</TableCell>
                                    <TableCell>Role</TableCell>
                                    <TableCell>Actions</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                            { props.users.length > 0 && props.users.map((user, index) => (
                                <TableRow key={index} >
                                    <TableCell className='max-w-[20vw] truncate'>
                                        {/* <Tooltip title={country.region} arrow placement="bottom">
                                            <span>{country.region}</span>
                                        </Tooltip> */}
                                        <div className='flex items-center'>
                                            {user.name} 
                                            {user.id == userId ? (
                                                <div className='uppercase text-xs font-semibold text-secondary bg-[#DAF1E3] rounded-md py-1 px-2 ml-2'>YOU</div>
                                            ):user.status == 'pending'?(
                                                <div className='uppercase text-xs font-semibold text-magenta bg-magenta bg-opacity-20 rounded-md py-1 px-2 ml-2'>PENDING INVITE</div>
                                            ):''}
                                        </div>
                                    </TableCell>
                                    <TableCell className='truncate'>
                                        {/* <Tooltip title={country.region} arrow placement="bottom">
                                            <span>{country.region}</span>
                                        </Tooltip> */}
                                        {user.email}
                                    </TableCell>
                                    <TableCell className=''>
                                        {user.role.name}
                                    </TableCell>
                                    <TableCell className={`max-w-[10rem]`}>
                                        <IconButton   onClick={()=>editUser(user.id)} className={`mr-4`}>
                                            <img src={EditIcon} alt='icon' className='w-5'/>
                                            </IconButton>
                                        <IconButton disabled={ Cookies.get('userId') && Cookies.get('userId') != user.id ? false : true }  onClick={()=>deleteUser(user.id)} className={`mr-4 ${Cookies.get('userId') && Cookies.get('userId') != user.id ? '' : 'opacity-30'}`}>
                                            <img src={DeleteIcon} alt='icon' className='w-5'/>
                                        </IconButton>
                                    </TableCell>
                                </TableRow>
                            ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                )}
                </SimpleBar>
            </div>
        </ThemeProvider>
    );
}

export default UsersTable;