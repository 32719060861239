import React, {useEffect, useState} from 'react';

import {Box, Typography, Modal} from '@mui/material';

import ButtonSecondary from '../button/Btn_secondary';

import ModalStyle from './Modal_styles.module.scss';

import unlockIcon from '../../assets/unlock.gif'

const ConCurRequestModal = ({openConcurRequest, setOpenConcurRequest, docViwerMessage, requesterId, requesterName, webSocketResponseAcessAllow, setEditDenyTimmer, setEditRequested}) =>{

    //view doc

    const readOnly = () =>{

        setOpenConcurRequest(false)

    }

    //request access

    const reqAccess = () =>{

        setOpenConcurRequest(false)
        webSocketResponseAcessAllow(requesterId)
        setEditDenyTimmer(15)
        setEditRequested(true)
    }

    

    return(
        <div>
            <Modal
                open={openConcurRequest}
                aria-labelledby="modal-modal-title"
            >
                <Box className={`${ModalStyle['modal-base']} ${ModalStyle['modal-disconnect']}`}> 
                    <div className={ModalStyle['modal-container']}>
                        <div className={ModalStyle['content-wrapper']}>
                            <div className={ModalStyle['icon-wrapper']}>
                                <img src={unlockIcon} alt='disconnect icon' className={`${ModalStyle["icon-img"]} modal-p-2 `}/>
                            </div>
                            <Typography id="modal-modal-title" className={ModalStyle['modal-Header1']} align={'center'} variant="text24" component="h2">
                                Document in Use
                                </Typography>
                                <Typography id="modal-modal-description" className={ModalStyle['delete-file']} align={'center'} variant="textBase" component="p">
                                {docViwerMessage}.
                            </Typography>
                        </div>
                        <div className={`${ModalStyle['footer-wrapper-center']}`}>
                            <ButtonSecondary btnName="View Read-Only" type="button" variant={'primaryBtn'} classes="mr-1" onClick={readOnly} disabled={false}/>
                            <ButtonSecondary btnName="Request Access" variant={'secondaryBtn'} type="button" classes="ml-1" onClick={reqAccess} disabled={false}/>
                        </div>
                    </div>    
                </Box>
            </Modal>
        </div> 
    );
}

export default ConCurRequestModal;