import React, {useState, useEffect} from 'react';

import {ThemeProvider, IconButton, Collapse, Typography} from '@mui/material';

import { ThemeCustom} from "../../styles/Theme";
import 'simplebar-react/dist/simplebar.min.css';

import InvoiceInputMap from '../invoice-input/Invoice-input-map';
import InvoiceTableMap from '../invoice-input/invoice-table-2-map';
import SklApproveForm from '../skeletons/Skl_approve-form';

import loaderIcon from '../../assets/Loading1.svg'

import CloseIcon from '@mui/icons-material/Close';
import InfoBlue from '../../assets/info-blue.svg'

const FormInputMap = (props) => {

    const [tableCol, setTableCol] = useState([]);
    const [tableMultiArray, setTableMultiArray] = useState([]);
    const [componentLoad, setComponentLoad] = useState(false)

    //component load detect
    
    useEffect(()=>{
        setComponentLoad(true)
    },[componentLoad])


    //filtering the table data and set them to TableRow

    useEffect(()=>{
        console.log('jsonInvoice form input map---', props.jsonInvoice, typeof(props.jsonInvoice), props.jsonInvoice.length);
        if(props.jsonInvoice.length > 0){
            //console.log('jsonInvoice inside', jsonInvoice, typeof(jsonInvoice), jsonInvoice.length);
            props.jsonInvoice.map((item, index) => {
                if (item.IsTable) {
                //console.log('props.tableRow item',item, item.id)
                  if (!props.mapTableRow.find(row => row.id === item.id)) {
                    //console.log('props.tableRow if item',item)
                    props.setMapTableRow(mapTableRow => [...mapTableRow, item]);
                  }
                  else{
                    //console.log('props.tableRow else item',item, item.id)
                  }
                }
            })
        }        
    },[props.jsonInvoice] )

    // create arrays table wise

    useEffect(()=>{

        //console.log('xxxxxxxxxxxxxxxxxxxxxxx')

        if (props.mapTableRow.length > 0){

            //console.log('iiiiiiiiiiiiiiiiiii')

            const keyMap = {};

            props.mapTableRow.forEach(item => {
                const key = item.Key;
        
                if (!keyMap[key]) {
                keyMap[key] = [];
                }
        
                keyMap[key].push(item);
            });
        
            const result = Object.values(keyMap); // Convert the values of keyMap to an array
            console.log('result---', result)
            //console.log('keyMap---', keyMap)
            setTableMultiArray(result);

        }  

    },[props.mapTableRow, props.jsonInvoice])

    //set the table columns

    useEffect(() => {

        if (tableMultiArray.length > 0){
            setTableCol([]);
            const newTableCol = tableMultiArray.map((oneArray) => {
            // Extract the first object in the inner array
            const firstObject = oneArray[0];
            
            // Filter out unwanted properties
            const filteredKeys = Object.keys(firstObject).filter(
                (key) => key !== 'IsTable' && key !== 'id' && key !== 'Key' && key !== 'IsUpdated'
            );

            console.log('filteredKeys---', filteredKeys, 'firstObject---', firstObject);
        
            // Create the array of objects for the inner array
            return filteredKeys.map((item) => ({
                accessorKey: item,
                header: item.replace(/([A-Z][a-z])/g, ' $1').trim(),
                muiTableBodyCellProps: ({ cell }) => ({
                className: !firstObject[item].connected ? 'cellNotMap' : '',
                }),
                muiTableHeadCellProps: ({ cell }) => ({
                className: !firstObject[item].connected ? 'cellNotMap header' : '',
                }),
            }));

            });
        
            setTableCol(newTableCol);

            //row data
            props.setMapTableData([]);
            const transformedObjects = {};

            tableMultiArray.forEach((oneArray) => {
                oneArray.forEach((item) => {
                const key = item.Key;
                if (!transformedObjects[key]) {
                    transformedObjects[key] = [];
                }

                const transformedObject = {};

                Object.keys(item).forEach((propKey) => {
                    if (!["Key", "IsTable", "id"].includes(propKey)) {
                    transformedObject[propKey] = item[propKey].value;
                    }
                });

                transformedObjects[key].push(transformedObject);
                });
            });

            const transformedArray = Object.values(transformedObjects).map((childArray) => childArray);

            console.log('transformedArray:', transformedArray);

            props.setMapTableData(transformedArray);

        }

    }, [tableMultiArray]);


    //xml search

    const handleInputChange = (key, name, value) =>{
        //console.log(key, name, value);
        props.setSearchText(value);
    } 
    
    //switch buyer and seller

    const switchData = () => {
        const dataSwitch = [...props.jsonInvoice];
        const tempKey = dataSwitch[0].Key; // Preserve line key
        dataSwitch[0].Key = dataSwitch[1].Key;
        dataSwitch[1].Key = tempKey;

        [dataSwitch[0], dataSwitch[1]] = [dataSwitch[1], dataSwitch[0]];
        props.setJsonInvoice(dataSwitch);
    }

//remove

    // useEffect(()=>{     
    //     console.log('form map------------------',props.jsonInvoicex)               
    // },[props.jsonInvoicex])

    useEffect(()=>{
        console.log('jsonMap Forminput', props.jsonInvoice)
    },[props.jsonInvoice])

    useEffect(()=>{
        console.log('tableMultiArray ---', tableMultiArray)
    },[tableMultiArray])

    //form view skeleton handiling

    useEffect(()=>{
        console.log('setSklFormView***********************',props.jsonInvoice.length, props.mapTableRow.length )

        if(props.jsonInvoice.length > 0 && props.mapTableRow.length > 0 && componentLoad){
            props.setSklFormView(false)
        }

    },[props.mapTableRow, props.jsonInvoice])
    
    return(
        <>
            <ThemeProvider theme={ThemeCustom}>
                <div>
                {((props.mapTableRow.length > 0 || props.jsonInvoice.length > 0) && !props.sklFormView ) ? (
                    <>
                        <Collapse in={props.switchCollapse}>
                        <div className='mt-5 mx-5'>
                            <div className='bg-waterBlue py-4 px-5 rounded-xl font-sans flex justify-between items-center'>
                                <img src={InfoBlue} alt='info' className='w-6 h-6 mr-5'/>
                                <div className='text-midnightGreen text-sm font-medium font-sans'>
                                    Multiple parties detected. To ensure accuracy, please verify the buyer and seller. <span onClick={switchData} className='underline text-midnightGreen font-semibold cursor-pointer'>Click here</span> to switch them if needed.
                                </div>
                                <div>
                                    <IconButton className='text-midnightGreen ml-5'
                                        aria-label="close"
                                        onClick={()=>props.setSwitchCollapse(false)}
                                    >
                                        <CloseIcon className='w-5 h-5'/>
                                    </IconButton>
                                </div>
                            </div>
                        </div>
                        </Collapse>
                        <form id='form'>
                            <div className='input-container flex flex-wrap'>
                                    {props.jsonInvoice.map((item, index)=> (
                                        <React.Fragment key={index}>
                                        { !item.IsTable ? (
                                            <div className={`w-1/2 border-b-2 border-0 border-solid border-b-cultured input-row ${[props.disconnectedCount > 0 ? 'input-map':'']}`} key={index}>
                                                <div className='section p-5'>
                                                    <div className='mb-5 flex justify-between items-center'>
                                                        <Typography variant='textBase' className='font-bold'>{(item.Key).replace(/([A-Z][a-z])/g, ' $1').trim()}</Typography>
                                                        {/* <AddDropdown readonly={props.readonly} keyCat={item.Key} setAddField={props.setAddField} addField={props.addField} fieldName={props.fieldName}/> */}
                                                    </div>
                                                    {Object.entries(item).map(([name, value], i)=> (
                                                        <React.Fragment key={i}>
                                                            {(name !== 'Key' && name !== 'IsTable' && name !== 'id' && name !== 'compliant') ? (
                                                                <InvoiceInputMap 
                                                                    inputName={name} 
                                                                    inputType={'text'}  
                                                                    id={item.Key+i}
                                                                    value={value.value.value} 
                                                                    compliant={item.compliant}
                                                                    onInputChange={(newValue) => handleInputChange(item.id, name, newValue)}
                                                                    readonly={props.readonly}
                                                                    setSearchText={props.setSearchText}
                                                                    connected={value.connected}
                                                                />
                                                                ):(
                                                                ''
                                                            )}
                                                        </React.Fragment>  
                                                    ))}
                                                </div>
                                            </div>
                                        ):(
                                            ''
                                        )}  
                                    </React.Fragment>
                                    ))}
                            </div>
                            {tableMultiArray.length > 0 && props.mapTableData.length > 0 && tableCol.length > 0 && tableMultiArray.map((table, index) => (
                            <React.Fragment key={index}>
                                <div className='p-5 pb-0 flex justify-between items-center border-t-2 border-0 border-solid border-t-cultured'>
                                    <Typography variant='textBase' className='font-bold'>{table[0].Key === 'Items' ? 'Line Items' : table[0].Key}</Typography>
                                </div>
                                <InvoiceTableMap
                                    tableRow={props.mapTableData[index]} 
                                    columns={tableCol[index]} 
                                    readonly={props.readonly}
                                    setSearchText={props.setSearchText}
                                />
                            </React.Fragment>
                            ))}
                        </form>
                    </>
                ):(

                    <SklApproveForm/>
                    // props.jsonInvoice.length > 0 ? (
                    // ):(
                    // <div className='flex flex-col items-center justify-center min-h-xml-viewer'>
                    //     <img src={loaderInvalid} alt='loader icon' width={250} className='mb-8'/>
                    //     <Typography id="modal-modal-title" className='font-semibold	text-2xl'align={'center'} variant="subHeader1" component="h2">
                    //         The document you provided is either not a valid invoice or it has an incorrect format.
                    //     </Typography>
                    // </div>
                    // )
                )}
                </div>
            </ThemeProvider>
        </>
    )
}

export default FormInputMap;