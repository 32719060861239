import React, {useEffect, useState} from 'react';
import { useForm } from "react-hook-form";
import axios from "axios";
import Cookies from 'js-cookie';
import SimpleBar from 'simplebar-react';
import { useBaseUrlContext } from '../../context/Base_url';
import { useAuthContext } from '../../context/Auth';
import useAxiosInstance from '../../api/interceptor';

import {Box, Typography, Modal, FormControl, OutlinedInput, InputLabel, Select, MenuItem, InputAdornment} from '@mui/material';

import ButtonSecondary from '../button/Btn_secondary';
import SklInputPopup from '../skeletons/Skl_input_popup';
import ButtonSecondaryLoader from '../button/Btn_secondary_loader';

import ModalStyle from './Modal_styles.module.scss';
import './Modal_styles.scss';
import 'simplebar-react/dist/simplebar.min.css';

const OrgSettingsModal = ({openOrgSetting, setOpenOrgSetting, popupLoader, setEditId, editId, orgEditData, getOrgData, setOpenFail, setTextFail, setOpenSuccess, setTextSuccess, isSubOrg, setOrgEditData, parentOrgId}) =>{

    const { register, handleSubmit, formState: { errors }, reset, setValue } = useForm();
    const [uploading, setUploading] = useState(false) 
    const [selectedCountryCode, setSelectedCountryCode] = useState('')
    const [countryList, setCountryList] = useState([])
    const [selCountry, setSelCountry] = useState('')
    const [extraFields, setExtraFields] = useState([])
    //const [orgFields, setOrgFields] = useState({"id": "4a5dc0af-e967-46dc-b254-42bf5a15ebef","orgName": "Pegero-extra","receiverName": "Banda","receiverEmail": "bandara@getlayup.com","attachmentEmail": "bandara-docupath@getlayup.com","address": "Colombo 01","countryCode": "LKA","city": "Colombo","postalCode": "1110","regNumber": "44545","vatNumber": null,"glnNumber": null,"pageroId": null,"isSubOrganization": 0})
    const [orgFields, setOrgFields] = useState({})
    const [step, setStep] = useState(0)
    const [isSub, setIsSub] = useState(0)
    const {BaseUrl} = useBaseUrlContext()
    const {tokenReview, popupAction} = useAuthContext()
    const axiosInstance = useAxiosInstance();
    const [btnLoading, setBtnLoading] = useState(false)

    useEffect(()=>{console.log('extraFields',extraFields)},[extraFields])

    useEffect(()=>{
        console.log('formData', register, )
    },[])

    useEffect(()=>{console.log('editOrgData*** org sub',orgEditData)},[orgEditData])



    useEffect(()=>{
        console.log('parentOrgId',parentOrgId)
    },[parentOrgId])


    useEffect(()=>{console.log('tokenReview region add popup',tokenReview)},[tokenReview])


    useEffect(()=>{
        if(openOrgSetting){
            setUploading(false)
            getcountryList()
        }
    },[openOrgSetting])

    //cancel

    const cancel = () =>{

        if(step === 1){
            setStep(0)
            //reset({orgName:"", attachmentEmail:"", receiverName:"", address:"",countryCode:"",city:"",postalCode:"",regNumber:"",vatNumber:"",glnNumber:"",pageroId:""})
            //setOrgEditData([])
        }
        else{
            setOpenOrgSetting(false)
            reset({orgName:"", attachmentEmail:"", receiverName:"", address:"",countryCode:"",city:"",postalCode:"",regNumber:"",vatNumber:"",glnNumber:"",pageroId:""})
            setOrgFields({})
            setExtraFields([])
            setSelectedCountryCode('')
            setSelCountry('')
            setStep(0)
            //setOrgEditData([])

        }
        
    }

    //on submit function

    const onSubmit = (data) => {

        data.countryCode = selectedCountryCode;
        console.log('isSub',isSub, data, extraFields, step)


        if(step === 0 && extraFields.length < 1){

            console.log('on submit', step, extraFields, extraFields.length, isSubOrg)

            if(editId === 0){


                    const {attachmentEmail, ...rest}  = data
                    console.log('removeAttachmentEmail',data)
                    const dataObj = {...rest, extraFields:extraFields, parentOrgId:parentOrgId}
                    console.log('submit data', dataObj)
                    dataPost(dataObj);
                
            }
            else{

                const {attachmentEmail, ...rest}  = data
                console.log('removeAttachmentEmail',data)
                    const dataObj = {...rest, extraFields:extraFields, orgId:editId, parentOrgId:parentOrgId}
                    console.log('submit data', dataObj)
                    dataUpdate(dataObj);
                
            }
        }
        else{

            console.log('on submit else', step, extraFields, extraFields.length, isSubOrg)
            setStep(1)
    
        }

        if(step === 1){

            if(editId === 0){
            
                const {attachmentEmail, ...rest}  = data
                    const dataObj = {...rest, extraFields:extraFields, parentOrgId:parentOrgId}
                    console.log('submit data', dataObj)
                    dataPost(dataObj);
            }
            else{

                const {attachmentEmail, ...rest}  = data
                    const dataObj = {...rest, extraFields:extraFields, orgId:editId, parentOrgId:parentOrgId}
                    console.log('submit data', dataObj)
                    dataUpdate(dataObj);
            }
        }

    }

    //new data post

    const dataPost = async(data) => {

        setBtnLoading(true)

        try {

            const res = await axiosInstance.post(`/organization.php`, data)
            //console.log(res)
            let result = res.data.result;
            let error = res.data.error;

            console.log(result.message)
            setUploading(false)
            setOpenOrgSetting(false)
            getOrgData()
            setOpenSuccess(true)
            setTextSuccess(result.message)
            reset({orgName:"", attachmentEmail:"", receiverName:"",address:"",countryCode:"",city:"",postalCode:"",regNumber:"",vatNumber:"",glnNumber:"",pageroId:""})
            setOrgFields({})
            setExtraFields([])
            setSelectedCountryCode('')
            setSelCountry('')
            setStep(0)
            //setOrgEditData([])
            setBtnLoading(false)

        } catch (err) {
            console.error('fetchData:', err);
            setBtnLoading(false)
            if (!err?.response?.alertShown) {
                setOpenFail(true)
                err?.response?.data?.error.text ? setTextFail(err.response.data.error.text): setTextFail('The server encounted an error and could not complete your request.')
            }
        }   

    }

    //data update

    const dataUpdate = async(data) => {

        console.log('data update')
        setBtnLoading(true)

        try {

            const res = await axiosInstance.put(`/organization.php`, data)

            let result = res.data.result;
            let error = res.data.error;

            console.log(result.message)
            setUploading(false)
            setOpenOrgSetting(false)
            getOrgData()
            setOpenSuccess(true)
            setTextSuccess(result.message)
            reset({orgName:"", attachmentEmail:"", receiverName:"",address:"",countryCode:"",city:"",postalCode:"",regNumber:"",vatNumber:"",glnNumber:"",pageroId:""})
            setOrgFields({})
            setExtraFields([])
            setSelectedCountryCode('')
            setSelCountry('')
            setStep(0)
            //setOrgEditData([])
            setBtnLoading(false)

        } catch (err) {
            console.error('fetchData:', err);
            setBtnLoading(false)
            if (!err?.response?.alertShown) {
                setOpenFail(true)
                err?.response?.data?.error.text ? setTextFail(err.response.data.error.text): setTextFail('The server encounted an error and could not complete your request.')
            }
        }    

    }

    //get country list

    const getcountryList = async() =>{

        try {

            const res = await axiosInstance.get(`/country.php`)

            let data = res.data.result;
            let error = res.data.error;
            setCountryList(data.countries)
                    
        } catch (err) {
            console.error('fetchData:', err);
            if (!err?.response?.alertShown) {
                setOpenFail(true)
                err?.response?.data?.error.text ? setTextFail(err.response.data.error.text): setTextFail('The server encounted an error and could not complete your request.')
            }
        }        

    }

    //get extra fields func call

    useEffect(()=>{
        console.log('selectedCountry:', selCountry);
        if(selCountry !== '' && selCountry){
            let countryCode;
            console.log('typeof countryCode ',typeof selCountry, selCountry )
            if(typeof selCountry === 'string'){
                countryCode = JSON.parse(selCountry).code
            }
            else{
                countryCode = selCountry.code
            }
            if(editId === 0){
                getCountryFields(countryCode)
            }
            setSelectedCountryCode(countryCode)
        }
        
    },[selCountry])

    //get extra fields

    const getCountryFields = async(code) => {

        console.log('getCountryFields ', code)
        setUploading(true)

        try {

            const res = await axiosInstance.get(`/organization.php?countryCode=${code}`)

            let data = res.data.result;
            let error = res.data.error;

            console.log('country api', data.fields)
            setExtraFields(data.fields)
            setUploading(false)

                   
        } catch (err) {
            console.error('fetchData:', err);
            if (!err?.response?.alertShown) {
                setOpenFail(true)
                err?.response?.data?.error.text ? setTextFail(err.response.data.error.text): setTextFail('The server encounted an error and could not complete your request.')
            }
        }    

    }

    const setInputVal = (val, name) => {

    }

    //extra fields input handling

    const setExtraInputVal = (val, index) => {

        const updatedFields = [...extraFields];
        updatedFields.at(index).value = val
        setExtraFields(updatedFields)

    }

    //separate the edit obj

    useEffect(()=>{

        if(orgEditData && editId !== 0){
            const {extraFields, ...other} = orgEditData;
            setExtraFields(extraFields);
            setOrgFields(other)
            console.log('orgEditData separate',extraFields, other )
        }    

    },[orgEditData, editId])

    //form edit values set

    useEffect(()=>{

        console.log('orgFields', orgFields)

        if(openOrgSetting){

            Object.entries(orgFields).map(([key, value]) => {
                console.log('orgFields', key, value)
                if(key === 'countryCode'){
                    const findCountry = countryList.find(item => item.code === value);
                    setValue(key,value)
                    setSelCountry(JSON.stringify(findCountry))
                    console.log('findCountry',JSON.stringify(findCountry))
                }
                else{
                    setValue(key,value)
                }
            })

        }

    },[orgFields])


    return(

        <div>
            <Modal
                open={openOrgSetting}
                aria-labelledby="modal-modal-organizations-create"
            >
                <Box className={`${ModalStyle['modal-base']} ${ModalStyle['modal-org-setting']}`}> 
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <div className={ModalStyle['modal-container']}>
                            <div className={ModalStyle['content-wrapper']}>
                                <Typography id="modal-modal-title" align='center' className={`${ModalStyle['modal-Header1']} modal-mb-5`} variant="text24" component="h2">
                                {editId === 0 ? 'Add new sub organization' :  'Edit sub organization settings'} 
                                </Typography>
                                    <Typography align='center' className={'modal-org-subTitle modal-mb-12 '} variant="text20" component="div">
                                        {step === 0 ? (<>{extraFields && extraFields.length > 0 ? (<span className='step-span'>Step 1: </span>):''} Basic information </>) : (<><span className='step-span'>Step 2: </span> Identification numbers </>)} 
                                    </Typography>
                                {step === 0 ? (
                                <>
                                <SimpleBar className={`min-h-organization-modal`}>
                                    <Typography className={`${ModalStyle['modal-Header1']} modal-mb-5`} variant="text24"  component="div">Organization details</Typography>
                                    <FormControl variant="standard" fullWidth  margin="normal" className='modal-mt-2 form-control modal-mb-5' >
                                        <InputLabel shrink htmlFor='name' title='text' className='modal-label'>
                                            Organization name <span className='mand-mark'>*</span>
                                        </InputLabel>
                                        {popupLoader ? (
                                            <SklInputPopup/>
                                        ):(
                                        <>
                                            <OutlinedInput 
                                                id='org-name'
                                                placeholder='Enter organization name'
                                                //value={orgVal.orgName}
                                                autoComplete="off" 
                                                type='text'
                                                className='modal-input'
                                                {...register('orgName',{ required: true , onChange: (e) => setInputVal(e.target.value, 'orgName') })} 
                                            />
                                            {errors.orgName && <p className='err-msg'>Organization name is required</p>}
                                        </>
                                        )}
                                    </FormControl>

                                    <FormControl variant="standard" fullWidth  margin="normal" className='modal-mt-0 form-control modal-mb-5' >
                                        <InputLabel shrink htmlFor='name' title='text' className='modal-label'>
                                            Receiver name <span className='mand-mark'>*</span>
                                        </InputLabel>
                                        {popupLoader ? (
                                            <SklInputPopup/>
                                        ):(
                                        <>
                                        <OutlinedInput 
                                            id='receiver-name'
                                            placeholder='Enter receiver name'
                                            //value={orgVal.receiverName}
                                            autoComplete="off" 
                                            type='text'
                                            className='modal-input'
                                            {...register('receiverName',{ required: true , onChange: (e) => setInputVal(e.target.value, 'receiverName') })} 
                                        />
                                        {errors.receiverName && <p className='err-msg'>Receiver name is required</p>}
                                        </>
                                        )}
                                    </FormControl>

                                    <Typography className={`${ModalStyle['modal-Header1']} modal-mt-7 `} variant="text24" component="div">Address details</Typography>
                                    <FormControl variant="standard" fullWidth  margin="normal" className='modal-mt-2 form-control modal-mb-5' >
                                        <InputLabel shrink htmlFor='name' title='text' className='modal-label'>
                                        Address <span className='mand-mark'>*</span>
                                        </InputLabel>
                                        {popupLoader ? (
                                            <SklInputPopup/>
                                        ):(
                                        <>
                                        <OutlinedInput 
                                            id='org-address'
                                            placeholder='Enter address'
                                            //value={orgVal.address}
                                            autoComplete="off" 
                                            type='text'
                                            className='modal-input'
                                            {...register('address',{ required: true , onChange: (e) => setInputVal(e.target.value, 'address') })} 
                                        />
                                        {errors.address && <p className='err-msg'>Address is required</p>}
                                        </>
                                        )}
                                    </FormControl>
                                    <FormControl variant="standard" fullWidth  margin="normal" className='modal-mt-0 form-control modal-mb-5' >
                                        <InputLabel shrink htmlFor='name' title='text' className='modal-label'>
                                            Country <span className='mand-mark'>*</span>
                                        </InputLabel>
                                        {popupLoader ? (
                                            <SklInputPopup/>
                                        ):(
                                        <>
                                        <Select
                                            id='country'
                                            className='w-modal-full select-modal type-filter-select'
                                            value={selCountry}
                                            input={<OutlinedInput />}
                                            displayEmpty
                                            renderValue={(selCountry) => {
                                                if (!selCountry) {
                                                return <span className='text-sonicSilver'>-Select country-</span>;
                                                }
                                                return JSON.parse(selCountry).name;
                                            }}
                                            inputProps={{ 'aria-label': 'Country' }}
                                            {...register('countryCode',{ required: editId === 0  ? true : selCountry !== '' ? true : false , onChange: (e) => setSelCountry(e.target.value), x:console.log('selCountry xxx',selCountry) })} 
                                        >
                                            { countryList.length > 0 && countryList.map((filter, index) => (
                                                <MenuItem 
                                                    className='country-down-item'
                                                    key={index} 
                                                    value={JSON.stringify(filter)} 
                                                >
                                                    {filter.name}
                                                </MenuItem>
                                            ))}
                                            
                                        </Select>
                                        {errors.countryCode && <p className='err-msg'>Country is required</p>}
                                        </>
                                        )}
                                    </FormControl>
                                    <div className='modal-flex modal-justify-between'>
                                        <FormControl variant="standard"  margin="normal" className='modal-mt-2 form-control modal-mb-5 modal-w-49' >
                                            <InputLabel shrink htmlFor='name' title='text' className='modal-label'>
                                            City 
                                            </InputLabel>
                                            {popupLoader ? (
                                                <SklInputPopup/>
                                            ):(
                                            <>
                                            <OutlinedInput 
                                                id='org-city'
                                                placeholder='Enter city'
                                                //value={orgVal.city}
                                                autoComplete="off" 
                                                type='text'
                                                className='modal-input'
                                                {...register('city',{ required: false , onChange: (e) => setInputVal(e.target.value, 'city') })} 
                                            />
                                            </>
                                            )}
                                        </FormControl>
                                        <FormControl variant="standard"  margin="normal" className='modal-mt-2 form-control modal-mb-5 modal-w-49' >
                                            <InputLabel shrink htmlFor='name' title='text' className='modal-label'>
                                            Postal code
                                            </InputLabel>
                                            {popupLoader ? (
                                                <SklInputPopup/>
                                            ):(
                                            <>
                                            <OutlinedInput 
                                                id='org-zipCode'
                                                placeholder='Enter postal code'
                                                //value={orgVal.postalCode}
                                                autoComplete="off" 
                                                type='text'
                                                className='modal-input'
                                                {...register('postalCode',{ required: false , onChange: (e) => setInputVal(e.target.value, 'zipCode') })} 
                                            />
                                            </>
                                            )}
                                        </FormControl>
                                    </div>
                                   
                                </SimpleBar>    
                                </>  
                                ):(
                                    <>
                                    <SimpleBar className='min-h-organization-modal-step2'>
                                        <Typography className={`${ModalStyle['modal-Header1']} modal-mb-5`} variant="text24" component="div">Registration details</Typography>
                                        <div className='modal-flex modal-justify-between modal-flex-wrap'>
                                            {isSubOrg !== 0 && extraFields.length > 0 && extraFields.map((input, index)=>(
                                                <FormControl key={input.id} variant="standard"  margin="normal" className='modal-mt-2 form-control modal-mb-5 modal-w-49' >
                                                    <InputLabel shrink htmlFor='name' title='text' className='modal-label'>
                                                        {input.field}
                                                    </InputLabel>
                                                    <OutlinedInput 
                                                        id={input.id}
                                                        placeholder={`Enter ${input.field}`}
                                                        value={input.value}
                                                        autoComplete="off" 
                                                        type='text'
                                                        className='modal-input'
                                                        onChange={(e) => setExtraInputVal(e.target.value, `${index}`)}
                                                        //{...register(`${input.id}`,{ required: false , onChange: (e) => setInputVal(e.target.value, `${input.id}`) })} 
                                                    />
                                                </FormControl>
                                            ))}
                                        </div>
                                    </SimpleBar>    
                                    </>
                                )
                                }  
                            </div>
                            <div className={`${ModalStyle['footer-wrapper-center']}  modal-mt-5`}>
                                <ButtonSecondary btnName={step === 0 ? "Cancel" : 'Back'} variant={'primaryBtn'} classes='modal-button-12' type="button" onClick={cancel} disabled={uploading}/>
                                {/* <ButtonSecondary btnName={step === 0 && extraFields && extraFields.length > 0 ? "Continue to Finish" : 'Finish'} variant={'secondaryBtn'} classes='modal-button-12' type="submit" onClick={()=>{}} disabled={popupLoader || uploading}/> */}
                                <ButtonSecondaryLoader variant={'secondaryBtn'} type='submit' classes='modal-button-12' btnName={step === 0 && extraFields && extraFields.length > 0 ? "Continue to Finish" : 'Finish'} btnLoading={btnLoading} disabled={popupLoader || uploading} onClick={()=>{}}/>

                            </div>
                        </div>    
                    </form>
                </Box>
            </Modal>
        </div> 
        
    );
}

export default OrgSettingsModal;