import * as React from 'react';

import { ThemeProvider, Button } from "@mui/material";

import { ThemeCustom} from "../../styles/Theme";

const ButtonOutline = (props) => {
    return (
        <ThemeProvider theme={ThemeCustom}>
          <Button variant="primaryOutline" type={props.type} className={props.classes} onClick={props.onClick}>
            {props.btnName}</Button>
        </ThemeProvider>
    );
}

export default ButtonOutline;