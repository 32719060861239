import React, {useState, useEffect, useRef} from 'react';
import axios from "axios";
import Cookies from 'js-cookie';
import SimpleBar from 'simplebar-react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useBaseUrlContext } from '../../context/Base_url';
import { useUserContext } from '../../context/User_Access';
import { useAuthContext } from '../../context/Auth';
import useAxiosInstance from '../../api/interceptor';

import {ThemeProvider, Container, AppBar, Checkbox, Table, TableHead, TableBody, TableCell, TableContainer, TableRow, Typography, Skeleton } from '@mui/material';

import { ThemeCustom} from "../../styles/Theme";
import 'simplebar-react/dist/simplebar.min.css';

import CheckIcon from '../../assets/tick-select.svg'; 
import UncheckIcon from '../../assets/tick-notselect.svg'; 
import trash from '../../assets/trash-red.svg'

import ButtonSecondary from '../button/Btn_secondary';
import NotFound from '../masseges/Not_found';
import ButtonOutlineImg from '../button/Btn_outline_img';
import DeleteModal from '../modal/Modal_delete';
import SklTable from '../skeletons/Skl_table';
import DragDropMore from './Drag-more_input';

const FilePreviw = (props) => {

    const [files, setFiles] = useState([]);
    const [searchFile, setSearchFile] = useState([]);
    const [selected, setSelected] = useState([]);
    const [allSelected, setAllSelected] = useState(false);
    const [submitFile, setSubmitFiles] = useState([]);
    const [searchParams, setSearchParams] = useSearchParams();
    const [notFound, setNotFound] = useState(false);
    const [openDelete, setOpenDelete] = useState(false);
    const [delType, setDelType] = useState(2);
    const [modalText, setModalText]  = useState('');
    const [uploadStatus, setUploadStatus] = useState({});
    const [allowFilesDrag, setAllowFilesDrag] = useState(false);
    const navigate = useNavigate();
    const {BaseUrl} = useBaseUrlContext()
    const {devMode} = useUserContext()
    const {tokenReview} = useAuthContext()
    const axiosInstance = useAxiosInstance();

    //skeleton loader handeling

    useEffect(()=>{
        //props.setSklFilePreview(true)
        setTimeout(()=>{
            props.setSklFilePreview(false)
        },1000)
    },[])


    useEffect(() => {

        console.log('files preveiw',props.fileArray);

        setFiles(props.fileArray)
        setSearchFile(props.fileArray)
        setSubmitFiles(props.fileArray)


    }, [props.fileArray]);

    useEffect(()=>{
        console.log('allSelected files', files)
        if(files.length > 0){
            const allSelected = files.map((doc, index) => doc.id);
            setSelected(allSelected);
            if(allSelected.length === files.length){
                setAllSelected(true);
                console.log('allSelected xxx', allSelected.length, files.length)
            }
        }
    },[files])

    
    useEffect(()=>{

        if(files.length > 0){
            const filteredFiles = files.filter((file) =>
                file.filename.toLowerCase().includes(props.searchTerm.toLowerCase())
            );
            setSearchFile(filteredFiles);
            //console.log('filteredFiles',filteredFiles);
        }

    },[props.searchTerm])

    useEffect(() => {

        //console.log("searchFile.length",searchFile.length);

        if(files.length !== 0 && searchFile.length === 0){
            setNotFound(true)
        }
        else{
            setNotFound(false)
        }

    }, [searchFile]);


    const submit = async (e) => {
        try {
        const tokenReviewresult = await tokenReview();
        console.log('tokenReviewresult', tokenReviewresult);

        if (tokenReviewresult) {
           // submitAPI(e);
        }
        } catch (error) {
        console.error('fileupload:', error);
        }
    };

    const submitAPI = async (e) => {
        e.preventDefault();
    
        props.setUploadCompeted(false);
        props.setUploading(true);
        props.setUploadFiles(0); // Initially, no files are uploaded
        props.setSubmitFiles(submitFile.length);
    
    
        let successfulUploads = 0; // Track successful uploads
    
        if (submitFile.length > 0) {

            if(devMode){
                navigate('/document_review?devMode=true');
            }else{
                navigate('/document_review');
            }

            for (let index = 0; index < submitFile.length; index++) {

                const file = submitFile[index];
                const formData = new FormData();
                formData.append('invoice', file.file);

                try{
         
                    const res = await axiosInstance.post(`/upload_file.php`, formData)

                        if (res.status === 200) {
                            successfulUploads++;
                            props.setUploadFiles(successfulUploads); // Update successful uploads count
                        }

                    }
                    catch(err) {
                        // Handle upload failure if needed
                        console.error('Upload failed:', err);
                    }
                    finally{

                        // Update UI after each file upload attempt
                        const currentProgress = `${successfulUploads} out of ${submitFile.length}`;
                        console.log(`Uploading: ${currentProgress}`);
    
                        // Check if all files have been attempted to be uploaded
                        if (index === submitFile.length - 1) {
                            props.setUploadCompeted(true);
                            setTimeout(() => {
                                props.setUploading(false);
                            }, 5000);
    
                            console.log(`Number of successful files: ${successfulUploads}`);
                            console.log(`Number of failed files: ${submitFile.length - successfulUploads}`);
                            sessionStorage.setItem('results', JSON.stringify({ successfulFiles: successfulUploads, failedFiles: submitFile.length - successfulUploads }));
                        }
                    }
            };
        }
    };  

    useEffect(()=>{

        //console.log('selected',selected, files);
        //console.log('file length = ',files.length, 'selected length = ',selected.length)

        files.length === selected.length && selected.length > 0 ? setAllSelected(true) : setAllSelected(false);

        let fileSubmit = files.filter((file) => selected.includes(file.id));
        setSubmitFiles(fileSubmit);

        console.log('fileSubmit', fileSubmit)

    },[selected])

    useEffect(()=>{

        console.log('allSelected',allSelected)

    },[allSelected])


    const isItemAllSelected = (checked) => {

        console.log('isItemSelected All', checked)

        if(checked){
            const allSelected = files.map((doc, index) => doc.id);
            setSelected(allSelected);
            setAllSelected(true);
        }
        else{
            setSelected([]);
            setAllSelected(false);
        }
    }

    const isItemSelected = (checked, id) => {

        //console.log('isItemSelected', checked, id)

        if(checked){
            setSelected([...selected,id])
        }
        else{
            let fileUncheck = selected.filter((select) => select !== id);
            setSelected(fileUncheck)
        }

    }

    const deleteMutiple = () =>{

        //console.log('deleteMutiple')

        let fileDelete = files.filter((file) => !selected.includes(file.id));
        setFiles(fileDelete)
        setSearchFile(fileDelete);
        setSelected([]);
        setAllSelected(false);
        props.setFiles(fileDelete)
        props.setSearchTerm('');
        if(fileDelete.length === 0){
            props.setDisabled(true);
        }
        // console.log(fileDelete);
        // console.log('rows', files, files.length );

    }

    const handleDrop = (e) => {
        //console.log('handleDrop')
        setTimeout(()=>{
            setAllowFilesDrag(false)
        },200)
    }

    const handleDragOver = (e) => {
        //console.log('handleDragOver')
        if(allowFilesDrag === false){
            setAllowFilesDrag(true)
        }
    }

    const handleDragLeave = (e) => {
        //console.log('handleDragLeave')
        if(allowFilesDrag === true){
            setAllowFilesDrag(false)
        }    
    }

    return(
        <ThemeProvider theme={ThemeCustom}>
            <DeleteModal 
                setOpenDelete={setOpenDelete} 
                openDelete={openDelete} 
                delType={delType} 
                modalText={modalText} 
                deleteMutiple={deleteMutiple} 
                from={'filePreview'}
            />
            <div 
                className={`mb-5 border-solid border ${allowFilesDrag? 'border-secondary':'border-borderGrayDark' } rounded-xl relative`}
                //onDragEnter={(event) => handleDragEnter(event)}
                onDragOver={(event) => handleDragOver(event)}
            >
                <>
                <div 
                    className={`${allowFilesDrag ? 'flex' : 'hidden' } z-10 w-full absolute`}
                    onDrop={(event) => handleDrop(event)}
                    onDragLeave	={(event) => handleDragLeave(event)}
                >
                    <DragDropMore
                        files={props.fileArray}
                        setFiles={props.setFiles}
                    />
                </div>
                <SimpleBar 
                    className={`${selected.length > 0 ? 'min-height-uploadView' : 'min-height-uploadView-uncheck'} relative`}
                >
                    {props.loader?(
                        <SklTable 
                            tableRow={9}
                            tableCol={2}
                            hasCheckBox={true}
                        />
                    ) :notFound ? (   
                        <NotFound msgText={'No result found!'}/>
                    ):(
                        <TableContainer>
                            <Table stickyHeader  aria-label="Uploaded documents">  
                                <TableHead>
                                    <TableRow>
                                        <TableCell className='rounded-tl-xl w-6'>
                                            <Checkbox 
                                                disableTouchRipple={true}
                                                disableRipple={true}
                                                className='py-0'
                                                color="primary"
                                                id='selAll'
                                                checkedIcon={<img src={CheckIcon} alt='checked' className='w-6'/> }
                                                icon={<img src={UncheckIcon} alt='uncheck' className='w-6'/> }
                                                checked={allSelected}
                                                onChange={(e)=>isItemAllSelected(e.target.checked)}
                                            />
                                        </TableCell>    
                                        <TableCell  className='min-w-[calc(100vw-50rem)]'>File Names</TableCell>
                                        <TableCell className='rounded-tr-xl max-w-[15rem]'>Status</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                { files.length !== 0 && searchFile.map((file, index) => (
                                    <TableRow key={index}>
                                        <TableCell>
                                        <Checkbox 
                                            disableTouchRipple={true}
                                            className='hover:bg-transparent'
                                            color="primary"
                                            id={`sel-${file.id}`}
                                            checkedIcon={<img src={CheckIcon} alt='checked' className='w-6'/> }
                                            icon={<img src={UncheckIcon} alt='uncheck' className='w-6'/> }
                                            checked={selected.includes(file.id)}
                                            onChange={(e)=>isItemSelected(e.target.checked, file.id)}
                                        />
                                        </TableCell>
                                        <TableCell className='max-w-[50vw] truncate'>
                                            {file.filename}
                                        </TableCell>
                                        <TableCell className='text-fulvous bg- max-w-[15rem]'>
                                            Pending upload
                                        </TableCell>
                                    </TableRow>
                                ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    )}
                </SimpleBar>
                </>
            </div>        
            {selected.length > 0 ? (
            <AppBar position="fixed" className='bg-white shadow-none border-t border-0 border-solid border-t-borderGrayDark top-auto bottom-0'>
                <Container maxWidth="false">
                    <div className='py-5 flex justify-end items-center'>
                        <div className='flex justify-between  w-full'>
                            {props.sklFilePreview ? (
                                <>
                                    <div className='flex items-center'>   
                                        <Skeleton animation="wave" className={` min-w-[13.625rem] max-h-[3.449rem] h-[3.449rem] rounded-xl`}></Skeleton>
                                        <Skeleton animation="wave" className='w-[6.875rem] max-h-[1.25rem] h-[1.25rem] rounded ml-5' ></Skeleton>
                                    </div>
                                    <Skeleton animation="wave" className={` min-w-[11.9rem] max-h-[3.449rem] h-[3.449rem] rounded-xl`}></Skeleton>
                                </>
                            ):(
                                <>
                                    <div>   
                                        <ButtonOutlineImg btnName="Delete Selected" variant={'redOutline'} img={trash} type="button" onClick={()=>setOpenDelete(true)} disabled={false}/>
                                        <Typography variant={'textBase'} component={'span'} className='ml-5 font-semibold'>{`${selected.length} File${selected.length === 1 ? '':'s'} selected`}</Typography>
                                    </div>
                                    <ButtonSecondary btnName="Process Selected" variant={'secondaryBtn'} type="submit" onClick={submitAPI} disabled={props.disabled}/>
                                </>
                            )}
                        </div>
                    </div>
                </Container>
            </AppBar>   
            ):
            ('')} 
        </ThemeProvider>
    )
};

export default FilePreviw;