import React, {useState, useEffect, useRef} from 'react';
import {ThemeProvider, Skeleton, TableContainer, Table, TableHead, TableRow, TableCell, TableBody } from '@mui/material';

import { ThemeCustom } from "../../styles/Theme";


const SklTable = (props) => {

    const [tableRow, setTableRow] = useState([])
    const [tableCol, setTableCol] = useState([])
    const [hasCheckBox, setHasCheckBox] = useState(props.hasCheckBox ?  true: false)
    const [hasActions, setHasActions] = useState([])

    //set table rows

    useEffect(()=>{

        console.log('props.tableRow',props.tableRow)

        if(props.tableRow){

            let tableRowArray = []

            for(let i=0; i < props.tableRow; i++){
                tableRowArray.push(i)
            }

            console.log('tableRowArray', tableRowArray)
            setTableRow(tableRowArray);
        }

    },[props.tableRow])

    //set table cols

    useEffect(()=>{

        console.log('props.tableCol',props.tableCol)

        if(props.tableCol){

            let tableColArray = []

            for(let i=0; i < props.tableCol; i++){
                tableColArray.push(i)
            }

            console.log('tableColArray', tableColArray)
            setTableCol(tableColArray);
        }

    },[props.tableCol])

    //set actions

    useEffect(()=>{

        console.log('props.hasActions',props.hasActions)

        if(props.hasActions){

            let hasActionsArray = []

            for(let i=0; i < props.hasActions; i++){
                hasActionsArray.push(i)
            }

            console.log('hasActionsArray', hasActionsArray)
            setHasActions(hasActionsArray);
        }

    },[props.hasActions])


    return(
        <ThemeProvider theme={ThemeCustom}>
            <TableContainer >
                <Table stickyHeader aria-label="table skeleton">  
                    <TableHead>
                        <TableRow className='h-[3.75rem]'>
                            {hasCheckBox?(
                                <TableCell className='w-6 rounded-tl-xl'>
                                    <Skeleton animation="wave" className={` w-[1.56rem] h-[1.56rem] rounded`}></Skeleton>    
                                </TableCell>
                            ):''}
                            { tableCol.length > 0 && tableCol.map((col, i) => (
                            <TableCell key={i}>
                                <Skeleton animation="wave" className={` w-full max-w-[80%] max-h-[1.125rem] h-[1.125rem] rounded`}></Skeleton>    
                            </TableCell>
                            ))}
                            {hasActions.length > 0 ? (
                                <TableCell className='rounded-tr-xl w-[12vw]'>
                                    <Skeleton animation="wave" className={` w-full max-w-[80%] max-h-[1.125rem] h-[1.125rem] rounded`}></Skeleton>    
                                </TableCell>
                            ) :''}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                    { tableRow.length > 0 && tableRow.map((row, index) => (
                        <TableRow key={index} className='h-[3.75rem]'>
                            {hasCheckBox?(
                            <TableCell>
                                <Skeleton animation="wave" className={` w-full max-w-[1.56rem] max-h-[1.56rem] h-[1.569rem] rounded`}></Skeleton>    
                            </TableCell>
                            ):''}
                            { tableCol.length > 0 && tableCol.map((col, i) => (
                                <TableCell key={i}>
                                    <Skeleton animation="wave" className={` w-full max-w-[80%] max-h-[1.125rem] h-[1.125rem] rounded`}></Skeleton>    
                                </TableCell>
                            ))}
                            {hasActions.length > 0 ? (
                            <TableCell>
                                <div className='flex'>
                                    {hasActions.length > 0 && hasActions.map((act, i) => (
                                        <div className='mr-4' key={i}>
                                            <Skeleton animation="wave" className={` w-[1.56rem] h-[1.56rem] rounded`}></Skeleton>    
                                        </div>
                                    ))}
                                </div>
                            </TableCell>
                            ):''}
                        </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </ThemeProvider>
    )
}

export default SklTable;

