import React, {useEffect, useState} from 'react';
import { useForm } from "react-hook-form";
import axios from "axios";
import Cookies from 'js-cookie';
import { useBaseUrlContext } from '../../context/Base_url';
import { useAuthContext } from '../../context/Auth';
import useAxiosInstance from '../../api/interceptor';

import {Box, Typography, Modal, FormControl, OutlinedInput, InputLabel, Autocomplete, TextField} from '@mui/material';

import ButtonSecondary from '../button/Btn_secondary';
import SklInputPopup from '../skeletons/Skl_input_popup';
import ButtonSecondaryLoader from '../button/Btn_secondary_loader';

import ModalStyle from './Modal_styles.module.scss';
import './Modal_styles.scss';


const RegionAddModal = ({openAddRegion, setOpenAddRegion, editId, getTableData, setOpenFail, setTextFail, setOpenSuccess, setTextSuccess}) =>{

    const { register, handleSubmit, formState: { errors }, reset, setValue } = useForm();
    const [uploading, setUploading] = useState(false)
    const [selectedCountry, setSelectedCountry] = useState([])
    const [selectedCountryCode, setSelectedCountryCode] = useState([])
    const [countryList, setCountryList] = useState([])
    const [countryListFilter, setCountryListFilter] = useState([])
    const [regionName, setRegionName] = useState('')
    const [popupLoader, setPopupLoader] = useState(false)
    const {BaseUrl} = useBaseUrlContext()
    const {tokenReview, popupAction} = useAuthContext()
    const axiosInstance = useAxiosInstance();
    const [btnLoading, setBtnLoading] = useState(false)

    useEffect(()=>{console.log('tokenReview region add popup',tokenReview)},[tokenReview])

    useEffect(()=>{
        if(openAddRegion){
            setUploading(false)
            getcountryList()
            if(editId !== 0){
                editRegion()
            }
            else{
                
            }
        }
    },[openAddRegion])

    //get selected countries code

    useEffect(()=>{
        console.log('selectedCountry',selectedCountry, 'selectedCountry length', selectedCountry.length)
        const countryCode =  selectedCountry.map(item => item.code);
        setSelectedCountryCode(countryCode)
    },[selectedCountry])

    //need to remove start
    
    useEffect(()=>{
        console.log('selectedCountryCode',selectedCountryCode)
    },[selectedCountryCode])

    useEffect(()=>{
        console.log('countryListFilter xxx',countryListFilter)
    },[countryListFilter])

    useEffect(()=>{
        console.error('countryList xxx',countryList)
    },[countryList])

    //need to remove end

    useEffect(()=>{
        if(countryList.length > 0){
            const countryListFilter = countryList.filter(item => !selectedCountryCode.includes(item.code));
            setCountryListFilter(countryListFilter)
        }
        console.log('countryListFirter',countryListFilter)

    },[countryList, selectedCountryCode])

    const cancel = () =>{
        setOpenAddRegion(false)
        reset({region:'', country:''})
        setRegionName('')
        setSelectedCountry([])
    }

    //get country list

    const getcountryList = async() =>{

        try {

            const res = await axiosInstance.get(`/country.php`)

            //console.log(res)
            let data = res.data.result;
            let error = res.data.error;

            console.log(data.countries)
            setCountryList(data.countries)

        } catch (err) {

            console.error('fetchData:', err);
            if (!err?.response?.alertShown) {
                setOpenFail(true)
                err?.response?.data?.error.text ? setTextFail(err.response.data.error.text): setTextFail('The server encounted an error and could not complete your request.')
            }
        }   

    }

    //get edit data

    const editRegion = async() => {

        try {

            setPopupLoader(true)

            const res = await axiosInstance.get(`/region.php?regionId=${editId}`)

            let data = res.data.result;
            let error = res.data.error;

            console.log(res.data.result)
            setSelectedCountry(data.region.countries)
            //setRegionName(data.region.name)
            setValue('region', data.region.name) 
            setPopupLoader(false)

        } catch (err) {
            console.error('fetchData:', err);
            if (!err?.response?.alertShown) {
                setOpenFail(true)
                err?.response?.data?.error.text ? setTextFail(err.response.data.error.text): setTextFail('The server encounted an error and could not complete your request.')
            }
        }   

    }

    const onSubmit = (data) => {

        console.log('submit data', data)
        
        if(editId === 0){
            dataPost();
        }
        else{
            dataUpdate();
        }



    }

    //new data post

    const dataPost = async() => {

        console.log('data post')
        setBtnLoading(true)

        try {

            const data = JSON.stringify({
                name: regionName,
                countries: selectedCountryCode
            })
                
            const res = await axiosInstance.post(`/region.php`, data)

            let result = res.data.result;
            let error = res.data.error;

            console.log(result.message)
            setUploading(false)
            setOpenAddRegion(false)
            getTableData()
            setOpenSuccess(true)
            setTextSuccess(result.message)
            reset({region:'', country:''})
            setRegionName('')
            setSelectedCountry([])      
            setBtnLoading(false)

        } catch (err) {
            console.error('fetchData:', err);
            setBtnLoading(false)
            if (!err?.response?.alertShown) {
                setOpenFail(true)
                err?.response?.data?.error.text ? setTextFail(err.response.data.error.text): setTextFail('The server encounted an error and could not complete your request.')
            }
        }   

    }

    //data update

    const dataUpdate = async() => {

        console.log('data update')
        setBtnLoading(true)

        try {

            const data = JSON.stringify({
                regionId:editId,
                name: regionName,
                countries: selectedCountryCode
            })
            
            const res = await axiosInstance.put(`/region.php`, data)

            let result = res.data.result;
            let error = res.data.error;

            console.log(result.message)
            setUploading(false)
            setOpenAddRegion(false)
            getTableData()
            setOpenSuccess(true)
            setTextSuccess(result.message)
            reset({region:'', country:''})
            setRegionName('')
            setSelectedCountry([])  
            setBtnLoading(false)

        } catch (err) {
            console.error('fetchData:', err);
            setBtnLoading(false)
            if (!err?.response?.alertShown) {
                setOpenFail(true)
                err?.response?.data?.error.text ? setTextFail(err.response.data.error.text): setTextFail('The server encounted an error and could not complete your request.')
            }
        }    

    }

    useEffect(()=>{
        console.log('selectedCountry:', selectedCountry);

    },[selectedCountry])

    return(
        <div>
            <Modal
                open={openAddRegion}
                aria-labelledby="modal-modal-title"
            >
                <Box className={`${ModalStyle['modal-base']} ${ModalStyle['modal-secret-key']}`}> 
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <div className={ModalStyle['modal-container']}>
                            <div className={ModalStyle['content-wrapper']}>
                                <Typography id="modal-modal-title" align='center' className={ModalStyle['modal-Header1']} variant="text24" component="h2">
                                   {editId === 0 ? 'Add new region & countries' : 'Edit region & countries'} 
                                </Typography>
                                    <FormControl variant="standard" fullWidth  margin="normal" className='modal-mt-12 form-control modal-mb-5' >
                                        <InputLabel shrink htmlFor='name' title='text' className='modal-label'>
                                            Region
                                        </InputLabel>
                                        { popupLoader ? (
                                            <SklInputPopup/>
                                        ):(
                                        <>
                                        <OutlinedInput 
                                            id='region'
                                            placeholder='Enter region'
                                            //value={regionName}
                                            autoComplete="off" 
                                            type='text'
                                            className='modal-input'
                                            {...register('region',{ required: true , onChange: (e) => setRegionName(e.target.value) })} 
                                        />
                                        {errors.region && <p className='err-msg'>Region is required</p>}
                                        </>
                                        )}
                                    </FormControl>
                                    <FormControl variant="standard" fullWidth  margin="normal" className='modal-mt-0 form-control modal-mb-5' >
                                        <InputLabel shrink htmlFor='name' title='text' className='modal-label'>
                                            Countries
                                        </InputLabel>
                                        { popupLoader ? (
                                            <SklInputPopup/>
                                        ):(
                                        <>
                                        <Autocomplete 
                                            multiple
                                            id="tags-country"
                                            className='tags-country-list modal-multiple-textarea'
                                            options={countryListFilter}
                                            getOptionLabel={(option) => option.name}
                                            filterSelectedOptions
                                            fullWidth
                                            //{...register('country', { required: true})}
                                            {...register('country', { required: selectedCountry.length > 0 ?false:true})}
                                            onChange={(event, newValue) => {
                                                setSelectedCountry(newValue);
                                            }}
                                            value={selectedCountry}
                                            renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                placeholder="Type & click enter to add country..."
                                                fullWidth
                                                id='country'
                                                className='no-boader-modal'
                                            />
                                            )}                                            
                                        />
                                        {errors.country && selectedCountry.length < 1 &&  <p className='err-msg'>Countries are required</p>}
                                        </>
                                        )}
                                    </FormControl>
                            </div>
                            <div className={ModalStyle['footer-wrapper-center']}>
                                <ButtonSecondary btnName="Cancel" variant={'primaryBtn'} classes='modal-button-12' type="button" onClick={cancel} disabled={uploading}/>
                                {/* <ButtonSecondary btnName={editId === 0 ? "Add" : 'Save'} variant={'secondaryBtn'} classes='modal-button-12' type="submit" onClick={()=>{}} disabled={uploading}/> */}
                                <ButtonSecondaryLoader variant={'secondaryBtn'} type='submit' classes='modal-button-12' btnName={editId === 0 ? "Add" : 'Save'} btnLoading={btnLoading} disabled={uploading} onClick={()=>{}}/>
                            </div>
                        </div>    
                    </form>
                </Box>
            </Modal>
        </div> 
    );
}

export default RegionAddModal;