import { useEffect, useState } from "react";

import Tooltip from '@mui/material/Tooltip';

import processing from '../../assets/processing.svg';
import infoCircle from '../../assets/info-circle.svg'
import infoWarnCircle from '../../assets/info-warn-circle.svg'

const Status = (props) => {

    const [status, setStatus]= useState({});
    const [Processing, setProcessing] = useState(false);
    const [failed, setFailed] = useState(false);
    const [orgMisMatch, setOrgMisMatch] = useState(false);
    const [notAcknowledged, setNotAcknowledged] = useState(false);
    const [notSent, setNotSent] = useState(false);
    const [rejected, setRejected] = useState(false);

    useEffect(()=>{
        //console.log('props.type',props.type)
        switch (props.type.value) {
            case 'pending_review':
                setStatus({text:'Pending', color:'state-fulvous', bg:'state-lace'})
                setProcessing(false)
                setFailed(false)
                setOrgMisMatch(false)
                setNotAcknowledged(false)
                setNotSent(false)
                setRejected(false)
            break;
            case 'no_org':
                setStatus({text:'Pending', color:'state-fulvous', bg:'state-lace'})
                setProcessing(false)
                setFailed(false)
                setOrgMisMatch(true)
                setNotAcknowledged(false)
                setNotSent(false)
                setRejected(false)
            break;
            case 'acknowledged':
                setStatus({text:'Acknowledged', color:'state-crayola', bg:'state-aqua'})
                setProcessing(false)
                setFailed(false)
                setOrgMisMatch(false)
                setNotAcknowledged(false)
                setNotSent(false)
                setRejected(false)

            break;
            case 'approved_sent':
                setStatus({text:'Approved', color:'state-crayola', bg:'state-aqua'})
                setProcessing(false)
                setFailed(false)
                setOrgMisMatch(false)
                setNotAcknowledged(false)
                setNotSent(false)
                setRejected(false)

            break;
            case 'not_acknowledge':
                setStatus({text:'Not Acknowledged', color:'state-red', bg:'state-linen'})
                setProcessing(false)
                setFailed(false)
                setOrgMisMatch(false)
                setNotAcknowledged(true)
                setNotSent(false)
                setRejected(false)

            break;
            case 'sent':
                setStatus({text:'Sent', color:'state-crayola', bg:'state-aqua'})
                setProcessing(false)
                setFailed(false)
                setOrgMisMatch(false)
                setNotAcknowledged(false)
                setNotSent(false)
                setRejected(false)
            break;
            case 'not_sent':
                setStatus({text:'Not Sent', color:'state-red', bg:'state-linen'})
                setProcessing(false)
                setFailed(false)
                setOrgMisMatch(false)
                setNotAcknowledged(false)
                setNotSent(true)
                setRejected(false)
            break;
            case 'pending':
                setStatus({text:'pending...', color:'state-sonicSilver', bg:'state-cultured'})
                setProcessing(false)
                setFailed(false)
                setOrgMisMatch(false)
                setNotAcknowledged(false)
                setNotSent(false)
                setRejected(false)
            break;
            case 'rejected':
                setStatus({text:'Rejected', color:'state-red', bg:'state-linen'})
                setProcessing(false)
                setFailed(false)
                setOrgMisMatch(false)
                setNotAcknowledged(false)
                setNotSent(false)
                setRejected(true)
            break;
            case 'retrying':
                setStatus({text:'Retrying', color:'state-primary', bg:'state-cultured'})
                setProcessing(true)
                setFailed(false)
                setNotAcknowledged(false)
                setNotSent(false)
                setRejected(false)
            break;
            case 'failed':
                setStatus({text:'Failed', color:'state-linen', bg:'state-imperialRed'})
                setProcessing(false)
                setFailed(true)
                setOrgMisMatch(false)
                setNotAcknowledged(false)
                setNotSent(false)
                setRejected(false)
            break;
            default:
                setStatus({text:'Processing', color:'state-primary', bg:'state-cultured'})
                setProcessing(true)
                setFailed(false)
                setOrgMisMatch(false)
                setNotAcknowledged(false)
                setNotSent(false)
                setRejected(false)
            break;
        }

    },[props.type]);


    return(
        <div className='flex'>
            {!Processing && !failed && !orgMisMatch && !notAcknowledged && !notSent && !rejected ? (
                <div className={`${status.color} ${status.bg} rounded-lg px-5 py-2`}>
                    {status.text}
                </div>
            ):
            (
                failed ? (
                    <div className={`${status.color} ${status.bg} rounded-lg px-5 py-2 flex`}>
                        {status.text} 
                        <Tooltip title={props.type.text} arrow placement="top">
                            <img src={infoCircle} alt="warning" className="w-5 ml-2" />
                        </Tooltip>
                    </div>
                ): orgMisMatch ? (
                    <div className={`${status.color} ${status.bg} rounded-lg px-5 py-2 flex`}>
                        {status.text} 
                        <Tooltip title={props.type.text} arrow placement="top">
                            <img src={infoWarnCircle} alt="warning" className="w-5 ml-2" />
                        </Tooltip>
                    </div>
                ): notAcknowledged ? (
                    <div className={`${status.color} ${status.bg} rounded-lg px-5 py-2 flex`}>
                        {status.text} 
                        <Tooltip title={<span dangerouslySetInnerHTML={{ __html:props.type.text}}/>} arrow placement="top">
                            <img src={infoCircle} alt="warning" className="w-5 ml-2" />
                        </Tooltip>
                    </div>
                ): notSent ? (
                    <div className={`${status.color} ${status.bg} rounded-lg px-5 py-2 flex`}>
                        {status.text} 
                        <Tooltip title={<span dangerouslySetInnerHTML={{ __html:props.type.text}}/>} arrow placement="top">
                            <img src={infoCircle} alt="warning" className="w-5 ml-2" />
                        </Tooltip>
                    </div>
                ): rejected ? (
                    <div className={`${status.color} ${status.bg} rounded-lg px-5 py-2 flex`}>
                        {status.text} 
                        {props.type.text ? (
                            <Tooltip title={<span dangerouslySetInnerHTML={{ __html:props.type.text}}/>} arrow placement="top">
                                <img src={infoCircle} alt="warning" className="w-5 ml-2" />
                            </Tooltip>
                        ):''}
                    </div>
                )
                :(
                    <div className={`${status.color} ${status.bg} rounded-lg px-5 py-2 flex`}>
                        {status.text} <img src={processing} alt="processing" className="w-6 ml-2" />
                    </div>
                )
                
            )
            }
        </div>
    )
    
}

export default Status;