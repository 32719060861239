import React, {useRef, useEffect, useState, createRef} from 'react';
import { useLocation } from 'react-router-dom';
import { debounce } from 'lodash';

import {ThemeProvider} from '@mui/material';

import hljs from 'highlight.js';
// import 'highlight.js/styles/paraiso-dark.css';

//import '../../styles/paraiso-dark-custom.scss'

const XmlEditViewer = (props) => {

    const refToHighlight = useRef(null);
    const contentEditableRef = useRef(null);
    const location = useLocation();
    const [pageApprove, setPageApprove] = useState(false)

    useEffect(() => {
        switch (location.pathname) {
            case "/approve":
                setPageApprove(true)
            break;
            default:
                setPageApprove(false)
            break;
        }
    }, [location]);

    useEffect(()=>{console.log('props nestedMap***', props.nestedMap)},[props.nestedMap])

    //fake cursor - works with content editable dynamic

    const handleContentClick = () => {
        console.log('handleContentClick')

        if(!pageApprove){

            const selection = window.getSelection();
            if (selection.rangeCount > 0) {
                const range = selection.getRangeAt(0);
                props.setLastCursorPosition(range);

                /*if(document.getElementById('cursorIndicator')){
                    document.getElementById('cursorIndicator').remove();
                }
                const cursorIndicator = document.createElement('span');
                cursorIndicator.id = 'cursorIndicator'
                range.insertNode(cursorIndicator);*/
            }
        }    

    };

    //get clicked line index

    const clickIndex = (index) => {
        console.log('index',index)
        if(!pageApprove){
            props.setClickId(index);
        }
    }

    const getHighlightedHTML = (xmlString) => {
        return hljs.highlight('xml', xmlString).value;
    };

    const setInput = debounce((input) => {
        console.log('setInput >>',input.target.innerText)
        props.setFilteredData(input.target.innerText)
        contentEditableRef.current.blur();
    }, 3000)

    return(
        <>
        {props.indent === false ? (
            <div className='p-6 w-fit min-w-full'>
                    <div className='flex justify-start mb-1'>
                        <div className='min-w-[1.5rem] mr-3 text-left text-sonicSilver font-normal text-base'></div>
                        <div 
                            id={`input-xml`} 
                            ref={contentEditableRef}
                            //contentEditable={index === props.clickId ? true : false} 
                            contentEditable={true}
                            //onKeyDown={(e)=>e.preventDefault()} 
                            spellCheck={false} 
                            onClick={()=>{handleContentClick()}} 
                            onInput={(e)=>{setInput(e)}}
                            className={`tagWrap font-normal text-white z-40 whitespace-nowrap outline-none tagWrap w-full min-h-[70vh]`}
                            dangerouslySetInnerHTML={{ __html: getHighlightedHTML(props.nestedMap) }}
                            >
                        </div>
                    </div>
            </div>
        ):(
            <div className='p-6 w-fit min-w-full'>
                {props.nestedMap.length > 0 && props.nestedMap.map((line, index)=> (
                    <div key={index} className='flex justify-start mb-1' ref={line.hasOwnProperty('class') ? refToHighlight : null}>
                        <div className='min-w-[1.5rem] mr-3 text-left text-sonicSilver font-normal text-base'>{index+1}</div>
                        <div 
                            id={`input-${index}`} 
                            //contentEditable={index === props.clickId ? true : false} 
                            contentEditable={true}
                            //onKeyDown={(e)=>e.preventDefault()} 
                            spellCheck={false} 
                            onClick={()=>{handleContentClick(); clickIndex(index)}} 
                            className={`tagWrap font-normal text-white z-40 whitespace-nowrap outline-none indent-${line.indent} ${line.hasOwnProperty('class') ? 'highligted':''} tagWrap`}
                            dangerouslySetInnerHTML={{ __html: getHighlightedHTML(line.line) }}
                            >
                        </div>
                    </div>
                ))}
            </div>
        )}
        </>
    )
}

export default XmlEditViewer;