import React, {useEffect, useState} from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { useForm } from "react-hook-form";
import axios from "axios";
import Cookies from 'js-cookie';
import { useBaseUrlContext } from '../../context/Base_url';

import {Box, OutlinedInput , FormControl, InputLabel, IconButton, InputAdornment, Typography } from '@mui/material';
import {VisibilityOff, Visibility} from '@mui/icons-material';
import { ThemeProvider } from "@mui/material";

import ButtonSecondary from '../button/Btn_secondary';
import ButtonOutlineImg from '../button/Btn_outline_img';

import { ThemeCustom} from "../../styles/Theme";

import eye from '../../assets/eye.svg'; 
import eyeSlash from '../../assets/eye-slash.svg'; 
import Logo from '../../assets/Docupath-Logo.svg';
import logoTestEnv from '../../assets/logo-test-env.svg'


const SignUpForm = (props) => {

    const [showPassword, setShowPassword] = useState(false);
    const { register, handleSubmit, formState: { errors } } = useForm();
    const navigate = useNavigate();
    const {BaseUrl, env} = useBaseUrlContext()


    const handleClickShowPassword = () => setShowPassword((show) => !show);

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    const onSubmit = (data) => {

        console.log('submit', data);

       
    }

    return (
        <ThemeProvider theme={ThemeCustom}>
            <div className='max-w-[35rem] bg-white rounded-3xl'>
                <Box component="form"  autoComplete="off" className='py-7' id="signin" onSubmit={handleSubmit(onSubmit)}>
                    <div className='flex items-center flex-col justify-center my-12'>
                        <img src={env ? logoTestEnv:Logo} alt='logo' className='w-[15rem]'/>
                    </div>
                    <div className='mt-16 px-12 mb-8 text-center'>
                        <Typography variant='textMedium24' component={'div'}>
                            Create your Docupath account
                        </Typography>
                    </div>
                    <FormControl variant="standard" fullWidth  margin="normal" className='px-12 '>
                        <div className='flex justify-between'>
                            <InputLabel shrink htmlFor="email-input">Email</InputLabel>
                            {errors.username && <span className='err-msg mt-0 mb-2'>Email is required.</span>}
                        </div>    
                        <OutlinedInput 
                            id="email-input"
                            autoComplete="off" 
                            placeholder="Enter email" 
                            //type='email'
                            type='text'
                            {...register("username",{ required: true })} 
                        />
                    </FormControl>
                    <FormControl variant="standard" fullWidth  margin="normal" className='px-12'>
                        <div className='flex justify-between'>
                            <InputLabel shrink htmlFor="password-input">Password</InputLabel>
                            {errors.password && <span className='err-msg mt-0 mb-2'>Password is required.</span>}
                        </div>
                        <OutlinedInput 
                            placeholder="Enter password" 
                            id="password-input" 
                            type={showPassword ? 'text' : 'password'} 
                            {...register("password",{ required: true })} 
                            endAdornment={
                                <InputAdornment position="end" className='mr-2'>
                                    <IconButton
                                        aria-label="toggle password visibility"
                                        onClick={handleClickShowPassword}
                                        onMouseDown={handleMouseDownPassword}
                                        edge="end"
                                    >
                                    {showPassword ? <img src={eyeSlash} alt='VisibilityOff' className='w-6'/> : <img src={eye} alt='Visibility' className='w-6'/>}
                                    {/*showPassword ? <VisibilityOff /> : <Visibility />*/}
                                    </IconButton>
                                </InputAdornment>
                            }
                        />
                    </FormControl>
                    <div className='mt-4 px-12 mb-12' >
                        <ButtonSecondary variant={'secondaryBtn'} type='submit' btnName='Continue' classes="w-full py-[1.125rem]" />
                    </div>
                    <div className='mt-0 px-12 mb-12 text-center'>
                        <Typography variant='textBase' className='font-semibold' component={'div'}>Already have an account? <Link className='text-secondary' to={'/'}>Sign in</Link></Typography>
                    </div>
                    <div className='flex items-center justify-center'>
                        <div className='mr-5 border-borderGrayDark border-t border-0 border-solid w-20'></div>
                        <Typography variant='textBase' component={'div'} className='px-5 py-2 border-borderGrayDark border border-solid rounded-2xl'>
                           Or
                        </Typography>
                        <div className='ml-5 border-borderGrayDark border-t border-0 border-solid w-20'></div>
                    </div>
                    <div className='px-12 mt-12 mb-5' >
                        <ButtonOutlineImg btnName="Continue with Google"  variant={'imgBtnPrimaryOutline'} img={Logo} type="submit" onClick={()=>{}} disabled={false} classes="w-full"/>
                    </div>
                </Box>
            </div>    
        </ThemeProvider>
    );
}

export default SignUpForm;