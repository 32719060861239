import React from "react";

import {ThemeProvider, Typography} from '@mui/material';
import { ThemeCustom} from "../../styles/Theme";

// Import React FilePond
import { FilePond, registerPlugin } from "react-filepond";
// Import FilePond styles
import "filepond/dist/filepond.min.css";
// Import the Image EXIF Orientation and Image Preview plugins
// Note: These need to be installed separately
// `npm i filepond-plugin-image-preview filepond-plugin-image-exif-orientation --save`
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type';

import SklDocUploadFull from "../skeletons/Skl_doc-upload-full";

import InputStyle from './Input_styles.module.scss';
import './Drag-input_styles.scss';

import documentIcon from '../../assets/file.png'
import Search  from "../../assets/search-gif.gif";

// Register the plugins
registerPlugin(FilePondPluginFileValidateType);


const DragDrop = (props) => {
    
    return (
        <ThemeProvider theme={ThemeCustom}>
            <div className="w-full rounded-xl bg-cultured" >
                <div className={`${InputStyle["file-upload-wrapper"]} file-pond-custom upload-screen bg-white overflow-hidden`}>
                    <form onSubmit={props.submit}>
                        <div className={InputStyle["file-upload-container"]}>
                            <FilePond
                                files={props.files}
                                allowReorder={false}
                                allowMultiple={true}
                                //acceptedFileTypes={['']}
                                onupdatefiles={props.setFiles}
                                labelIdle=''
                            />
                            {props.sklCommon ? ( 
                                    <SklDocUploadFull/>
                                ):(
                                <div className="upload-files-wrapper">
                                    <div className="text-center">
                                        <img className="max-w-[15.625rem] min-h-[15.625rem]" src={Search} alt="search logo"/>
                                        <Typography variant="text30" className="font-semibold mb-7 header-text" component='div'>
                                            Upload files
                                        </Typography>
                                        <Typography variant="text18" className="font-medium mb-10 sub-text" component='div'>
                                            Transform PDF, DOCX, XLS, and XML files into smart business documents with AI.
                                        </Typography>
                                    </div>
                                    <div className='mb-8 max-w-[22.5rem] mx-auto drop-btn'>
                                        <div className="drag-text-wrapper">
                                            <div className="action-text"> Drag & drop your files or <span className="filepond--label-action">Browse</span>
                                            </div>
                                        </div>
                                    </div>
                                    <Typography variant="textBase" className={`${InputStyle['hr-lines']} hr-break`} component="div" align="center" margin={'2rem 0'}>
                                    <div className="py-2 mx-auto w-16 border border-platinum border-solid rounded-[1.25rem] or-text"> or </div> 
                                    </Typography>
                                    {/* <Typography variant="text18" className="font-medium mt-10 text-center" component='div'>
                                            Email Attachments to <a href="mailto:admin@docupath.app" target="blank" className="text-crayola">admin@docupath.app</a>
                                    </Typography> */}
                                    <Typography variant="text18" className="font-medium mt-10 text-center" component='div'>
                                        Email, upload, or use our API to share attachments.
                                    </Typography>
                                </div>
                                )
                            }
                        </div>
                        {/*
                        <div className='border-t-2 border-0 border-solid border-t-cultured p-5 flex justify-center' >
                            <ButtonSecondary type='submit' btnName='Upload' onClick={()=>{}} disabled={disabled}/>
                        </div> 
                        */}
                    </form>
                </div>
            </div>
        </ThemeProvider>    
    );
}

export default DragDrop;