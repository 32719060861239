import React, {useState, useEffect} from 'react';
import {ThemeProvider, Container, Skeleton} from '@mui/material';

import { ThemeCustom} from "../../styles/Theme";

import ButtonSecondary from '../button/Btn_secondary';

const SettingsFooter = (props) => {
    return(
        <ThemeProvider theme={ThemeCustom}>
            <div className='bg-white shadow-none border-t border-0 border-solid border-t-borderGrayDark top-auto bottom-0 absolute w-full md:w-[calc(100%*9.865/12)] lg:w-[calc(100%*8.925/12)] right-0'>
                <Container maxWidth="false" >
                    <div className='py-5 flex justify-end items-center'>
                        <div className='flex'>
                            {props.loader ? (
                            <>
                                <Skeleton animation="wave" className={` min-w-[7.5rem] max-h-[3.449rem] h-[3.449rem] rounded-xl mr-5`}></Skeleton>
                                <Skeleton animation="wave" className={` min-w-[7.5rem] max-h-[3.449rem] h-[3.449rem] rounded-xl`}></Skeleton>
                            </>
                            ):(
                            <>    
                                {props.cancel?(
                                    <ButtonSecondary btnName="Cancel" type="button" variant={'platinumBtn'} classes="mr-5" onClick={props.cancel} disabled={false}/>
                                ):''}
                                <ButtonSecondary btnName="Save" variant={'secondaryBtn'} type="submit" onClick={props.submit} disabled={false}/>
                            </>
                            )}
                            </div>
                    </div>
                </Container>
            </div>
        </ThemeProvider>
    )
}

export default SettingsFooter;