import React, {useEffect, useState} from 'react';
import { Navigate, Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import { useUserContext } from './context/User_Access';
import {QueryClient, QueryClientProvider,} from '@tanstack/react-query'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import { PersistQueryClientProvider } from '@tanstack/react-query-persist-client'
import { createSyncStoragePersister } from '@tanstack/query-sync-storage-persister'
import Cookies from 'js-cookie';
import { useMediaQuery } from 'react-responsive';

import { CssBaseline, ThemeProvider, Backdrop, Typography } from "@mui/material";
import { ThemeMain } from "./styles/Theme";
import "./styles/custom_styles.scss";

import NavBar from "./components/nav/Nav";
import SuccessAlert from './components/alerts/Success_alert';
import ErrorAlert from './components/alerts/Fail_alert';

import Signin from "./views/Signin";
import SignUp from './views/Signup';
import InvoiceUploader from './views/Invoice_upload';
import Approve from './views/Approve';
import Settings from './views/Settings';
import DocReview from './views/Document_review';
import KnowMap from './components/settings_comp/Known_map';
import FormatAPI from './components/settings_comp/Format_API';
import UnKnowMap from './components/settings_comp/UnKnown_map';
import Region from './components/settings_comp/Region';
import Instruct from './components/settings_comp/Instruct';
import ReportConsumption from './components/settings_comp/Report_consum';
import NotAccess from './views/Not_access';
import OrganizationSettings from './components/settings_comp/Organization';
import Users from './components/settings_comp/Users';
import UserSettings from './components/settings_comp/User_settings';
import NotFound from './views/404';
import CreateAccount from './views/Create_account';
import TermsPolicies from './views/Terms_and_policies';
import CreateWorkSpace from './views/Create_workspace';
import WelcomeFlow from './views/Welcome_ob';
import PickPackageFlow from './views/Pick_package_ob';
import FinalizingFlow from './views/Finalizing_ob';
import Redirect from './views/Redirect';
import LoginUnsuccessful from './views/Login_unsuccessful';
import AuthGoToBack from './views/AuthGoToBack';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      cacheTime: 1000 * 60 * 60 * 24, // 24 hours
    },
  },
})

const persister = createSyncStoragePersister({
  storage: window.localStorage,
})

function App() {

  const location = useLocation();
  const [navBar, setNavBar] = useState(true);
  const [navBarBoader, setNavBarBoader]= useState('');
  const [uploading, setUploading] = useState();
  const [submitFiles, setSubmitFiles] = useState(0);
  const [uploadFiles, setUploadFiles] = useState(0);
  const [uploadCompeted, setUploadCompeted] = useState(false)
  const [tabValue, setTabValue] = useState(0);
  const [pathName, setPathName] = useState('')
  const {devMode, accessControl} = useUserContext()
  const navigate = useNavigate();
  const isMobile = useMediaQuery({ query: '(max-width: 768px)' });
  const [open, setOpen] = useState(false);
  const nestedRouteList = [

    { path: 'destination_format_and_apis', accessPath: '/mappings', Component: FormatAPI },
    { path: 'known_mappings', accessPath: '/known_mappings', Component: KnowMap },
    { path: 'unknown_mappings', accessPath: '/unknown_map', Component: UnKnowMap },
    { path: 'regions_and_countries', accessPath: '/region', Component: Region },
    { path: 'instruction_builds', accessPath: '/instructions', Component: Instruct },
    { path: 'usage_insights', accessPath: '/reports', Component: ReportConsumption },
    { path: 'organization_settings', accessPath: '/organization', Component: OrganizationSettings },
    { path: 'user_management', accessPath: '/users', Component: Users },

  ]

  //useEffect(()=>{console.error('accessControl', accessControl)},[accessControl])  

  // Automatically open the popup when isMobile is true

  useEffect(() => {

    if (isMobile) {
      setOpen(true);
    } else {
      setOpen(false);
    }
    
  }, [isMobile]);

  useEffect(() => {

    if(location.search !== ''){
      const url = `${location.pathname}${location.search}`;
      setPathName(url)
    }
    else{
      setPathName(location.pathname)
    }

    switch (location.pathname) {
        case "/":
          setNavBar(false);
          document.body.style.backgroundColor = "#F3F5F5";
        break;
        case "/signup":
          setNavBar(false);
          document.body.style.backgroundColor = "#F3F5F5";
        break;
        case "/redirect":
          setNavBar(false);
          document.body.style.backgroundColor = "#F3F5F5";
        break;
        case "/create_workspace":
          setNavBar(false);
          document.body.style.backgroundColor = "#F3F5F5";
        break;
        case "/go_back":
          setNavBar(false);
          document.body.style.backgroundColor = "#F3F5F5";
        break;
        case "/terms_and_policies":
          setNavBar(false);
          document.body.style.backgroundColor = "#FFFFFF";
        break;
        case "/welcome":
          setNavBar(false);
          document.body.style.backgroundColor = "#FFFFFF";
        break;
        case "/pick_your_package":
          setNavBar(false);
          document.body.style.backgroundColor = "#FFFFFF";
        break;
        case "/lets_go":
          setNavBar(false);
          document.body.style.backgroundColor = "#FFFFFF";
        break;
        case "/file_upload":
          setNavBarBoader('border-enable');
          document.body.style.backgroundColor = "#FFFFFF";
          setNavBar(true);        
        break;
        case "/approve":
          setNavBarBoader('border-enable');
          document.body.style.backgroundColor = "#FFFFFF";
          setNavBar(true);        
        break;
        case "/document_review":
          setNavBarBoader('border-enable');
          document.body.style.backgroundColor = "#FFFFFF";
          setNavBar(true);        
        break;
        case "/settings":
          setNavBarBoader('border-enable');
          document.body.style.backgroundColor = "#FFFFFF";
          setNavBar(true);   
          if(devMode){
            navigate('/settings/organization_settings?devMode=true')     
          }
          else{
            navigate('/settings/organization_settings')     
          }
        break;
        case "/settings/destination_format_and_apis":
          setNavBarBoader('border-enable');
          document.body.style.backgroundColor = "#FFFFFF";
          setNavBar(true);
        break;
        case "/settings/known_mappings":
          setNavBarBoader('border-enable');
          document.body.style.backgroundColor = "#FFFFFF";
          setNavBar(true);
        break;
        case "/settings/unknown_mappings":
          setNavBarBoader('border-enable');
          document.body.style.backgroundColor = "#FFFFFF";
          setNavBar(true);
        break;
        case "/settings/regions_and_countries":
          setNavBarBoader('border-enable');
          document.body.style.backgroundColor = "#FFFFFF";
          setNavBar(true);
        break;
        case "/settings/instruction_builds":
          setNavBarBoader('border-enable');
          document.body.style.backgroundColor = "#FFFFFF";
          setNavBar(true);
        break;
        case "/settings/usage_insights":
          setNavBarBoader('border-enable');
          document.body.style.backgroundColor = "#FFFFFF";
          setNavBar(true);
        break;
        case "/settings/organization_settings":
          document.body.style.backgroundColor = "#FFFFFF";
          setNavBarBoader('border-enable');
          setNavBar(true);
        break;
        case "/settings/user_management":
          setNavBarBoader('border-enable');
          document.body.style.backgroundColor = "#FFFFFF";
          setNavBar(true);
        break;
        case "/settings/user_settings":
          setNavBarBoader('border-enable');
          document.body.style.backgroundColor = "#FFFFFF";
          setNavBar(true);
        break;
        default:
          setNavBar(false);
          document.body.style.backgroundColor = "#FFFFFF";
        break;
    }
    
  }, [location]);

  // Function to check if the URL has a subdomain

  const hasSubdomain = () => {
    const hostname = window.location.hostname;
    const parts = hostname.split('.');
    
    if(hostname.includes('localhost')){
      return true;
    }
    else{
      return parts.length > 2  // True if there's a subdomain
    }
   
  };

  // Function to restrict onboarding flow page to users who have subscription

  const hasPackage = () => {
    const packageSubscription = (Cookies.get('packageId') != null) ? false : true;
    return packageSubscription;
  }

  //check accesss

  const hasAccess = (path) => {
    return Object.keys(accessControl).includes(path) && accessControl[path].write;
  };
  
  //component inserting  

  const ProtectedRoute = ({ accessPath, component: Component }) => {
    return hasAccess(accessPath) ? <Component /> : <NotAccess className="settings-not-access" class={'h-[calc(100vh-12rem)]'}/>;
  };


  return (
    <ThemeProvider theme={ThemeMain}>
      <CssBaseline enableColorScheme />
      <PersistQueryClientProvider 
        client={queryClient}
        persistOptions={{ persister }}
      >
      <Backdrop
        sx={(theme) => ({ color: '#fff', zIndex: theme.zIndex.drawer + 1, backgroundColor: 'rgba(0, 0, 0, 0.7)' })}
        open={open}
      >
        <Typography component={'div'} variant='text20' className='text-white text-center p-10'>
          You're on a small screen. Switch to a larger screen for a better experience!
        </Typography>
      </Backdrop>
      {navBar && 
        <NavBar 
          setTabValue={setTabValue} 
          navBarBoader={navBarBoader} 
        />
      }
        <Routes>
          {hasSubdomain() ? (
            <Route path='/' element={<Signin/>}/>
          ):(
            <>
              <Route path='/' element={<CreateAccount/>} />
              <Route path='/create_workspace' element={<CreateWorkSpace/>} />
            </>
          )}
                
                  {/* <Route path='/create_workspace' element={<CreateWorkSpace/>} />
                  <Route path='/welcome' element={<WelcomeFlow/>} />
                  <Route path='/pick_your_package' element={<PickPackageFlow/>} />
                  <Route path='/lets_go' element={<FinalizingFlow/>} /> */}
                
            <Route path='/signup' element={<SignUp/>} />
            <Route path='/redirect' element={<Redirect/>} />
            <Route path='/go_back' element={<AuthGoToBack/>} />
            {hasPackage() ? (
              <>
                <Route path='/welcome' element={<WelcomeFlow/>} />
                {
                  devMode ? <Route path='/pick_your_package' element={<PickPackageFlow/>} /> : ''
                }
                <Route path='/lets_go' element={<FinalizingFlow/>} />
              </>
            ):''}
            <Route path='/terms_and_policies' element={<TermsPolicies/>} />
            <Route path='/file_upload' element={
                <InvoiceUploader 
                  setUploading={setUploading} 
                  uploading={uploading} 
                  setSubmitFiles={setSubmitFiles} 
                  setUploadFiles={setUploadFiles} 
                  setUploadCompeted={setUploadCompeted} 
                  submitFiles={submitFiles} 
                  uploadFiles={uploadFiles} 
                  uploadCompeted={uploadCompeted}
                />
              } 
            />
            <Route path='/approve' element={<Approve/>}/>
            <Route path='/settings' element={
                <Settings 
                  tabValue={tabValue} 
                  setTabValue={setTabValue} 
                  pathName={pathName}
                />
              }
            >
                {nestedRouteList.map(({ path, accessPath, Component }) => (
                  <Route
                    key={path}
                    path={path}
                    element={<ProtectedRoute accessPath={accessPath} component={Component} />}
                  />
                ))}
                <Route path="user_settings" element={
                  <UserSettings/>}
                />
            </Route>
            <Route path={'/document_review'} element={
                <DocReview 
                  setUploading={setUploading} 
                  uploading={uploading} 
                  setSubmitFiles={setSubmitFiles} 
                  setUploadFiles={setUploadFiles} 
                  setUploadCompeted={setUploadCompeted} 
                  submitFiles={submitFiles} 
                  uploadFiles={uploadFiles} 
                  uploadCompeted={uploadCompeted}
                />
              }
            />
            <Route path='*' element={<NotFound/>}/>
            <Route path='/login_unsuccessful' element={<LoginUnsuccessful/>}/>
        </Routes>   
        <ReactQueryDevtools initialIsOpen />
      </PersistQueryClientProvider>   
    </ThemeProvider>
  );
}

export default App;
